import React from 'react';
import Button from '@material-ui/core/Button';
import Modal from 'react-bootstrap/Modal'

export default function AlertDialogSlide(props) {

  const {
    modaldata,
    onHide
  } = props

  
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header className={modaldata.border} closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
        ¡{modaldata.title}!  
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {modaldata.body}
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={onHide} className={modaldata.border}>Cerrar</Button>
      </Modal.Footer>
    </Modal>
  );
}
