import React from "react";
import { Button, FormGroup, Label } from "reactstrap";
import { Form, Modal } from "react-bootstrap";

import "../../assets/styles/components/NewFields.scss";

function Row({ onChange, onRemove, nombre, select }) {
  return (
    <>
      <FormGroup className="fields__new">
        <Label>Nombre del elemento</Label>
        <Form.Control
          type="text"
          value={nombre}
          onChange={(e) => onChange("name", e.target.value)}
          required
        ></Form.Control>
      </FormGroup>

      {select && (
        <>
          <FormGroup className="fields__new">
            <Label>Descripción del elemento</Label>
            <Form.Control
              as="textarea"
              name="description"
              rows="3"
              onChange={(e) => onChange("description", e.target.value)}
              required
            ></Form.Control>
          </FormGroup>
        </>
      )}
      <Button color="danger" className="delete__button" onClick={onRemove}>
        Eliminar
      </Button>
    </>
  );
}

const ModalAcountAdd = (props) => {
  const {
    defaultState,
    rows,
    setRows,
    select,
    setSelect,
    showNewMod,
    ModNewCateSate,
    handleSubmit,
    handleChange,
    handleOnChange,
    stateform
  } = props;

  const handleOnAdd = () => {
    setRows(rows.concat(defaultState));
  };

  const handleOnRemove = (index) => {
    const copyRows = [...rows];
    copyRows.splice(index, 1);
    setRows(copyRows);
  };

  const handleChangeCheckbox = (e, value) => {
    if (e.target.checked && value === "Si") {
      setSelect(true);
    } else {
      setSelect(false);
    }
  };

  return (
    <Modal show={showNewMod} id="ModalAdd" onHide={ModNewCateSate} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>Creacion de lista</Modal.Title>
      </Modal.Header>
      <Form role="form" onSubmit={e => handleSubmit(e, stateform.name, stateform.description, stateform)}>
        <Modal.Body>
          <FormGroup className="fields__new">
            <Label>Nombre de la lista</Label>
            <Form.Control
              type="text"
              name="name"
              required
              onChange={(e) => handleOnChange(e)}
            ></Form.Control>
          </FormGroup>
          <FormGroup className="fields__new">
            <Label>Descripción de la lista</Label>
            <Form.Control
              as="textarea"
              name="description"
              rows="3"
              required
              onChange={(e) => handleOnChange(e)}
            ></Form.Control>
          </FormGroup>
          <div className="deseas__select">
            <span>Deseas una descripción</span>

            <span>Si</span>
            <input
              type="checkbox"
              name=""
              id=""
              onClick={(e) => handleChangeCheckbox(e, "Si")}
            />
          </div>

          {rows.map((row, index) => (
            <Row
              {...row}
              onChange={(name, value) => handleChange(index, name, value)}
              onRemove={() => handleOnRemove(index)}
              key={index}
              setSelect={setSelect}
              select={select}
            />
          ))}
          <Button color="info" className="add_field" onClick={handleOnAdd}>
            <i className="fas fa-plus-square"></i>
          </Button>
        </Modal.Body>
        <Modal.Footer>
          <Button color="danger" onClick={ModNewCateSate}>
            Cerrar
          </Button>
          <Button type="submit" color="success">
            Guardar Cambios
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default ModalAcountAdd;
