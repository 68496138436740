import React, { useRef, useState } from "react";

import { Button } from "reactstrap";
import { Modal } from "react-bootstrap";
import UserService from "../../service/user.service";
import MercadoPago from "./MercadoPago";
import { storage } from "../../utils/firebase.config";
import { ref as storageRef, uploadBytes } from "firebase/storage";
import moment from "moment";
import { useDispatch } from "react-redux";
import { createDocumentsUser } from "../../actions/auth";
import { useSelector } from "react-redux";
import DropzoneInput from "../FormComponents/DropzoneInput";
import { CCol, CRow } from "@coreui/react";
import { ReactComponent as CloseSVG } from "../../assets/svg/cross.svg";
import { removeAccents } from "../../utils/TextUtils";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import Routes from "../../routes/Routes";

const CreateDocuments = ({
  show,
  onHide,
  userDoc,
  doc,
  price,
  //dataPerson
}) => {
  const [archivos, setArchivos] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const firebaseDocumentID = useRef("");
  const user = useSelector((state) => state?.auth?.user);
  const [paymentModal, setPaymentModal] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory()

  const handleSubmit = async (files = []) => {
    setLoading(true);

    //const f = new FormData();
    let attach = {};
    for (let index = 0; index < files.length; index++) {
      //f.append(`attachments[${index}]`, files[index]);

      const reader = new FileReader();
      reader.fileName = files[index].name;
      reader.readAsDataURL(files[index]);
      reader.onloadend = function (file) {
        attach[file.target.fileName] = reader.result;
      };
    }
    setArchivos(files);
    //localStorage.setItem(`attach_${documentCreated.id}`, JSON.stringify(attach));
    setLoading(false);
    /*
    UserService.postAttachement(id, f)
      .then((res) => {
        setArchivos(files);
        localStorage.setItem(
          `attach_${id_document.id}`,
          JSON.stringify(attach)
        );
        setLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setLoading(false);
        setError("Ha ocurrido un error al adjuntar los archivos");
        setTimeout(() => {
          setError(null);
        }, 4000);
      });
      */
  };

  const handleGoPay = async () => {
    try {
      
      if (firebaseDocumentID.current?.id) {
        setPaymentModal(true);
        return;
      }
      //antes de pagar se crea el documento
      const listDocuments = [];
      if (archivos && archivos.length > 0) {
        const f = new FormData();
        for (let index = 0; index < archivos.length; index++) {
          let filename = removeAccents(`${archivos[index].name}`);
          filename = filename.substring(0, filename.length - 4);
          filename = filename.replace(/ /g, "_");
          filename = filename.substring(0, 10);

          const nameDocument = `${filename}_${moment().unix()}.pdf`;
          f.append(`attachments[${index}]`, archivos[index]);
          f.append(`attachmentsName[${index}]`, nameDocument);
          const storageReference = storageRef(storage, nameDocument);

          // 'file' comes from the Blob or File API
          await uploadBytes(storageReference, archivos[index]);
          listDocuments.push(nameDocument);
        }
        //await UserService.postAttachement(documentCreatedResponse, f);
      }

      const documentCreatedResponse = await dispatch(
        createDocumentsUser({
          ...userDoc,
          user: user,
          transaction_amount: price,
          attachments: listDocuments,
        })
      );
      //se crea el paymentorder
      if(documentCreatedResponse.redirect_to_payment) {
        
        const query = `?&payment_id=gift_${documentCreatedResponse.id}&external_reference=null&payment_type=gift`; //&merchant_order_id=${}&preference_id=${}&processing_mode=${}&merchant_account_id=${}`
        history.push(`${Routes.ValidatePayment(documentCreatedResponse.id)}${query}`);
        return 
      }
      firebaseDocumentID.current = documentCreatedResponse;
      setPaymentModal(true);
      /*
      let data = {
        payment_platform: 1,
        plantilla_id: "",
        document_id: "",
        payment_id: "Pending",
        items: [documentCreatedResponse],
        name: "prueba",
        email: "prueba@gmail.com",
      };
      const order = await UserService.createDocumentsUser(data);
      */
      //const order = await UserService.createPayment(data);
      //localStorage.setItem("order_id", order.data.data.order_id);

      setPaymentModal(true);
    } catch (error) {
      console.error(error);
      setError("Ha ocurrido un error al adjuntar los archivos");
      setTimeout(() => {
        setError(null);
      }, 4000);
    }
  };
  const close = () => {
    onHide();
    setArchivos([]);
    //localStorage.removeItem(`attach_${id_document.id}`);
  };

  return (
    <>
      <Modal
        show={show}
        size="lg"
        className="drag_attachments_modal"
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Modal.Body>
          <CRow>
            <CCol>
              <Modal.Title id="contained-modal-title-vcenter">
                Añadir anexos: {doc.name}
              </Modal.Title>
            </CCol>
            <CCol
              className="d-flex justify-content-center align-items-center"
              xs="auto"
            >
              <button onClick={close} type="button" className="close_btn">
                <CloseSVG />
              </button>
            </CCol>
          </CRow>
          <div className="my-3">
            {loading && error !== null ? (
              <span className="spinner-border spinner-border-sm"></span>
            ) : error != null ? (
              <h3> {error} </h3>
            ) : (
              // : " La sección de añadir archivos esta siendo modificada, vuelve a intentar más tarde"}

              <DropzoneInput
                loaded={archivos?.length > 0}
                className={`mt-2 ${
                  archivos.length > 0 ? "dropzone_file_loaded" : ""
                }`}
                onDrop={handleSubmit}
              >
                <p>
                  {archivos?.length > 0
                    ? archivos.map((arc) => arc.name).join(", ")
                    : "Importar documento o arrastrar aquí"}
                </p>
              </DropzoneInput>
            )}
          </div>
          <CRow>
            <CCol className="d-flex justify-content-center">
              <Button className="primary_custom_btn" onClick={handleGoPay}>
                Pagar
              </Button>
            </CCol>
          </CRow>
        </Modal.Body>
      </Modal>

      <Modal
        show={paymentModal}
        onHide={() => {
          //setPaymentModal(false);
        }}
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Modal.Header>
          <CRow className="w-100">
            <CCol>
              <Modal.Title id="contained-modal-title-vcenter">
                Llena los Siguientes datos
              </Modal.Title>
            </CCol>
            <CCol
              className="d-flex justify-content-center align-items-center drag_attachments_modal "
              xs="auto"
            >
              <button
                onClick={() => {
                  setPaymentModal(false);
                }}
                type="button"
                className="close_btn"
              >
                <CloseSVG />
              </button>
            </CCol>
          </CRow>
        </Modal.Header>
        <Modal.Body>
          <MercadoPago
            //onSubmit={handleGoPay}
            onCancel={close}
            paymentData={{
              unit_price: parseFloat(price),
              title: doc.name,
            }}
            setError={setError}
            doc={doc}
            documentFirebaseId={firebaseDocumentID.current?.id}
            //dataPerson={dataPerson}
          ></MercadoPago>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default CreateDocuments;
