import React, { useEffect, useState } from "react";
import { Redirect, withRouter } from "react-router-dom";

import Icons from "../assets/static/perfil.png";
import { Button } from "reactstrap";
import Logo from "../assets/static/logo-yo-litigo-01.png";
import { useDispatch, useSelector } from "react-redux";
import { postInfoUser, logout } from "../actions/auth";
import UserService from "../service/user.service";
import AuthService from "../service/auth.service";
import FormPasswordReset from "./FormComponents";
import Footer from "./Footer";
import AlertDialogSlide from "./modals/AlertDialogSlide";
import DocumentProfile from "./DocumentProfile";
import ModalMethod from "./ModalDocument";
import "../assets/styles/components/Profile.scss";

import { Spinner } from "react-bootstrap";
import { getDataUser, getTokenUser, getTypeUser } from "../reducers/auth";
import { LOGIN_SUCCESS } from "../actions/types";
import signService from "../service/sign.service";
import ExternalDocumentsProfile from "./ExternalDocumentsProfile";

function Profiles(props) {
  const [dataPerson, setDataPerson] = useState({
    name: "",
    birth: "",
    phone: "",
    email: "",
    gender: "",
  });
  const dispatch = useDispatch();
  const [modalData, setModalData] = useState({
    title: "",
    body: "",
    border: "",
  });
  const ModAlertMod = () => setshowAlertMod(!showAlertMod);
  const [showAlertMod, setshowAlertMod] = useState(false);
  const [select, setSActive] = useState({
    personal: true,
    change: false,
    document: false,
    externalDocument: false,
    home: false,
  });
  
  let query = new URLSearchParams(props.location.search);
  const [dataDoc, setDataDoc] = useState([]);
  const [externalDataDoc, setExternalDataDoc] = useState([]);
  const [refreshData, setrefreshData] = useState(false);

  const [whichActive, setWhichActive] = useState("personal");

  const [infoPerson, setInfoPerson] = useState({});
  const [inputDisable, setInputDeseable] = useState(true);
  const [redirect, setRedirect] = useState(false);

  const [modalShow, setModalShow] = useState(false);
  const [data, setData] = useState({});
  const [dataPdf, setDataPdf] = useState({});
  const [title, setTitle] = useState("");
  const [inputs, setInput] = useState([]);
  const [topRight, setTopRight] = useState(false);
  const [fieldsTop, setFieldsTop] = useState([]);

  const [isLoading, setIsLoading] = useState(false);
  const dataUser = useSelector(getDataUser);
  const tokenUser = useSelector(getTokenUser);
  const typeUser = useSelector(getTypeUser);
  useEffect(() => {
    UserService.getUserBoard().then(
      (response) => {
        setDataPerson(response.data);
        const save = { ...response.data, token: tokenUser };
        dispatch({
          type: LOGIN_SUCCESS,
          payload: { user: save, typeUser: typeUser, token: save },
        });
        if (isLoading) {
          setIsLoading(false);
          setInputDeseable(true);
        }
      },
      (error) => {
        const _content =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        if (_content === "Unauthenticated.") {
          logOut();
        } else {
          setModalData({
            title: "Lo sentimos",
            body: _content,
            border: "bg-danger",
          });
          ModAlertMod();
        }
        if (isLoading) {
          setIsLoading(false);
          setInputDeseable(true);
        }
      }
    );
    /*
    if (location.state?.documents) {
      location.state.documents = false;
      setSActive({ personal: false, document: true });
      setWhichActive("document");
    }
    */
  }, [refreshData]);

  useEffect(() => {
    let stateArray = [];
    if (dataUser.id) {
      //UserService.getUserDocument(dataUser.id)
      AuthService.getPayedDocumentsUser({
        userID:dataUser.id
      }).then(
        (response) => {
          
          setDataDoc(response);
        },
        (error) => {
          console.error(error);
          const _content =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();

          if (_content === "Unauthenticated.") {
            logOut();
          } else {
            setModalData({
              title: "Lo sentimos",
              body: _content,
              border: "bg-danger",
            });
            ModAlertMod();
          }
        }
      );
      
    signService.requestExternalSignDocuments(dataUser.id).then((value={})=>{
      setExternalDataDoc(Object.entries(value).map(([key,value])=> ({...value, id:key})))
      const externalId = query.get('externalDocument')
      if(externalId && value[externalId]){
        handleSelect(4)
      }
    })
    }
  }, [refreshData]);

  const handleModal = (document1, setData1, setTitle1, topRight1) => {
    let new_dict = [];
    let arrayData = [];

    for (const property in document1.lists) {
      new_dict.push({ name: property, list: document1.lists[property] });
    }

    for (const key_data in document1.vars) {
      arrayData.push({ type_data: document1.vars[key_data], msg: key_data });
    }

    if (topRight1) {
      UserService.getAllTemplate().then(
        (response) => {
          let _arrays = Object.keys(response.data);
          let _document_test = document1;
          _arrays.forEach(function (item, index) {
            if (document1.template_id === item) {
              _document_test = response.data[item];
            }
          });
          localStorage.setItem(
            "doc",
            JSON.stringify({..._document_test})
          );
          // setFieldsTop(arrayData);
          // setInput(new_dict);
          // setData1(_document_test);
          // setTitle1(_document_test.title_doc);
          // setTopRight(topRight1);
          // setModalShow(true);
          //history.push("/methods");
        },
        (error) => {
          const _content =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();

          console.error(_content);
        }
      );
    } else {
      setFieldsTop(arrayData);
      setInput(new_dict);
      setData1(document1);
      setTitle1(document1.title_doc);
      setTopRight(topRight1);
      setModalShow(true);
    }
  };

  const logOut = () => {
    dispatch(logout());
  };

  const handleSelect = (value) => {
    if (value === 1) {
      setSActive({ personal: true });
      setWhichActive("personal");
    } else if (value === 2) {
      setSActive({ personal: false, change: true });
      setWhichActive("change");
    } else if (value === 3) {
      setSActive({ personal: false, document: true });
      setWhichActive("document");
    } else if (value === 4) {
      setSActive({ personal: false, externalDocument: true });
      setWhichActive("externalDocument");
    } else {
      setSActive({ personal: false, home: true });
      setWhichActive("home");
      setRedirect(true);
    }
  };

  const EditPerson = (e) => {
    e.preventDefault();
    setInputDeseable(false);
  };

  const handleChange = (e) => {
    e.preventDefault();
    setInfoPerson({ ...infoPerson, [e.target.name]: e.target.value });
  };
  const SavePerson = (e, data) => {
    e.preventDefault();

    setIsLoading(true);
    if (!dataPerson.person && !data.name) {
      data.name = dataPerson.name;
      if (!data.type_id) data.type_id = "Cedula de identificacion";
      if (!data.gender) data.genre = "Masculino";
    }

    for (const index in data) {
      if (data[index] === "") {
        delete data[index];
      }
    }

    if (data.cedula?.length > 12) {
      data.cedula = data.cedula.slice(0, 11);
    }

    dispatch(postInfoUser(data))
      .then((response) => {
        setModalData({
          title: "Excelente",
          body: response.data.message,
          border: "bg-success",
        });
        ModAlertMod();
        setrefreshData(!refreshData);
      })
      .catch((error) => {
        console.error(error);

        const errorMessage = dataPerson.person
          ? `${error.response?.data}`
          : `Por favor complete todos los campos`;

        setModalData({
          title: "Lo sentimos",
          body: "No se puede editar el usuario.\n" + errorMessage,
          border: "bg-danger",
        });
        ModAlertMod();

        setrefreshData(!refreshData);
      });

    // setrefreshData(!refreshData);
  };
  return (
    <>
      <main className="body__profile">
        {redirect && <Redirect to="/panel" />}
        <section className="info__profile">
          <div className="imagen__logo__profile">
            <img src={Logo} alt="" />
          </div>
          <div>
            <h2>MI PERFIL</h2>
            <div className="perfil__data">
              <figure className="photo__perfil">
                <img src={Icons} alt="" />
              </figure>
              <div className="up__data">
                <p>Bienvenido {dataPerson.name ? dataPerson.name : ""}</p>
              </div>
            </div>
            <div
              className={`cajas__info ${
                whichActive === "personal" ? "active" : ""
              }`}
              onClick={(e) => handleSelect(1)}
            >
              <h6>DETALLES PERSONALES</h6>
            </div>
            <div
              className={`cajas__info ${
                whichActive === "document" ? "active" : ""
              }`}
              onClick={(e) => handleSelect(3)}
            >
              <h6>MIS DOCUMENTOS</h6>
            </div>
            <div
              className={`cajas__info ${
                whichActive === "externalDocument" ? "active" : ""
              }`}
              onClick={(e) => handleSelect(4)}
            >
              <h6>MIS DOCUMENTOS EXTERNOS</h6>
            </div>
            <div
              className={`cajas__info ${
                whichActive === "change" ? "active" : ""
              }`}
              onClick={(e) => handleSelect(2)}
            >
              <h6>CAMBIOS DE CONTRASEÑA</h6>
            </div>
            <div
              className={`cajas__info ${
                whichActive === "home" ? "active" : ""
              }`}
              onClick={(e) => handleSelect(5)}
            >
              <h6>HOME DE USUARIO</h6>
            </div>
          </div>
        </section>
        <section className="show__detail">
          {select.personal && (
            <div className="show__info">
              <h2>DETALLES PERSONALES</h2>
              <hr />
              <div className="fields__show">
                <h6>Nombre Completo:</h6>

                {inputDisable ? (
                  <input
                    type="text"
                    name="name"
                    value={dataPerson.name || ""}
                    disabled
                  />
                ) : (
                  <input
                    className="input__edit"
                    type="text"
                    name="name"
                    defaultValue={dataPerson.name || ""}
                    onChange={handleChange}
                    required
                  />
                )}
              </div>
              <div className="fields__show">
                <h6>Número de identificación:</h6>

                {inputDisable ? (
                  <div style={{ display: "flex" }}>
                    <input
                      type="text"
                      value={dataPerson.person ? dataPerson.person.type_id : ""}
                      disabled
                    />
                    <input
                      type="text"
                      name="cedula"
                      value={dataPerson.person ? dataPerson.person.cedula : ""}
                      disabled
                    />
                  </div>
                ) : (
                  // <input
                  //   type="text"
                  //   name="inputname"
                  //   defaultValue={dataPerson.person.cedula}
                  //   disabled
                  // />
                  <div style={{ display: "flex" }}>
                    <select
                      style={{ marginRight: "5px" }}
                      onChange={handleChange}
                      defaultValue={
                        dataPerson.person
                          ? dataPerson.person.type_id
                          : "Cedula de identificacion"
                      }
                      name="type_id"
                      required
                    >
                      <option value="Cedula de identificacion">
                        Cédula de identificación
                      </option>
                      <option value="NIT">Nit</option>
                      <option value="Passport">Passport</option>
                      <option value="Cedula extranjera">
                        Cédula extranjera
                      </option>
                      <option value="Tarjeta de identidad">
                        Tarjeta de identidad
                      </option>
                    </select>
                    <input
                      className="input__edit"
                      type="text"
                      name="cedula"
                      onChange={handleChange}
                      defaultValue={
                        dataPerson.person ? dataPerson.person.cedula : ""
                      }
                      required
                    />
                  </div>
                )}
              </div>
              {/* <div className="fields__show">
                <h6>Rol:</h6>

                <input
                  type="text"
                  name="inputname"
                  defaultValue={infoAdicional.roles || ""}
                  disabled
                />
              </div> */}
              <div className="fields__show">
                <h6>Fecha de nacimiento:</h6>
                {inputDisable ? (
                  <input
                    type="text"
                    name="birth"
                    value={dataPerson.birth || ""}
                    disabled
                  />
                ) : (
                  <input
                    className="input__edit"
                    type="date"
                    name="birth"
                    onChange={handleChange}
                    defaultValue={dataPerson.birth}
                    required
                  />
                )}
              </div>
              <div className="fields__show">
                <h6>Numero telefonico:</h6>
                {inputDisable ? (
                  <input
                    type="text"
                    name="inputname"
                    value={dataPerson.phone || ""}
                    disabled
                  />
                ) : (
                  <input
                    className="input__edit"
                    type="text"
                    name="phone"
                    onChange={handleChange}
                    defaultValue={dataPerson.phone || ""}
                    required
                  />
                )}
              </div>
              <div className="fields__show">
                <h6>Genero:</h6>
                {inputDisable ? (
                  <input
                    type="text"
                    name="inputname"
                    value={dataPerson ? dataPerson.gender : ""}
                    disabled
                  />
                ) : (
                  <select
                    onChange={handleChange}
                    defaultValue={dataPerson.gender || "Masculino"}
                    name="genre"
                    required
                  >
                    <option value="Masculino">Masculino</option>
                    <option value="Otro">Otro</option>
                    <option value="Femenino">Femenino</option>
                  </select>
                  // <input
                  //   className="input__edit"
                  //   type="text"
                  //   name="genre"
                  //   onChange={handleChange}
                  //   defaultValue={""}
                  //   required
                  // />
                )}
              </div>
              <div className="fields__show">
                <h6>Usuario:</h6>

                {inputDisable ? (
                  <input
                    type="text"
                    name="inputname"
                    value={dataPerson.email || ""}
                    disabled
                  />
                ) : (
                  <input
                    className="input__edit"
                    type="text"
                    name="email"
                    defaultValue={dataPerson.email || ""}
                    onChange={handleChange}
                  />
                )}
              </div>
              {isLoading ? (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <Spinner animation="border" variant="primary" />
                </div>
              ) : inputDisable ? (
                <Button onClick={(e) => EditPerson(e)}>EDITAR DATOS</Button>
              ) : (
                <Button
                  type="submit"
                  onClick={(e) => SavePerson(e, infoPerson)}
                >
                  GUARDAR DATOS
                </Button>
              )}
            </div>
          )}
          {select.document && (
            <DocumentProfile
              state={dataDoc}
              modalShow={modalShow}
              setrefreshData={setrefreshData}
              refreshData={refreshData}
              ModAlertMod={ModAlertMod}
              setModalData={setModalData}
              handleModal={handleModal}
              setTitle={setTitle}
              setData={setData}
            />
          )}
          {select.externalDocument && (
            <ExternalDocumentsProfile
              selectedDocument={query.get('externalDocument')}
              state={externalDataDoc}
              modalShow={modalShow}
            />
          )}
          {select.change && (
            <FormPasswordReset
              ModAlertMod={ModAlertMod}
              setModalData={setModalData}
            />
          )}
        </section>
      </main>
      {modalShow && (
        <ModalMethod
        
          rawDoc={''}
          data={data}
          setData={setData}
          setModalShow={setModalShow}
          title={title}
          dataPdf={dataPdf}
          setDataPdf={setDataPdf}
          inputs={inputs}
          topRight={topRight}
          fieldsTop={fieldsTop}
        />
      )}
      <Footer isProfile />
      <AlertDialogSlide
        modaldata={modalData}
        show={showAlertMod}
        onHide={() => ModAlertMod()}
      />
    </>
  );
}

export default withRouter(Profiles);
