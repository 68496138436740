import React, { useState } from "react";
import { Button, FormGroup, Label } from "reactstrap";
import { Form, Modal } from "react-bootstrap";
import strongPaswordTester from "../../utils/strongPasswrdTest";

const NewUser = (props) => {
  const { showNewMod, ModNewCateSate, handleSubmit, handleChange, data } =
    props;
  const [strongLevel, setStrongLevel] = useState(0);
  
  const validateChange = (e) => {
    if (e.target.name === "password") {
      setStrongLevel(strongPaswordTester(e.target.value));
    }
    handleChange(e);
  };
  const validateSubmit = (e) =>{
    e.preventDefault()
    if((data.password === data.password2) && (strongLevel===1) ) {
      handleSubmit(e)
    }
  }
  return (
    <Modal show={showNewMod} id="ModalAdd" onHide={ModNewCateSate}>
      <Modal.Header closeButton>
        <Modal.Title>Crear Usuario</Modal.Title>
      </Modal.Header>
      <Form role="form" onSubmit={validateSubmit}>
        <Modal.Body>
          <FormGroup className="fields__new">
            <Label>Nombre completo</Label>
            <Form.Control
              type="text"
              name="name"
              required
              onChange={validateChange}
            ></Form.Control>
          </FormGroup>
          <FormGroup className="fields__new">
            <Label>Correo Electronico</Label>
            <Form.Control
              type="email"
              name="email"
              required
              onChange={validateChange}
            ></Form.Control>
          </FormGroup>
          <FormGroup className="fields__new">
            <Label>Contraseña</Label>
            <Form.Control
              type="password"
              name="password"
              required
              onChange={validateChange}
            ></Form.Control>
          </FormGroup>

          <div className="progress mb-1">
            <div
              className={`progress-bar bg-${
                strongLevel <= 0.4 ? "danger" : ""
              }${strongLevel > 0.4 && strongLevel <= 0.8 ? "warning" : ""}${
                strongLevel > 0.8 ? "success" : ""
              }`}
              role="progressbar"
              style={{ width: `${strongLevel * 100}%` }}
              aria-valuenow={strongLevel * 100}
              aria-valuemin="0"
              aria-valuemax="100"
            ></div>
          </div>
          <FormGroup className="fields__new">
            <Label>Confirma tu contraseña</Label>
            <Form.Control
              type="password"
              name="password2"
              required
              onChange={validateChange}
            ></Form.Control>
          </FormGroup>
          <FormGroup className="fields__new">
            <Label>Numero de Documento de Identidad</Label>
            <Form.Control
              type="text"
              name="document_id"
              required
              onChange={validateChange}
            ></Form.Control>
          </FormGroup>
          <Form.Control
            as="select"
            name="edit_badge"
            onChange={validateChange}
            required
          >
            <option disabled selected>
              Seleciona un opcion
            </option>
            <option value="administrator">Administrador</option>
            <option value="superadministrator">Super Adminstrdor</option>
          </Form.Control>
        </Modal.Body>
        <Modal.Footer>
          <Button color="danger" onClick={ModNewCateSate}>
            Close
          </Button>
          <Button type="submit" color="success">
            Save Changes
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default NewUser;
