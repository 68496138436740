import React from "react";
import { Button } from "reactstrap";
import { Modal } from "react-bootstrap";

import UserService from "../../service/user.service";
const DeleteTemplate = (props) => {
  const {
    showDelMod,
    setshowDelMod,
    idTemplate,
    title,
    message,
    setrefreshData,
    refreshData,
    ModAlertMod,
    setModalData,
  } = props;
  const ModDelCateSate = () => setshowDelMod(!showDelMod);

  const handleDelete = (e, id) => {
    e.preventDefault();
    UserService.deleteTemplate(id).then((response) => {
        setModalData({title: "Excelente", body: response.data.message, border: "bg-success"})
        ModDelCateSate();
        ModAlertMod();
    })
    .catch((error) => {
        console.error(error)
        setModalData({title: "Lo sentimos", body:"No se pudo eliminar la plantilla.\n" + error.data, border: "bg-danger"})
        ModAlertMod();
    });
    setrefreshData(!refreshData);
  };
  return (
    <Modal show={showDelMod} id="ModalDelete" onHide={ModDelCateSate}>
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{message}</Modal.Body>
      <Modal.Footer>
        <Button color="secundary" onClick={ModDelCateSate}>
          Cancel
        </Button>
        <Button
          type="submit"
          color="danger"
          id="btn_dele_move_move"
          onClick={(e) => handleDelete(e, idTemplate)}
        >
          Delete
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default DeleteTemplate;
