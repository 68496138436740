import {
  CButton,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
} from "@coreui/react";
import React from "react";
import { ReactComponent as CloseSVG } from "../../assets/svg/cross.svg";

function PreviewDocument({
  documentHook = [],
  title = "Alerta",
  onConfirm = () => {},
  onCancel = () => {},
}) {
  const [documentHTML, setDocument] = documentHook;
  const HandleClose = async (e) => {
    e?.preventDefault();
    onConfirm();
    setDocument("");
  };
  const HandleCancel = async (e) => {
    e.preventDefault();
    onCancel();
    setDocument("");
  };

  return (
    <>
      <CModal
        size="lg"
        fullscreen="sm"
        className=""
        visible={Boolean(documentHTML)}
        onClose={HandleClose}
      >
        <CModalBody className="preview_document">
          <button
            onClick={HandleClose}
            type="button"
            className="close_preview_document"
          >
            <CloseSVG />
          </button>
          <div className="preview_document_content">
            <h6>{title}</h6>
            <div
              key={`pLabels_`}
              dangerouslySetInnerHTML={{
                __html: documentHTML,
              }}
              // onClick={(event) => handleChange(event, document.vars)}
            ></div>
          </div>
        </CModalBody>
      </CModal>
    </>
  );
}

export default React.memo(PreviewDocument);
