import React, {  useEffect, useState } from "react";

import { Modal, Spinner } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { Button } from "reactstrap";
import { logout } from "../../actions/auth";
import "../../assets/styles/components/ListDocumentSelect.scss";
import UserService from "../../service/user.service";
import { searchElementByName } from "../../utils/searchElementByName";

function getRandomNumberNLength(n=1) {
  var numberLength = ''
  for(let i=0; i<n;i++){
    numberLength+= `${Math.floor(Math.random() * (10 - 0) + 0)}`
  }
  return numberLength
}
const ListDocumentSelect = (props) => {
  const {
    dataList,
    setDataList,
    stateList,
    setStateList,
    nameList,
    // // setSearchData,
    // // searchData,
    listId,
  } = props;
  const [selectButton, setSelectButton] = useState([]);
  const [selectText, setSelectText] = useState([]);
  const dispatch = useDispatch();
  useEffect(() => {
    const test = [{
      "name": "Municipio",
      "texts": [
          {
              "name": "Medellín"
          }
      ]
  }]
  if(stateList[nameList]){
      
      setSelectButton([{...stateList[nameList]}])
      const values = {...stateList[nameList]}
      const name = values.name
      delete values.name
      setSelectText([{
        name:name,
        texts:Object.values(values).map(text=>({name:text}))
      }])
    }
  
    return () => {
      
    }
  }, [stateList])
  
  const addList = (e, item) => {
    
    if(!listId.multi && selectButton.length!==0) {
      const keys =  Object.keys(selectButton[0])

      if(keys.length>=2)return
    }
    let _array1 = [];
    var propietyname = item.name;
    if (selectButton.length === 0) {
      let _varAux = { name: nameList };
      propietyname = propietyname.replace(/ /g, "_")
      propietyname = propietyname.substring(0,6)
      propietyname += getRandomNumberNLength(3)
      _varAux[propietyname] = item.name;
      _array1.push(_varAux);
      setSelectButton(() => _array1);
    } else {
      let isThereList = false;
      let _listToSet = [];
      selectButton.forEach(function (button, index) {
        let _button = button;
        if (button.name === nameList) {
          propietyname = propietyname.replace(/ /g, "_")
          propietyname = propietyname.substring(0,6)
          propietyname += getRandomNumberNLength(3)
          _button[propietyname] = item.name;
          isThereList = true;
        }
        _listToSet.push(_button);
      });
      if (!isThereList) {
        let _button = { name: nameList };
        propietyname = propietyname.replace(/ /g, "_")
        propietyname = propietyname.substring(0,6)
        propietyname += getRandomNumberNLength(3)
        _button[propietyname] = item.name;
        _listToSet.push(_button);
      }
      setSelectButton(_listToSet);
    }
    let _arrayText = [];
    if (selectText.length === 0) {
      _arrayText = [{ name: nameList, texts: [item] }];
    } else {
      let isThereList = false;
      selectText.forEach(function (text, index) {
        let _text = text;
        if (text.name === nameList) {
          _text.texts.push(item);
          isThereList = true;
        }
        _arrayText.push(_text);
      });
      if (!isThereList) {
        let _text = { name: nameList, texts: [item] };
        _arrayText.push(_text);
      }
    }
    setSelectText(_arrayText);
    
  };
  const searchDocument = (event, dataState) => {
    UserService.getListId(listId.value).then(
      (res) => {
        let list;
        list = res.data.message.items.filter((item) => item != null);
        let items = searchElementByName(list,event.target.value);

        setDataList({ ...res.data.message, items });
      },
      (error) => {
        const _content =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        if (_content === "Unauthenticated.") {
          dispatch(logout());
        }
      }
    );

    // const value = searchDocument(event.target.value, dataState);
    // if (event.target.value === "") {
    //   setDataList(searchData);
    // } else if (value.length !== 0) {
    //   let _dataTotest = dataList;
    //   _dataTotest.items = value;

    //   // setSelectButton(selectButton);
    //   setDataList(_dataTotest);
    // }
  };

  const removeList = (param) => {

    if(selectButton.length!==0 ){
      const keys =  Object.keys(selectButton[0])
      //console//.log(keys,listId)
    }
    let _listToSet = [];
    let valueDocument = ''

    selectButton.forEach(function (button, index) {
      let _button = button;
      if (button.name === nameList) {
        valueDocument = _button[param]
        delete _button[param];
      }
      _listToSet.push(_button);
    });
    setSelectButton(_listToSet);
    let _arrayText1 = [];
    selectText.forEach(function (text3, index) {
      let _text = text3;
      if (text3.name === nameList) {
        _text.texts = text3.texts.filter(function (itemText, i) {
          
          return (itemText.name !== valueDocument)
        });;
        _arrayText1.push(_text);
      }
    });

    setSelectText(_arrayText1);
  };

  const handleSaveList = (e, selectButton) => {
    for (const key in selectButton) {
      if (selectButton[key] !== undefined) {
        let value = selectButton[key];
        setStateList(value);
      }
    }
    props.onHide();
  };

  return (
    <Modal
      show={props.show}
      onHide={props.onHide}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      scrollable={true}
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Selecciona lista en {props.dataList.name}
        </Modal.Title>
      </Modal.Header>
      <input
        type="text"
        className="search-input mx-5 mt-3"
        placeholder="Buscar"
        onChange={(e) => searchDocument(e, dataList.items)}
      />
      <Modal.Body>
      
      {dataList.items && dataList.items.length > 0 ? (
          dataList.items.map((item, id) => {
            if (item === null) return <></>;
            let _buttonSelected = false;
            selectButton.forEach(function (button, index) {
              for(const element in button){
                if (button[element]===item.name && button.name === nameList) {
                  _buttonSelected = element;
                  break
                }
              }
            });
            if (item !== null) {
              return (
                <div className="col-lg-12 col-sm-12 select-list card" key={id}>
                  <div className="card-info">
                    {" "}
                    <h2 className="card-title-list">{item.name}</h2>
                    <div>
                      <h6>
                        {item.description
                          ? item.description
                          : "Sin Descripción"}
                      </h6>
                    </div>
                  </div>
                  {!_buttonSelected ? (
                    <div
                      className="btn-group btn-select"
                      role="group"
                      onClick={(event) => {
                        addList(event, item);
                      }}
                    >
                      {/*
                      <input type="button" name={item.name} value=" + Añadir" /> */}
                      <button
                        name={item.name}
                        type="button"
                        id="stars"
                        className="btn btn-primary"
                      >
                        <span
                          className="glyphicon glyphicon-star"
                          aria-hidden="true"
                        >
                          {" "}
                          + Añadir
                        </span>
                        <div className="hidden-xs"></div>
                      </button>
                    </div>
                  ) : (
                    <div
                      className="btn-group btn-select"
                      role="group"
                      onClick={(e) => removeList(_buttonSelected)}
                    >
                      <button
                        type="button"
                        id="stars"
                        className="btn btn-warning"
                      >
                        <span
                          className="glyphicon glyphicon-star"
                          aria-hidden="true"
                        ></span>
                        <div className="hidden-xs"> - Quitar</div>
                      </button>
                    </div>
                  )}
                </div>
              );
            }
            return <></>
          })
        ) : (
          <div className="d-flex justify-content-center m-auto">
            <Spinner animation="border" variant="primary" />
          </div>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={(e) => handleSaveList(e, selectButton)}>
          Guardar
        </Button>
        <Button onClick={props.onHide}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ListDocumentSelect;
