import { CPagination, CPaginationItem } from "@coreui/react";
import React from "react";

function PaginationFull({
    maxBtnPerSide = 1,
  currentPage = 0,
  totalPage = 0,
  onChangePage = () => {},
}) {
    const buttons = [...Array((2*maxBtnPerSide)+1)].map((x,i) => i - maxBtnPerSide)
  return (
    <CPagination align="center" addListClass="some-class">
      {currentPage !== 1 ? (
        <CPaginationItem
          onClick={() => {
            onChangePage(1);
          }}
          aria-label="First"
        >
          Primera
        </CPaginationItem>
      ) : (
        ""
      )}
      <CPaginationItem
        onClick={() => {
          onChangePage(currentPage - 1);
        }}
        aria-label="Previous"
        disabled={currentPage - 1 <= 0}
      >
        <span aria-hidden="true">&laquo;</span>
      </CPaginationItem>

      {buttons.map((e) =>{
        if(
            (currentPage + e <=0) || 
            (currentPage + e>totalPage)
        ) {
            return <></>
        }
        return <CPaginationItem
        key={`btn_page_${currentPage + e}`}
          onClick={() => {
            onChangePage(currentPage + e);
          }}
        >
          {currentPage + e}
        </CPaginationItem>
      })}


      <CPaginationItem
        onClick={() => {
          onChangePage(currentPage + 1);
        }}
        aria-label="Next"
        disabled={currentPage + 1 > totalPage}
      >
        <span aria-hidden="true">&raquo;</span>
      </CPaginationItem>
      {currentPage !== totalPage ? (
        <CPaginationItem
          onClick={() => {
            onChangePage(totalPage);
          }}
          aria-label="Last"
          disabled={currentPage + 1 > totalPage}
        >
          Ultima
        </CPaginationItem>
      ) : (
        ""
      )}
    </CPagination>
  );
}

export default PaginationFull;
