import { useContext } from "react";
import toastContext from "../Contexts/toast-configuration";
/**
 * 
 * @returns function({
            title=String,
            subtitle= String,
            icon=ReactComponent,
            body=String
        })
 */
export default function useToast(){
    return useContext(toastContext)
}