import React, { useEffect, useState } from "react";
import { Button } from "reactstrap";

import UserService from "../service/user.service";
import NewCatg from "../components/modals/NewCatg";
import DeleteCateg from "../components/modals/DeleteCateg";
import { useDispatch } from "react-redux";
import { Redirect } from "react-router-dom";
import { logout, postNewCateg } from "../actions/auth";
import { CPagination } from "@coreui/react";
import AlertDialogSlide from "./modals/AlertDialogSlide";
import EditCateg from "./modals/EditCateg";

import "../assets/styles/components/TableUser.scss";
import { searchElementByName } from "../utils/searchElementByName";
const TableCatego = () => {
  const [data, setData] = useState([]);
  const [, setLoader] = useState(true);
  const [state, setState] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState(0);
  const [clickButton, setClick] = useState("btn-shadow btn btn-dark");
  const [transfor, setTransform] = useState(false);
  const [close, setClose] = useState(false);
  const [data_show, setShowData] = useState({});
  const [infoCatg, setCatg] = useState({
    name: "",
    description: "",
    icon_path: "",
  });
  const [showEdiMod, setshowEdiMod] = useState(false);
  const [showDelMod, setshowDelMod] = useState(false);
  const [showNewMod, setshowNewMod] = useState(false);
  const [refreshData, setrefreshData] = useState(false);
  const [showAlertMod, setshowAlertMod] = useState(false);
  const [modalData, setModalData] = useState({
    title: "",
    body: "",
    border: "",
  });

  const dispatch = useDispatch();
  const ModEdiCateSate = () => setshowEdiMod(!showEdiMod);
  const ModDelCateSate = () => setshowDelMod(!showDelMod);
  const ModNewCateSate = () => setshowNewMod(!showNewMod);
  const ModAlertMod = () => setshowAlertMod(!showAlertMod);

  const logOut = () => {
    dispatch(logout());
    setClose(true);
  };

  const handleClickClose = () => {
    setTransform(false);
    //setClass({ butn: "btn-shadow btn btn-dark" });
  };

  useEffect(() => {
    setLoader(true);
    UserService.getCetegorias("page", currentPage).then(
      (response) => {
        setState(response.data.data);
        setTotalPage(response.data.last_page);
        setLoader(false);
      },
      (error) => {
        const _content =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        console.error(_content);
        logOut();
      }
    );
  }, [refreshData]);

  const handleClickSearch = () => {
    setTransform(true);
    setClick("btn-shadow btn btn-dark btn-border");
  };
  /*Buscando categoria */
  // function searchDocument(valueFind, stateData) {
  //   let element = [];
  //   const valueMatches = (value, searchKey) =>
  //     typeof value === "string" &&
  //     value.toUpperCase().includes(searchKey.toUpperCase());

  //   element = stateData.filter((obj) =>
  //     Object.keys(obj).some((key) => valueMatches(obj[key], valueFind))
  //   );
  //   return element;
  // }

  const handleSearchDocuemnt = (event, dataState) => {
    const value = searchElementByName(dataState,event.target.value);

    if (event.target.value === "") {
      setData([]);
    } else if (value.length !== 0) {
      setData(value);
    }
  };

  const handleChangesPage = (number_page) => {
    UserService.getCetegorias("page", number_page).then(

      (response) => {
        setState(response.data.data);
        setCurrentPage(response.data.current_page);
      },
      (error) => {
        const _content =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        console.error(_content);
        /* if (_content == "Unauthenticated") {
          dispatch(logout());
        } */
      }
    );
  };

  const OpenModalNew = (e) => {
    e.preventDefault();
    ModNewCateSate();
  };

  const handleChange = (e) => {
    setCatg({
      ...infoCatg,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    dispatch(
      postNewCateg(infoCatg.name, infoCatg.description, infoCatg.icon_path)
    )
      .then((response) => {
        setModalData({
          title: "Felicidades",
          body: response.data.message,
          border: "bg-success",
        });
        ModAlertMod();
      })
      .catch((error) => {
        setModalData({
          title: "Lo sentimos",
          body: "No se realizo la creación de la categoria.\n",
          border: "bg-danger",
        });
        ModAlertMod();
      });
    ModNewCateSate();
    setrefreshData(!refreshData);
  };

  const OpenModalEdit = (e, dataCatg) => {
    e.preventDefault();
    setShowData(dataCatg);
    ModEdiCateSate();
  };

  const handleModalDelete = (e,dataCatg) => {
    e.preventDefault();
    setShowData(dataCatg);
    ModDelCateSate();
  };

  return (
    <div className="show_detail">
      {close && <Redirect to="/login/admin" />}
      <div >
        <div className="header__field">
          <div className="image__list">
            <i className="fas fa-angle-double-up"></i>
          </div>
          <div className="text__information">
            <h4>Administracion de Categorias</h4>

            <p>Lorem ipsum dolor, sit amet consectetur adipisicing elit.</p>
          </div>
          <div className="create__or__search active">
            <div className="input-search d-flex">
              {transfor ? (
                <>
                  <button
                    className={clickButton}
                    onClick={(e) => handleClickClose(e)}
                  >
                    <i className="fas fa-times"></i>
                  </button>

                  <input
                    type="text"
                    className="search-input"
                    placeholder="Buscar categorias"
                    onChange={(e) => handleSearchDocuemnt(e, state)}
                  />
                </>
              ) : (
                <button
                  className={clickButton}
                  onClick={(e) => handleClickSearch()}
                >
                  <i className="fas fa-search"></i>
                </button>
              )}

              <Button className="btn-search" onClick={OpenModalNew}>
                <i className="fas fa-user-plus"></i>
                <span className="ml-3">Nueva categoria</span>
              </Button>
            </div>
          </div>
        </div>
        <div className="table-responsive">

        <table className="table table-light table-striped ">
          <thead id="dataTableHead">
            <tr className="head-table">
              <th scope="col">#</th>
              <th scope="col">id</th>
              <th scope="col">Nombre de la Categoria</th>
              <th scope="col">Descripción</th>
              <th scope="col">Documentos utilizados</th>
              <th scope="col">Añadir documentos</th>
            </tr>
          </thead>
          <tbody className="">
            {state ? (
              data.length > 0 ? (
                data.map((data, i) => {
                  return (
                    <tr key={i}>
                      <th scope="row">{i}</th>
                      <td>{data.id}</td>
                      <td>{data.name}</td>
                      <td>
                        {data.description
                          ? data.description
                          : "Sin descripción"}
                      </td>
                      <td>15 / 20</td>
                      <td>
                        <button
                          type="button"
                          className="btn btn-warning"
                          onClick={(e) => OpenModalEdit(e, data)}
                        >
                          <i className="fas fa-eye"></i>
                        </button>
                        &nbsp;&nbsp;&nbsp;
                        <button
                          type="button"
                          className="btn btn-primary"
                          onClick={(e)=>handleModalDelete(e,data)}
                        >
                          <i className="far fa-trash-alt white"></i>
                        </button>
                      </td>
                    </tr>
                  );
                })
              ) : (
                state.map((data, i) => {
                  return (
                    <tr key={i}>
                      <th scope="row">{i}</th>
                      <td>{data.id}</td>
                      <td>{data.name}</td>
                      <td>
                        {data.description
                          ? data.description
                          : "Sin descripción"}
                      </td>
                      <td>15 / 20</td>
                      <td style={{display: "flex"}}>
                        <button
                          type="button"
                          className="btn btn-warning"
                          onClick={(e) => OpenModalEdit(e, data)}
                        >
                          <i className="fas fa-eye"></i>
                        </button>
                        &nbsp;&nbsp;&nbsp;
                        <button
                          type="button"
                          className="btn btn-primary"
                          onClick={(e)=>handleModalDelete(e,data)}
                        >
                          <i className="far fa-trash-alt white"></i>
                        </button>
                      </td>
                    </tr>
                  );
                })
              )
            ) : (
              <div className="loader-page"></div>
            )}
          </tbody>
        </table>
        </div>

        <CPagination
          style={{ marginTop: "20px"}}
          align="center"
          addListClass="some-class"
          activePage={currentPage}
          pages={totalPage}
          onActivePageChange={handleChangesPage}
        />
        <br></br>
      </div>
      <NewCatg
        showNewMod={showNewMod}
        setshowNewMod={setshowNewMod}
        ModNewCateSate={ModNewCateSate}
        setrefreshData={setrefreshData}
        refreshData={refreshData}
        handleChange={handleChange}
        handleSubmit={handleSubmit}
      />
      <EditCateg
        title={data_show.name}
        showEdiMod={showEdiMod}
        setshowEdiMod={setshowEdiMod}
        setrefreshData={setrefreshData}
        refreshData={refreshData}
        data={data_show}
        ModAlertMod={ModAlertMod}
        setModalData={setModalData}
      />
      <DeleteCateg
        showDelMod={showDelMod}
        setshowDelMod={setshowDelMod}
        title={"Eliminar Categoria: " + data_show.name}
        message={"¿Estas seguro de eliminar la categoria seleccionada?"}
        refreshData={refreshData}
        setrefreshData={setrefreshData}
        ModAlertMod={ModAlertMod}
        setModalData={setModalData}
        isDocument={true}
        data={data_show}
      />
      <AlertDialogSlide
        modaldata={modalData}
        show={showAlertMod}
        onHide={() => ModAlertMod()}
      />
    </div>
  );
};

export default TableCatego;
