import React, { useEffect, useState } from 'react'
import { withRouter, useParams, useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import UserService from "../service/user.service";
import AuthService from "../service/auth.service";
import { useSelector } from 'react-redux';
import Routes from '../routes/Routes';
import AlertUser from '../components/modals/AlertUser';
import signService from '../service/sign.service';
import useToast from '../utils/CustomHooks/useToast';

function ValidatePayment(props) {
    let query = new URLSearchParams(props.location.search);
    const user = useSelector((state)=> state?.auth?.user)
    const addToast= useToast();
    const {id:order_id} = useParams()
    const history = useHistory()
    const [errorMsg, setErrorMsg] = useState('');
    useEffect(() => {
        const requestPaymentStatus = async () =>{
          try {
            const {data:status} = await UserService.GetPayment(query.get('payment_id'))
            const{response:payment} = status.data
            const {data:orderDTA} = await UserService.GetOrder(order_id)
            let order = orderDTA.data
            let externalSign = false
            if(!order?.id ) {
              order = await signService.requestExternalSignTemporalDocument(user?.id + '/'+order_id)
              externalSign = Boolean(order)
            }
            if(
              // payment.external_reference!==order_id || 
              user?.id!==order.user_regular_id || 
              !order)  {
              setErrorMsg('Datos de pago erróneos')
              throw new Error('error validate')
            }
            if(!externalSign) {
              const update = await AuthService.updateDocument({
                document_id:order.firebase_document_id,
                payment_id:payment.id,
                order_id:order.id,
                status_document: payment.status
              })
              const valid_states = ['pagado', "necesita_firma"]
              if(valid_states.includes(update.state)){
                const {template} = update
                history.push(`${Routes.methods(template?.name.replace(/ /g,'-'))}?order_id=${order.id}`,{doc:template})
                return
              } else {
                if(payment.status==='rejected'){
                  setErrorMsg('El pago fue Rechazado')
                  addToast({
                    title:'El pago fue rechazado',
                    mode:'danger'
                  })
                }
              }
            } else {
              if(payment.status === 'approved') {
                order = await signService.createExternalFirmDocument(user?.id + '/'+ order_id, query.get('payment_id'), payment)
                history.push(`${Routes.profile}?externalDocument=${order.id}`,)
                return
              }
            }
            throw new Error('error validate')
          } catch (error) {
            console.error(error)
            addToast({
              title:'Error al procesar el pago',
              mode:'danger'
            })
            history.push(Routes.base,{Redirect:true})
          }
        }
        if(user?.id) 
          requestPaymentStatus()
        if(!user) 
          history.push(Routes.base,{Redirect:true})
        return () => {
            
        };
    }, [user]);

    const handleGoBack =()=>{
      
      history.push(`${Routes.base}`)
    }

  return (
    <>
    <div
      style={{ height: "100vh" }}
      className="w-100 d-flex justify-content-center align-items-center"
    >
      <span className="spinner-border "></span>
    </div>
    
    <AlertUser
      title='Parece que hubo un error'
      content={errorMsg}
      setShow={()=>{setErrorMsg('')}}
      onCancel={handleGoBack}
      onConfirm={handleGoBack}

        show={Boolean(errorMsg)}
      />
    </>
  )
}

export default withRouter(ValidatePayment)