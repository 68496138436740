import {
  CCol,
  CForm,
  CModalBody,
  CModalTitle,
  CRow,
} from "@coreui/react";
import React, { useEffect, useRef, useState } from "react";
import CreateDocuments from "../CreateDocument";
import AuthService from "../../../service/auth.service";
import { Button } from "reactstrap";
import { Modal } from "react-bootstrap";
import useArray from "../../../utils/CustomHooks/useArray";
import StepperPageComponent from "./StepperPageComponent";
import moment from "moment";
import { ReactComponent as CloseSVG } from "../../../assets/svg/cross.svg";
import _, { isUndefined } from "lodash";
import { sanitize } from 'dompurify';

function ModalStepperForm({
  test,
  documentDta = {},
  showHook = [],
  dataHook = [],

  rawDoc,
  fieldsTop, //variables
  inputs, //lists
}) {
  const [show, setShow] = showHook;
  const [data, setData] = dataHook;
  const [actualPage, setActualPage] = useState(0);
  const [paymentModal, setPaymentModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [stateVars, setStateVars] = useState({});
  const [stateList, setStateList] = useState({});
  const [listOptionsDta, setListOptionsDta] = useState({});
  const [pricesToPay, setPricesToPay] = useState({
    subTotal: 0,
    total: 0,
    ivaAmount: 0,
    superviseAmount: 0,
  });
  const {
    array: pLabels,
    set: setPLabels,
    /*
    update: updatePlabel,
    push, 
    filter, 
    remove, 
    clear 
    */
  } = useArray();
  const pLabelsPerPage = useRef([]);

  const [alertInputsMissing, setAlertInputsMissing] = useState(false);
  const [needFirm, setNeedFirm] = useState(false);
  const [totalSteppers, setTotalSteppers] = useState(0);

  useEffect(() => {
    const subTotal = parseFloat(documentDta.price);
    let percentageTotal = parseInt(documentDta.iva),
      supervisePrice = 0;

    if (documentDta.supervise) {
      if (documentDta.percentage_check) {
        percentageTotal =
          percentageTotal + parseInt(documentDta.supervise_price);
      } else {
        supervisePrice = parseFloat(documentDta.supervise_price);
      }
    }

    percentageTotal = percentageTotal / 100;
    const total = subTotal / (1 - percentageTotal) + supervisePrice;

    const amountIva = total * (parseInt(documentDta.iva) / 100);

    supervisePrice = total - amountIva - subTotal;
    const pricesToSave = {
      subTotal: parseInt(Math.round(documentDta.price)),
      total: parseInt(Math.round(total)),
      iva: parseInt(documentDta.iva),
      ivaAmount: parseInt(Math.round(amountIva)),
      supervisePercentage: documentDta.percentage_check
        ? parseInt(documentDta.supervise_price)
        : undefined,
      superviseAmount: Math.round(supervisePrice),
    };
    setPricesToPay(pricesToSave);

    setNeedFirm(documentDta.need_firm);

    const totalStep = documentDta.need_firm
      ? documentDta.stepperPages?.length + 2
      : documentDta.stepperPages?.length + 1;
    setTotalSteppers(totalStep);

    if (show) {
      const parrafosRaw = updateDocument({})

      const labelsforPage = [];
      for (const step of documentDta.stepperPages) {
        const indexLabels = [];

        if (step.variables)
          step.variables.forEach((variables) => {
            indexLabels.push(
              parrafosRaw.findIndex((par) => par.includes(variables.name))
            );
          });
          else {
            if (step.lists)
              step.lists.forEach((list) => {
                indexLabels.push(
                  parrafosRaw.findIndex((par) => par.includes(list))
                );
              });
            if (step.vars)
              step.vars.forEach((vari) => {
                indexLabels.push(
                  parrafosRaw.findIndex((par) => par.includes(vari))
                );
              });
          }
        const dataArr = new Set(indexLabels);

        let result = [...dataArr];

        result.sort((a, b) => {
          return a - b;
        });
        labelsforPage.push(result);
      }

      pLabelsPerPage.current = labelsforPage;
      setPLabels(parrafosRaw);
    } else {
      setPLabels([]);
      pLabelsPerPage.current = [];
    }
    return () => {
      setPLabels([]);
      pLabelsPerPage.current = [];
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show]);

  useEffect(() => {
    if (Boolean(alertInputsMissing)) {
      setTimeout(() => {
        setAlertInputsMissing(false);
      }, 3000);
    }
  }, [alertInputsMissing]);
  useEffect(() => {
    const disableContextMenu = (e) => {
      e.preventDefault();
    };
    document.addEventListener("copy", disableContextMenu, false);
    document.addEventListener("contextmenu", disableContextMenu, false);
    return () => {
      document.removeEventListener("copy", disableContextMenu);
      document.removeEventListener("contextmenu", disableContextMenu);
    };
  }, []);

  const OpenSubmit = async (e) => {
    e.preventDefault();
    const vars2 = { ...stateVars },
      list2 = { ...stateList };
    setLoading(true);
    delete vars2["conf1rm_f1rmed_Document"];

    // if (getLengthOfObject(vars2) > 0 || getLengthOfObject(list2) > 0) {
    const document_need_firm = stateVars["conf1rm_f1rmed_Document"];
    if (needFirm && !document_need_firm) {
      setLoading(false);
      setAlertInputsMissing("Seleccione si el documento requiere firma");
      return;
    }
    const optionals_variables_list = documentDta.optionals_variables_list || []
    const varsListToFill = [
      ...fieldsTop.map((val) => val.msg),
      ...inputs.map((val) => val.name),
    ].filter(name=>{return !optionals_variables_list.includes(name)}).sort();
    
    const listToCheck = [...Object.keys(list2), ...Object.keys(vars2)].filter(name=>{return !optionals_variables_list.includes(name)}).sort();

    if (_.isEqual(varsListToFill, listToCheck)) {
      /*
      const data_to_sent = {
        ...data,
        vars: vars2,
        lists: list2,
        // pagado: false,
        // template_id
      }; 
      */
      if (test) {
        const pdfUrl = await AuthService.createTestDocument({
          documentHTML: data.document,
        });
        var downloadLink = document.createElement("a");
        downloadLink.href = pdfUrl;
        downloadLink.target = "_blank";
        downloadLink.download = "example.pdf";
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
        downloadLink.remove();
        setLoading(false);
        return;
      }

      let listString = [];
      for (const key in list2) {
        listString.push(list2[key]);
      }
      /*
      setDataPdf({
        ...dataPdf,
        need_firm : document_need_firm && document_need_firm==='si',
        vars2: vars2,
        list2: listString.join(),
        id: data["id"],
        document: data["document"],
      });
      */
      setPaymentModal(true);
    } else {
      const notIncluded = varsListToFill.find(
        (value) => !listToCheck.includes(value)
      );
      setLoading(false);
      setAlertInputsMissing(`Falta el valor de '${notIncluded}'`);
    }
    setLoading(false);
  };

  const updateDocument = ({ lists = stateList, inputs = stateVars }) => {
    let _arrayObject = {};

    Object.entries(lists).forEach(([key, input]) => {
      _arrayObject[key] = Object.values(input);
    });
    let _array = rawDoc.split("~");
    var _data = data;
    let introduceList = _array.map((variable) => {
      if (!isUndefined(_arrayObject?.[variable])) {
        return `~<span>${_arrayObject[variable].join(", ")}</span>~`;
      } else {
        if(!variable.includes(' ')) return `<span>${variable}</span>`;
        return variable;
      }
    });
    introduceList = introduceList.join("~");
    // eslint-disable-next-line no-useless-escape
    introduceList = introduceList.replace(/\~\~/g, "");

    _array = introduceList.split("|");
    let finalDocument = _array.map((variable) => {
      if (!isUndefined(inputs?.[variable])) {
        return `|<span>${inputs[variable]}</span>|`;
      } else {
        if(!variable.includes(' ')) return `<span>${variable}</span>`;
        return variable;
      }
    });
    finalDocument = finalDocument.join("|");
    finalDocument = finalDocument.replace(/\|\|/g, "");
    _data.document = finalDocument;
    const parrafos = _data.document
      .split("<p")
      .filter((p) => Boolean(p))
      .map((p) => `<p${p}`);

    setPLabels(parrafos);
    setData(_data);
    return parrafos
  };

  const handleChange = (e) => {
    let { name, value,  type } = e.target;
    const variableType = documentDta.vars[name];
    if (type === "date") {
      if (value === "") return;
      value = moment(value).format("DD-MM-YYYY");
    } else if (type === "number") {
      if (variableType === "Integer") {
        value = parseInt(value.replace(/,/g, ""));
      } else if (variableType === "Float") {
        value = parseFloat(value.replace(/,/g, ""));
      }
    }

    e?.preventDefault?.();
    setStateVars((oldState) => {
      const copyState = {
        ...oldState,
        [name]: value,
      };
      if (!copyState[name]) delete copyState[name];
      updateDocument({
        inputs: { ...copyState },
      });
      return copyState;
    });
  };
  const handleEditList = (value, name) => {
    const prevStateList = { ...stateList };
    prevStateList[name] = value;
    if (Object.keys(value).length === 0) delete prevStateList[name];
    setStateList(prevStateList);
    updateDocument({ lists: prevStateList });
  };

  const handleNextPage = (e) => {
    if (actualPage === totalSteppers - 1) {
      OpenSubmit(e);
      return;
    }
    const optionalList = documentDta.optionals_variables_list||[]
    if(actualPage === totalSteppers - 2) {
      const listDOC = Object.keys(documentDta.lists).filter(key => optionalList.includes(key)).reduce((acc, current) => {
        acc[current] = {empty345:''}
        return  acc
         
      },{})
      const varsDOC = Object.keys(documentDta.vars).filter(key => optionalList.includes(key)).reduce((acc, current) => {
        acc[current] = ''
        return  acc
         
      },{})
      const prevStateList = {...listDOC, ...stateList };
      const prevStateVars = {...varsDOC, ...stateVars };
      
      setStateVars(prevStateVars)
      setStateList(prevStateList)
      updateDocument({ lists: prevStateList,inputs:prevStateVars });
    }
    setActualPage((actual) => {
      return actual + 1;
    });
  };
  return (
    <>
      <Modal
      size="xl"
        alignment="center"
        className={`modal_create_document ${paymentModal ? "hide" : ""}`}
        backdrop={false}
        show={show}
        
      >
        <CModalBody>
          <CRow>
            <CCol className="modal_create_document_form my-3" md="6">
              <div className="stepper_indicator">
                {documentDta?.stepperPages?.map((pge, ind) => {
                  return (
                    <div
                      style={{
                        width: `${100 / totalSteppers}%`,
                      }}
                      className={actualPage >= ind ? "active" : ""}
                      key={`pge_${ind}`}
                    ></div>
                  );
                })}
                {needFirm && (
                  <div
                    style={{
                      width: `${100 / totalSteppers}%`,
                    }}
                    className={
                      actualPage >= documentDta.stepperPages?.length
                        ? "active"
                        : ""
                    }
                    key={`need_firm_page`}
                  ></div>
                )}
                <div
                  style={{
                    width: `${100 / totalSteppers}%`,
                  }}
                  className={actualPage === totalSteppers - 1 ? "active" : ""}
                  key={`pge_final`}
                ></div>
              </div>
              <CForm>
                <StepperPageComponent
                  page={actualPage}
                  needFirm={needFirm}
                  totalSteppers={totalSteppers}
                  varsDictionary={documentDta.vars}
                  listsDictionary={documentDta.lists}
                  onChangeVarsSelected={handleChange}
                  onChangeListSelected={handleEditList}
                  VarsSelected={stateVars}
                  listSelected={stateList}
                  steps={documentDta?.stepperPages}
                  listDtaHook={[listOptionsDta, setListOptionsDta]}
                  needSupervise={documentDta.supervise}
                  priceDta={pricesToPay}
                  optionals_variables_list={
                    documentDta.optionals_variables_list
                  }
                ></StepperPageComponent>
                <div className="d-flex justify-content-end">
                  {alertInputsMissing ? (
                    <CCol className="select_list_doc">
                      <Button color="danger" disabled>
                        {alertInputsMissing}
                      </Button>
                    </CCol>
                  ) : loading ? (
                    <span className="spinner-border spinner-border-sm"></span>
                  ) : (
                    <>
                      <button
                        type="button"
                        className="prev_step_btn"
                        onClick={() => {
                          if (actualPage === 0) return;
                          setActualPage((actual) => {
                            return actual - 1;
                          });
                        }}
                      >
                        Regresar
                      </button>
                      <button
                        type="button"
                        onClick={handleNextPage}
                        className="next_step_btn"
                      >
                        Siguiente
                      </button>
                    </>
                  )}
                </div>
              </CForm>
            </CCol>
            <CCol className="modal_create_document_preview" md="6">
              <button
                onClick={() => {
                  setShow(false);
                }}
                type="button"
                className="close_modal_stepper"
              >
                <CloseSVG />
              </button>
              {!paymentModal && <div className="preview_container">
                <CModalTitle>{documentDta.name}</CModalTitle>
                {pLabelsPerPage.current?.[actualPage]?.map((pos, i) => {
                  return (
                    <div
                      key={`pLabels_${i}`}
                      dangerouslySetInnerHTML={{
                        __html: sanitize(pLabels[pos]),
                      }}
                      // onClick={(event) => handleChange(event, document.vars)}
                    ></div>
                  );
                })}
                {actualPage === totalSteppers - 1 ? (
                  <div
                    key={`pLabels_`}
                    dangerouslySetInnerHTML={{
                      __html: sanitize(data.document),
                    }}
                    // onClick={(event) => handleChange(event, document.vars)}
                  ></div>
                ) : (
                  ""
                )}
              </div>}
            </CCol>
          </CRow>
        </CModalBody>
      </Modal>
      {!test ? (
        <CreateDocuments
          show={paymentModal}
          onHide={() => {
            setLoading(false);
            setPaymentModal(false);
          }}
          userDoc={{
            ...data,
            need_firm: stateVars["conf1rm_f1rmed_Document"] === "si",
            vars2: { ...stateVars, conf1rm_f1rmed_Document: undefined },
            list2: Object.values(stateList),
            id: data["template_id"],
            document: data["document"],
          }}
          price={pricesToPay.total}
          doc={documentDta}
        />
      ) : (
        <></>
      )}

      {/*
      <AlertDialogSlide
        modaldata={modalData}
        show={showAlertMod}
        onHide={ModAlertMod}
      />
      */}
    </>
  );
}
const propsAreEqual = (prev, post) => {
  return false;
};
export default React.memo(ModalStepperForm, propsAreEqual);
