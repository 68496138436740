import React from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import PaymentMethod from "../components/PaymentMethod";
import { useState } from "react";
import { protectUserComponent } from "../utils/protectUserComponent";

const Payment = (props) => {

  const [showModal, setShowModal] = useState(false);
  return (<div className="body__panel">
  <Header isLogged NotCreate />
  <PaymentMethod setShowModal={setShowModal} />
  <Footer isLogged showModal={showModal} />
</div>)
};

export default protectUserComponent(Payment);
