import React from 'react'
import { connect } from 'react-redux'
import {  Redirect } from 'react-router-dom'
import PropTypes from 'prop-types'
import { getTypeUser } from '../reducers/auth'

export const protectComponent = (WrappedComponent) => {
  const Validate = class extends React.Component {
    constructor(props) {
      super(props)
      this.state = {
        level: true,
      }
    }
    async componentDidMount() {
    }
    render() {
      
      const typeUser = this.props.typeUser
      if (typeUser==='regular' || !typeUser)  {
        return <Redirect to="/login" />
      }
      //
      return <WrappedComponent {...this.props} />
    }
  }
  const mapDispatchToProps = (dispatch) => ({
  })

  Validate.propTypes = {
    token: PropTypes.string,
  }
  return connect((state) => ({ typeUser: getTypeUser(state) }), mapDispatchToProps)(Validate)
}
