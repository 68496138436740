import React, { useEffect, useState } from "react";
import {
  initMercadoPago,
  createCardToken,
  CardNumber,
  SecurityCode,
  ExpirationDate,
  Wallet,
} from "@mercadopago/sdk-react";
import {
  CButtonGroup,
  CCol,
  CFooter,
  CFormInput,
  CFormLabel,
  CFormSelect,
  CInputGroup,
  CInputGroupText,
  CRow,
} from "@coreui/react";
import { Button } from "reactstrap";
import axios from "axios";
import userService from "../../service/user.service";
import mercadoPagoOrdersApi from "../../utils/mercadoPagoOrdersApi";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import Routes from "../../routes/Routes";
import Multiselect from "multiselect-react-dropdown";
import useToast from "../../utils/CustomHooks/useToast";
/**
 * valorDelDocumento = valorBase/(1-%)
 */
initMercadoPago(process.env.REACT_APP_MERCADOPAGO_KEY);
const indetificationTypes = [
  {
    value: "CC",
    label: "C.C.",
    type: "number",
    min_length: 5,
    max_length: 20,
  },
  {
    value: "CE",
    label: "C.E.",
    type: "number",
    min_length: 5,
    max_length: 20,
  },
  {
    value: "NIT",
    label: "NIT",
    type: "number",
    min_length: 5,
    max_length: 20,
  },
  {
    value: "Otro",
    label: "Otro",
    type: "number",
    min_length: 5,
    max_length: 20,
  },
];

const payment_methods = {
  credit: "credit",
  mercadoPago: "mercado_pago",
  pse: "pse",
};
const MercadoPago = ({
  paymentData,
  children,
  documentFirebaseId,
}) => {
  const addToast = useToast();
  const [preferenceID, setPreferenceID] = useState("");
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const [financialInstitutions, setFinancialInstitutions] = useState([]);
  const [financialInstitutionSelected, setFinancialInstitutionSelected] =
    useState([]);
  const [paymentMethod, setPaymentMethod] = useState(payment_methods.credit);
  const [dataForm, setDataForm] = useState({
    email: "",
    fullname: "",
    identification_number: "",
    identification_type: "CC",
  });
  useEffect(() => {
    const requestTipes = async () => {
      
      try {
        const { data } = await mercadoPagoOrdersApi.post("/preference", {
          title: paymentData.title,
          unit_price: paymentData.unit_price,
          order_id: documentFirebaseId,
        });
        /*
        let payment = {
          payment_platform: 1,
          items: [id],
        };
        const order = await userService.createPayment(payment);
        console.log(order)
        */
        const { data: preferenceData } = data;
        if (preferenceData.response.id) {
          
          setPreferenceID(preferenceData.response.id);
        }
      } catch (error) {
        return;
      }
    };
    if (paymentMethod === payment_methods.mercadoPago && !preferenceID) {
      requestTipes();
    }
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paymentMethod]);
  useEffect(() => {
    const script = document.createElement("script");

    script.src = "https://www.mercadopago.com/v2/security.js";
    script.view = "checkout";

    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
  }, []);
  useEffect(() => {
    const requestInstitutional = async () => {
      try {
        const { data } = await mercadoPagoOrdersApi.get("/paymentMethods");
        const { response } = data.data;
        const pseDta = response.find((res) => res.id === "pse");
        setFinancialInstitutions(pseDta.financial_institutions);
        
      } catch (error) {
        console.error(error);
      }
    };
    requestInstitutional();
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handlePayCardOrGeneralPaymentMethods = async () => {
    
    setLoading(true);
    try {
      let token = undefined;
      if (paymentMethod === payment_methods.credit) {
        token = await createCardToken({
          cardholderName: dataForm.fullname,
          identificationType: dataForm.identification_type,
          identificationNumber: dataForm.identification_number,
        });
        /*
        const paymentMethods = await getPaymentMethods({
          bin: token.first_six_digits,
        });
       const issuers = await getIssuers({
         paymentMethodId: paymentMethods.results[0].id,
         bin: token.first_six_digits,
       });
       */
        /*  
      if (paymentMethods.results.length <= 0) return;
       const installments = await getInstallments({
         amount: '10000',
         locale: "es-CO",
         bin: token.first_six_digits,
         processing_mode: paymentMethods.results[0].processing_mode,
       });
       */
      }
      const { data } = await axios.get("https://api.ipify.org/?format=json");

      const dataToSend = {
        email: dataForm.email,
        token: token?.id || undefined,
        transaction_amount: paymentData.unit_price,
        payment_method: !token ? paymentMethod : undefined,
        installments: 1,
        identification_number: dataForm.identification_number,
        identification_type: dataForm.identification_type,
        ip_address: data.ip,
        order_id: documentFirebaseId,
        financial_institution: financialInstitutionSelected?.[0]?.id,
      };
      const responseOrder = await userService.postPurchaseCreate(dataToSend);

      //TODO: redireccionar cuando se confirme

      /*

            ?collection_id=1313658354
            &collection_status=approved
            &payment_id=1313658354
            &status=approved
            &external_reference=null
            &payment_type=credit_card
            &merchant_order_id=10146461415
            &preference_id=213744223-4afa230b-97f6-4abd-bfaa-50e61988e89d&site_id=MCO
            &processing_mode=aggregator
            &merchant_account_id=null
            */
      
      const { data: dataResponse } = responseOrder;
      const { response: payment } = dataResponse.data;
      
      if (payment.transaction_details.external_resource_url) {
        var downloadLink = document.createElement("a");
        downloadLink.href = payment.transaction_details.external_resource_url;
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
        downloadLink.remove();
      } else {
        const query = `?collection_id=${payment.id}&collection_status=${payment.status}&payment_id=${payment.id}&status=${payment.status}&external_reference=null&payment_type=${payment.payment_type_id}`; //&merchant_order_id=${}&preference_id=${}&processing_mode=${}&merchant_account_id=${}`
        history.push(`${Routes.ValidatePayment(documentFirebaseId)}${query}`);
      }
    } catch (error) {
      setLoading(false);
      let msg = error.response.data.error.detail;
      if (msg === "Cannot infer Payment Method")
        msg = "No se pudo procesar el pago, verifique los datos";
      if (msg === "payer.email must be a valid email") msg = "Correo no valido";

      addToast({
        title: "Error",
        body: msg,
      });
    }
    setLoading(false);
  };

  const handlePayMercadoPago = () => {};

  const handleChange = (e) => {
    let { value, name } = e.target;
    setDataForm((oldDataForm) => {
      return { ...oldDataForm, [name]: value };
    });
  };
  return (
    <>
      <CRow>
        {children}
        <CCol>
          <CButtonGroup className="w-100">
            <Button
              className="primary_custom_btn px-3"
              onClick={(e) => {
                setPaymentMethod(payment_methods.credit);
              }}
            >
              Tarjeta
            </Button>
            <Button
              className="secondary_custom_btn"
              onClick={(e) => {
                setPaymentMethod(payment_methods.mercadoPago);
              }}
            >
              MercadoPago
            </Button>
            <Button
              className="primary_custom_btn"
              onClick={(e) => {
                setPaymentMethod(payment_methods.pse);
              }}
            >
              PSE
            </Button>
          </CButtonGroup>
        </CCol>

        {paymentMethod !== payment_methods.mercadoPago && (
          <>
            <CCol xs="12">
              <CFormInput
                type="text"
                name="email"
                id="titularEmail"
                label="Correo del Titular"
                aria-describedby="titularEmail"
                value={dataForm.email}
                onChange={handleChange}
              />
            </CCol>
            <CCol xs="12">
              <CFormInput
                type="text"
                name="fullname"
                id="titularName"
                label="Nombre del Titular"
                text="Must be 8-20 characters long."
                aria-describedby="titularName"
                value={dataForm.fullname}
                onChange={handleChange}
              />
            </CCol>
            <CCol xs="12">
              <CInputGroup className="mb-3">
                <CFormSelect
                  name="identification_type"
                  value={dataForm.identification_type}
                  onChange={handleChange}
                  options={indetificationTypes}
                />
                <CFormInput
                  name="identification_number"
                  value={dataForm.identification_number}
                  onChange={handleChange}
                  style={{ background: "transparent", flexGrow: 4 }}
                  placeholder="Numero de Identificación"
                  aria-label="Username"
                />
              </CInputGroup>
            </CCol>
          </>
        )}

        {paymentMethod === payment_methods.credit ? (
          <>
            <CCol
              xs="12"
              style={{ height: "3em" }}
              className="payment-mercadopago-input mb-3"
            >
              <label>Numero de tarjeta</label>
              <CardNumber placeholder="Card Number" />
            </CCol>
            <CCol
              style={{ height: "3em" }}
              className="payment-mercadopago-input"
            >
              <label>Código de Seguridad</label>
              <SecurityCode placeholder="Security Code" />
            </CCol>
            <CCol
              style={{ height: "3em" }}
              className="payment-mercadopago-input mb-3"
            >
              <label>Vencimiento</label>
              <ExpirationDate placeholder="MM/YY" mode="short" />
            </CCol>
          </>
        ) : (
          <></>
        )}
        {paymentMethod === payment_methods.pse ? (
          <>
            <CCol
              xs="12"
              style={{ height: "3em" }}
              className="modal_create_document mb-3"
            >
              <CFormLabel className="text-capitalize">
                Institución financiera
              </CFormLabel>
              <CInputGroup className="input-group-multiselect ">
                <CInputGroupText></CInputGroupText>
                <Multiselect
                  selectionLimit={1}
                  className="w-100"
                  emptyRecordMsg="No hay categorías Disponibles"
                  options={financialInstitutions
                    .filter((it) => Boolean(it))
                    .map((category) => {
                      return {
                        name: category.description,
                        id: category.id,
                      };
                    })} // Options to display in the dropdown
                  selectedValues={financialInstitutionSelected} // Preselected value to persist in dropdown
                  onSelect={(e) => {
                    const optionListCont = document.querySelector(
                      ".optionListContainer.displayBlock"
                    );

                    optionListCont.className =
                      "optionListContainer displayNone";
                    document.body.click();
                    document.body.focus();
                    setFinancialInstitutionSelected(e);
                  }} // Function will trigger on select event
                  onRemove={setFinancialInstitutionSelected} // Function will trigger on remove event
                  displayValue="name" // Property name to display in the dropdown options
                />
              </CInputGroup>
            </CCol>
          </>
        ) : (
          <></>
        )}
      </CRow>

      <CFooter className="justify-content-center">
        {paymentMethod === payment_methods.mercadoPago ? (
          <>
            <Wallet
              onSubmit={handlePayMercadoPago}
              initialization={{
                preferenceId: preferenceID,
              }}
            />
          </>
        ) : (
          <Button
            disabled={loading}
            className="primary_custom_btn w-100"
            onClick={(e) => handlePayCardOrGeneralPaymentMethods(e)}
          >
            Pagar
          </Button>
        )}
      </CFooter>
    </>
  );
};

export default MercadoPago;
