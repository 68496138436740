import React, { useEffect, useState } from "react";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import ReactHTMLTableToExcel from "react-html-table-to-excel";

import UserService from "../service/user.service";
// import { useDispatch } from "react-redux";
// import { logout } from "../actions/auth";

import "../assets/styles/components/TableUser.scss";
import moment from "moment/moment";

const PurchaseHistories = () => {
  const [, setLoader] = useState(true);
  const [state, setState] = useState([]);
  // const [transfor, setTransform] = useState(false);
  // const [infoCatg, setCatg] = useState({
  //   name: "",
  //   description: "",
  //   icon_path: "",
  // });
  const [dateStart, setDateStart] = useState(
    moment().subtract(1, "month").format("YYYY-MM-DD")
  );
  const [dateEnd, setDateEnd] = useState(moment().format("YYYY-MM-DD"));
  // const [showEdiMod, setshowEdiMod] = useState(false);
  // const [showDelMod, setshowDelMod] = useState(false);
  // const [showNewMod, setshowNewMod] = useState(false);
  const [refreshData] = useState(false);
  // const [showAlertMod, setshowAlertMod] = useState(false);
  // const [modalData, setModalData] = useState({
  //   title: "",
  //   body: "",
  //   border: "",
  // });

  // const dispatch = useDispatch();
  // const ModEdiCateSate = () => setshowEdiMod(!showEdiMod);
  // const ModDelCateSate = () => setshowDelMod(!showDelMod);
  // const ModNewCateSate = () => setshowNewMod(!showNewMod);
  // const ModAlertMod = () => setshowAlertMod(!showAlertMod);

  // const logOut = () => {
  //   dispatch(logout());
  //   setClose(true);
  // };

  // const handleClickClose = () => {
  //   setTransform(false);
  //   //setClass({ butn: "btn-shadow btn btn-dark" });
  // };

  useEffect(() => {
    setLoader(true);
    getPurchaseWithDate();
  }, [refreshData]);

  // const handleClickSearch = () => {
  //   setTransform(true);
  //   setClick("btn-shadow btn btn-dark btn-border");
  // };
  /*Buscando categoria */
  //   function searchPurchaseByDate(valueFind, stateData) {
  //     let element = [];
  //     const valueMatches = (value, searchKey) =>
  //       typeof value === "string" &&
  //       value.toUpperCase().includes(searchKey.toUpperCase());

  //     element = stateData.filter((obj) =>
  //       Object.keys(obj).some((key) => valueMatches(obj[key], valueFind))
  //     );
  //     return element;
  //   }

  //   const useSearchDocuemnt = (event, dataState) => {
  //     const value = searchDocument(event.target.value, dataState);

  //     if (event.target.value === "") {
  //       setData([]);
  //     } else if (value.length !== 0) {
  //       setData(value);
  //     }
  //   };

  const onChangeDate = (date, funSetState) => {
    funSetState(date.target.value);
    // funSetState(date.target.value);
  };

  const getPurchaseWithDate = async () => {
    if (dateStart !== null && dateEnd !== null) {
      setLoader(true);
      
      try {
        const userHistory = await UserService.getPurchaseHistory(
          dateStart,
          dateEnd
        );

        if (userHistory.status === 200) {
          setState(userHistory.data.data);
        } else {
          setState([]);
        }
      } catch (error) {
        setState([]);
        
        console.error(error);
      }
      setLoader(false);
    }
  };

  return (
    <div className="show_detail">
      <div className="table-responsive">
        <div className="header__field__purchase">
          <div className="image__list">
            <i className="fas fa-angle-double-up"></i>
          </div>
          <div className="text__information">
            <h4>Historial de compras</h4>
          </div>
        </div>

        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
        >
          <form className="pl-3" noValidate>
            <TextField
              id="dateStart"
              onChange={(date) => onChangeDate(date, setDateStart)}
              value={dateStart}
              label="Fecha de inicio"
              type="date"
              className="pr-5 pb-3"
              InputLabelProps={{
                shrink: true,
              }}
            />
            <TextField
              id="dateEnd"
              onChange={(date) => onChangeDate(date, setDateEnd)}
              label="Fecha de fin"
              value={dateEnd}
              type="date"
              className="pr-5 pb-3"
              InputLabelProps={{
                shrink: true,
              }}
            />
          </form>
          <button
            className={"btn-shadow btn btn-dark"}
            onClick={() => getPurchaseWithDate()}
          >
            <i className="fas fa-search"></i>
          </button>
          <ReactHTMLTableToExcel
            id="test-table-xls-button"
            className="btn-success p-2"
            table="tablePurchaseBuy"
            filename="historialdecompras"
            sheet="tablexls"
            buttonText="Generar excel"
          />
        </Grid>

        <table
          className="table table-light table-striped"
          id="tablePurchaseBuy"
        >
          <thead id="dataTableHead">
            <tr className="head-table">
              <th scope="col">id</th>
              <th scope="col">Fecha de compra</th>
              <th scope="col">Documento</th>
              <th scope="col">Correo</th>
              <th scope="col">Precio</th>
              <th scope="col">Estado</th>
              <th scope="col">Tipo de documento</th>
              <th scope="col">User id</th>

              <th className="d-none" scope="col">
                Nombre de usuario
              </th>
              <th className="d-none" scope="col">
                Activo
              </th>
              <th className="d-none" scope="col">
                Fecha de nacimiento
              </th>
              <th className="d-none" scope="col">
                Genero
              </th>
              <th className="d-none" scope="col">
                Teléfono principal
              </th>
              <th className="d-none" scope="col">
                Teléfono secundario
              </th>
              <th className="d-none" scope="col">
                Tipo de identificación
              </th>
              <th className="d-none" scope="col">
                Numero de identificación
              </th>
            </tr>
          </thead>
          <tbody className="">
            {state ? (
              state.length > 0 ? (
                state.map((data, i) => {
                  return (
                    <tr key={i}>
                      <td>{data.id}</td>
                      <td>{data.created_at}</td>
                      <td>{data.name}</td>
                      <td>{data.email}</td>
                      <td>{data.value}</td>
                      <td>{data.state}</td>
                      <td>{data.document_type}</td>
                      <td>{data?.user_regular_id}</td>

                      <td className="d-none">
                        {data?.user.name}
                      </td>
                      <td className="d-none">
                        {data?.user.active ?'Activo':'Inactivo'}
                      </td>
                      <td className="d-none">
                        {data?.user?.person?.birth}
                      </td>
                      <td className="d-none">
                        {data?.user?.person?.genre}
                      </td>
                      <td className="d-none">
                      {data?.user?.person?.phone} 
                      </td>
                      <td className="d-none">
                      {data?.user?.person?.secondary_phone|| '-'} 
                      </td>
                      <td className="d-none">
                      {data?.user?.person?.type_id} 
                      </td>
                      <td className="d-none">
                      {data?.user?.person?.cedula} 
                      </td>
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td>No hay documentos diligenciados aun</td>
                </tr>
              )
            ) : (
              <div className="loader-page"></div>
            )}
          </tbody>
        </table>
        {/* <CPagination
          align="center"
          addListClass="some-class"
          activePage={currentPage}
          pages={totalPage}
          onActivePageChange={handleChangesPage}
        /> */}
        <br></br>
      </div>
    </div>
  );
};

export default PurchaseHistories;
