import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { logout } from "../actions/auth";
import CreateDocument from "../components/modals/CreateDocument";
import "../assets/styles/components/Modal.scss";
import "../assets/styles/components/TopBar.scss";
import DocumentEdit from "./DocumentEdit"; 
// import MercadoPago from "./modals/MercadoPago";
import { Button } from "reactstrap";
import ListDocument from "./modals/ListDocument";
import UserService from "../service/user.service";
import { Redirect } from "react-router-dom";
import AlertDialogSlide from "./modals/AlertDialogSlide";

const ModalVertical = (props) => {
  const {
    rawDoc,
    data,
    setData,
    modalPayment: modalShow,
    setModalShow,
    setModalPayment: setModalShowAtchamen,
    title,
    dataPdf,
    setDataPdf,
    inputs,
    topRight,
    fieldsTop,
    setFiles,

    doc,
    dataPerson,
  } = props;

  const [stateVars, setStateVars] = useState();
  const [stateList, setStateList] = useState({});
  // const [modalShow, setModalShowAtchamen] = useState(false);
  const [modalShowList, setModalShowList] = useState(false);
  const [searchData, setSearchData] = useState({});
  // const [searchedData, setSearchedData] = useState();

  const [showAlertMod, setshowAlertMod] = useState(false);
  const [modalData, setModalData] = useState({
    title: "",
    body: "",
    border: "",
  });
  const ModAlertMod = () => setshowAlertMod(!showAlertMod);

  // const [topRight, setTopRight] = useState(false);
  // const [fieldsTop, setFieldsTop] = useState([]);
  // const [vars, setVars] = useState({});
  const [dataList, setDataList] = useState({});
  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState(false);
  const [stateData, setStateData] = useState({});
  const [nameList, setNameList] = useState("");
  const [, setDocumentPagos] = useState("");
  const [inputsDynamics, setInputsDynamics] = useState([]);

  const [listId, setListId] = useState();

  const [close, setClose] = useState(false);
  const logOut = () => {
    dispatch(logout());
    setClose(true);
  };

  useEffect(() => {
    if (alert === true) {
      setTimeout(() => {
        setAlert(false);
      }, 3000);
    }
  }, [alert]);

  const handleChange = (e, document) => {
    e.preventDefault();
    const newStateVars = {
      ...stateVars,
      [e.target.name]: e.target.value,
    }
    setStateVars(newStateVars);

    updateDocument({inputs:newStateVars})
  };

  const getLengthOfObject = (obj) => {
    return Object.keys(obj).length;
    // let length0fObject = 0;

    // for (let key in obj) {
    //   length0fObject++;
    // }
    // return length0fObject;
  };

  const OpenModalList = (e, list_dta, id_name) => {
    e.preventDefault();
    setListId(list_dta);
    setDataList({});

    UserService.getListId(list_dta.value).then(
      (res) => {
        setDataList(
          res.data.message !== null
            ? res.data.message
            : {
                description: "No hay elementos en esta lista",
                name: "",
              }
        );

        setSearchData(res.data.message);
      },
      (error) => {
        const _content =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        if (_content === "Unauthenticated.") {
          dispatch(logOut());
        }
      }
    );
    setNameList(id_name);
    setModalShowList(true);
  };
  const dispatch = useDispatch();

  const OpenSubmit = (e, vars2, list2, data) => {
    setLoading(true);
    e.preventDefault();
    
    // if (getLengthOfObject(vars2) > 0 || getLengthOfObject(list2) > 0) {
    if (
      getLengthOfObject(vars2) === fieldsTop.length &&
      inputs.length === Object.keys(stateList).length
      ) {
      
      const data_to_sent = {
        ...data,
        vars: vars2,
        lists: list2,
        // pagado: false,
        // template_id
      };
      let listString = [];
      for (const key in list2) {
        listString.push(list2[key]);
      }
      
      setDataPdf({
        ...dataPdf,
        vars2: vars2,
        list2: listString.join(),
        id: data["id"],
        document: data["document"],
      });
      setModalShowAtchamen(true);
      /*
      dispatch(createDocumentsUser(data_to_sent))
        .then((response) => {
          
          setModalData({
            title: "Felicidades",
            body: "Se ha creado correcantamente el documento ya puedes !Descargar¡",
            border: "bg-success",
          });

          localStorage.setItem("doc_id", JSON.stringify(response.data.data));
          localStorage.setItem(
            "dataPdf",
            JSON.stringify({
              ...dataPdf,
              vars2: vars2,
              list2: listString.join(),
              id: data["id"],
              document: data["document"],
            })
          );
          setStateData(response.data.data);
        })
        .catch((error) => {
          console.error( error );
          
          setModalData({
            title: "Lo sentimos",
            body: "No se realizo la creación del documento\n",
            border: "bg-danger",
          });

          ModAlertMod();
        });
        */
    } else {
      setLoading(false);
      setAlert(true);
    }
    setLoading(false);
  };

  const OpenModalPagos = (e, doc) => {
    e.preventDefault();
    setDocumentPagos(doc);
  };

  const _listVariable = fieldsTop.map((item, i) => {
    let type_data = null;
    let msg = item.msg.replace(/['"]+/g, "");
    msg = msg.replaceAll("_", " ");
    if (item.type_data === "String") type_data = "text";
    else if (item.type_data === "Integer") type_data = "number";
    else if (item.type_data === "Date") type_data = "date";
    else if (item.type_data === "TextArea") type_data = "textarea";
    let properties = {
      className: "input_vars",
      type: type_data,
      name: msg,
      placeholder: "Escribe aqui",
      key: i,
      onChange: (e) => handleChange(e, data.document),
    };
    return (
      <div className="text-left" key={i}>
        <p>{msg}</p>{" "}
        {type_data === "textarea" ? (
          <textarea
            rows={5}
            cols={5}
            {...properties}
            style={{ resize: "none" }}
          />
        ) : (
          <input {...properties} />
        )}
      </div>
    );
  });

  useEffect(() => { 
    let _listOrder = fieldsTop;
    fieldsTop.map((item, i) => {
      fieldsTop.map((item2, l) => {
        if (l + 1 >= fieldsTop.length) {
        } else {
          let msg = _listOrder[l].msg.replace(/['"]+/g, "");
          let msg2 = _listOrder[l + 1].msg.replace(/['"]+/g, "");

          if (data.document.indexOf(msg) > data.document.indexOf(msg2)) {
            let aux1 = _listOrder[l + 1];

            _listOrder[l + 1] = _listOrder[l];
            _listOrder[l] = aux1;
          }
        }
      });
    });
  }, [fieldsTop]);

  const updateDocument=({
    lists = stateList,
    inputs = stateVars
  })=>{
    
    let _arrayObject = {}
  Object.values(lists).forEach((input=>{
    let elemento= []
    Object.keys(input).forEach(key=>{
      if(key!=='name') {
        elemento.push(input[key])
      }
    })
    _arrayObject[input.name] = elemento
  }))
    let _array = rawDoc.split("~");
    var _data = data;
    let introduceList = _array.map((variable)=>{
      if(_arrayObject?.[variable]) {
        return `~${_arrayObject[variable].reduce((prevE,currentE)=>{
          prevE += `, ${currentE}`;
          return prevE
        })}~`
      } else {
        return variable
      }
    })
    introduceList = introduceList.join('~')
    // eslint-disable-next-line no-useless-escape
    introduceList = introduceList.replace(/\~\~/g, "")

    _array = introduceList.split("|")
    let finalDocument = _array.map((variable)=>{
      if(inputs?.[variable.replace(/_/g, " ")]) {
        return `|${inputs[variable.replace(/_/g, " ")] }|`
      } else {
        return variable 
      }
    })
    finalDocument = finalDocument.join('|')
    finalDocument = finalDocument.replace(/\|\|/g, "")
    _data.document = finalDocument;

    setData(_data);
  }

  const handleEditList = (value)=>{
    const prevStateList = {...stateList}
    prevStateList[value.name] = value;
    setStateList(prevStateList)
    updateDocument({lists:prevStateList})
  }
  return (
    <>
      {close && <Redirect to="/login" />}
      <div className="modal__container">
        <h1>{title}</h1>
        <div className={topRight ? "modal__row" : ""}>
          {topRight && (
            <div className="rigth__bar">
              <div className="contains__bar">
                <div className="title__select">
                  <h6>
                    {" "}
                    <b>{data.name}</b>
                  </h6>
                  <hr></hr>
                </div>
                <form>
                  <div className="body__select">
                    <article className="body__data">{_listVariable}</article>
                  </div>
                  <div className="select_list_doc">
                    {inputs.length > 0
                      ? inputs.map((list, i) => {
                          return (
                            <button
                              color="success"
                              onClick={(e) => {
                                OpenModalList(e, list.list, list.name);
                              }}
                              key={i}
                            >
                              Selecciona uno o varios elementos en{" "}
                              {list.name.replaceAll("_", " ")}
                            </button>
                          );
                        })
                      : ""}
                  </div>
                  {alert ? (
                    <div className="select_list_doc">
                      <Button color="danger" disabled>
                        Campos o Listas sin seleccionar
                      </Button>
                    </div>
                  ) : (
                    <div className="button_major">
                      <div className="button_select">
                        {loading ? (
                          <span className="spinner-border spinner-border-sm"></span>
                        ) : (
                          <>
                            <button
                              onClick={(e) =>
                                OpenSubmit(e, stateVars, stateList, data)
                              }
                            >
                              Enviar
                            </button>
                            <button type="reset">Limpiar</button>
                          </>
                        )}
                      </div>
                    </div>
                  )}
                </form>
              </div>
            </div>
          )}
          <div className="title__peticion">
            <div className="document__content">
              <DocumentEdit
                // setTopRight={setTopRight}
                // setFieldsTop={setFieldsTop}
                document={data}
                fieldsTop={fieldsTop}
                inputs={inputs}
              />
            </div>
            <button className="btn__close" onClick={() => setModalShow(false)}>
              X
            </button>
          </div>
        </div>
      </div>
      <CreateDocument
        show={modalShow}

        onHide={() => {
          setLoading(false);
          setModalShowAtchamen(false);
        }}
        setFiles={setFiles}
        
        id_document={stateData}
        // openmodal={(e) => OpenModalPagos(e, data)}
        userDoc={{
          ...dataPdf,
          vars2: stateVars,
          list2: Object.values(stateList),
          id: data["template_id"],
          document: data["document"],
        }}
        doc={doc}
        dataPerson={dataPerson}
      />

      {modalShowList && <ListDocument
        show={modalShowList}
        onHide={() => setModalShowList(false)}
        dataList={dataList}
        setDataList={setDataList}
        stateList={stateList}
        setStateList={handleEditList}
        data={data}
        setData={setData}
        nameList={nameList}
        inputsDynamics={inputsDynamics}
        setInputsDynamics={setInputsDynamics}
        searchData={searchData}
        listId={listId}
        // listItems={dataList.items}
      />}
      <AlertDialogSlide
        modaldata={modalData}
        show={showAlertMod}
        onHide={() => ModAlertMod()}
      />
    </>
  );
};

export default ModalVertical;
