import React, { useEffect, useState } from "react";
import { Redirect, useParams } from "react-router-dom";

import Panel from "../components/Panel";
import UserService from "../service/user.service";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../actions/auth";
import AlertDialogSlide from "../components/modals/AlertDialogSlide";
import { getTokenUser } from "../reducers/auth";

const PanelControl = (props) => {
  const userToken = useSelector(getTokenUser)
  const {id} = useParams()
  const [listCategories, setListCategories] = useState([]);
  const [loader, setLoader] = useState(true);
  const [showAlertMod, setshowAlertMod] = useState(false);
  const [close, setClose] = useState(false);
  const [modalData, setModalData] = useState({
    title: "",
    body: "",
    border: "",
  });

  const ModAlertMod = () => setshowAlertMod(!showAlertMod);

  const dispatch = useDispatch();

  const logOut = () => {
    dispatch(logout());
    setClose(true);
  };

  useEffect(() => {
    setLoader(true);
    UserService.getCetegorias("pagination", 1000)
      .then((response) => {
        setListCategories(response.data.data);
        setLoader(false);
      })
      .catch((error) => {
        console.error(error.response);
        if (error.response.data.message === "Unauthenticated.") {
          dispatch(logOut());
        }
      });
  }, []);

  return userToken ? (
    <>
      {close && <Redirect to="/login" />}
      <Panel
        categoryId={id}
        documents={listCategories}
        loader={loader}
        modalData={modalData}
        setModalData={setModalData}
        ModAlertMod={ModAlertMod}
      />
      <AlertDialogSlide
        modaldata={modalData}
        show={showAlertMod}
        onHide={() => ModAlertMod()}
      />
    </>
  ) : (
    <Redirect to="/login" />
  );
};

export default PanelControl;
