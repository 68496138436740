import {
  CButton,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
} from "@coreui/react";
import React from "react";

function AlertUser({
  show = undefined,
  title='Alerta',
  content='El gobierno miente',
  setShow = () => {},
  onConfirm=()=>{},
  onCancel=()=>{},

}) {
  const HandleClose = async (e) => {
    e.preventDefault();
    onConfirm()
    setShow(false);
  };
  const HandleCancel = async (e) => {
    e.preventDefault();
    onCancel()
    setShow(false);
  };

  return (
    <>
      <CModal
        size="md"
        fullscreen="sm"
        className="excel-users-modal"
        visible={Boolean(show)}
        onClose={() => {}}
      >
        <CModalHeader onClose={HandleClose}>
          <h3>{title}</h3>
        </CModalHeader>
        <CModalBody>
          {content}
        </CModalBody>
        <CModalFooter>
          <CButton
            className="me-2"
            color="secondary"
            onClick={HandleCancel}
          >
            Cerrar
          </CButton>
          <CButton onClick={HandleClose} color="primary">
            Continuar
          </CButton>
        </CModalFooter>
      </CModal>
    </>
  );
}

export default AlertUser;
