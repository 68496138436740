import { CButton, CRow } from "@coreui/react";
import React, { useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { ReactComponent as HandSVG } from "../../assets/svg/hand.svg";
import { ReactComponent as PlusSVG } from "../../assets/svg/plus.svg";

function DraggableVariables({
  children,
  pagesVars = [],
  type = "variables",
  maxPages = 0,
  noItemsMsg = "Sin datos",
  droppableId = "dropableIDTest",
  onDragEnd = () => {},
  onEditPageMetaDta = () => {},
  onRemovePage,
  onNewPage,
  invalid
}) {
  const [editPageName, setEditPageName] = useState(-1);
  const [editPageDescription, setEditPageDescription] = useState(-1);
  const [temporalTitleName, setTemporalTitleName] = useState("");
  const [temporalPageDescription, setTemporalPageDescription] = useState("");

  const handleDragEnd = (e) => {
    debugger
    const [varId, index_var, index_old_page] = e.draggableId.split("|");
    const [index_target_page] = e.destination.droppableId.split("|");

    const oldPage = { ...pagesVars[index_old_page] };
    const TargetPage = { ...pagesVars[index_target_page] };
    const oldVarsOrLists = [...oldPage[type]];
    let TargetVarsOrLists = [...TargetPage[type]];
    const [spliced] = oldVarsOrLists.splice(index_var, 1);
    const toSend = {};
    if (index_old_page === index_target_page) {
      TargetVarsOrLists = [...oldVarsOrLists];
    } else {
      oldPage[type] = oldVarsOrLists;
      toSend.old_page = {
        index: index_old_page,
        data: oldPage,
      };
    }
    TargetVarsOrLists.splice(e.destination.index, 0, spliced);
    TargetPage[type] = TargetVarsOrLists;
    toSend.new_page = {
      index: index_target_page,
      data: TargetPage,
    };

    onDragEnd(toSend);
  };
  const handleSavePageTitle = (index_page) => {
    const TargetPage = { ...pagesVars[index_page] };
    TargetPage.title = temporalTitleName;
    onEditPageMetaDta({
      index: index_page,
      data: TargetPage,
    });
    setEditPageName(-1);
  };
  const handleSavePageDescription = (index_page) => {
    const TargetPage = { ...pagesVars[index_page] };
    TargetPage.description = temporalPageDescription;

    onEditPageMetaDta({
      index: index_page,
      data: TargetPage,
    });
    setEditPageDescription(-1);
  };
  return (
    <DragDropContext onDragEnd={handleDragEnd}>
      {pagesVars.map((dataPage, indexPage) => {
        const data = dataPage[type] || [];
        return (
          <React.Fragment key={`fragment_DRAGGABLE_${indexPage}`}>
            <div className="droppable_page_title mt-2">
              {indexPage === editPageName ? (
                <input
                  value={temporalTitleName}
                  onChange={(e) => {
                    setTemporalTitleName(e.target.value);
                  }}
                  placeholder={dataPage?.title}
                ></input>
              ) : (
                <h5 className={(invalid && !dataPage.title)?'invalid':''}>
                  {dataPage.title ? dataPage.title : `página ${indexPage + 1}`}
                </h5>
              )}
              <button
                type="button"
                className="edit_title_page_name_btn"
                onClick={() => {
                  if (indexPage !== editPageName) {
                    setTemporalTitleName(dataPage.title);
                    setEditPageName(indexPage);
                  } else {
                    handleSavePageTitle(indexPage);
                  }
                }}
              >
                <span>
                  <HandSVG />
                </span>
                {indexPage === editPageName ? "Guardar" : "Editar nombre"}
              </button>
            </div>

            <div className="droppable_page_description mt-2">
              <div className="d-flex justify-content-end">
                <button
                  type="button"
                  className="edit_title_page_name_btn"
                  onClick={() => {
                    if (indexPage !== editPageDescription) {
                      setTemporalPageDescription(dataPage.description);
                      setEditPageDescription(indexPage);
                    } else {
                      handleSavePageDescription(indexPage);
                    }
                  }}
                >
                  <span>
                    <HandSVG />
                  </span>
                  {indexPage === editPageDescription
                    ? "Guardar"
                    : "Editar descripción"}
                </button>
              </div>
              {indexPage === editPageDescription ? (
                <textarea
                  value={temporalPageDescription}
                  onChange={(e) => {
                    setTemporalPageDescription(e.target.value);
                  }}
                  placeholder={dataPage?.description}
                ></textarea>
              ) : (
                <p>
                  {dataPage.description ? dataPage.description : `Descripción`}
                </p>
              )}
            </div>
            <CRow></CRow>
            <Droppable droppableId={`${indexPage}|${droppableId}`}>
              {(provided, snapshot) => {
                return (
                  <div
                    className={`droppable_variables_list ${
                      snapshot?.isDraggingOver ? "dragging_over" : ""
                    }`}
                    style={{ minHeight: `${data.length * 63}px` }}
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                  >
                    {data.map((variable, index) => {
                      const documentVariable = (typeof variable === 'string') ? variable : variable.name
                      return (
                        <Draggable
                          key={`${documentVariable}_draggable_${droppableId}`}
                          draggableId={`${documentVariable}|${index}|${indexPage}`}
                          index={index}
                        >
                          {(provided, snapshot) => {
                            return (
                              <>
                                <div
                                  key={`test-${index}`}
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                >
                                  {children(variable, index)}
                                  {provided.placeholder}
                                </div>
                              </>
                            );
                          }}
                        </Draggable>
                      );
                    })}
                    {data.length === 0 ? (
                      <>
                        {dataPage["vars"].length === 0 &&
                        dataPage["lists"].length === 0 ? (
                          <CButton
                            type="button"
                            className="add_page_btn d-inline-block"
                            onClick={()=>{onRemovePage(indexPage)}}
                          >
                            Remover
                          </CButton>
                        ) : (
                          <></>
                        )}
                        <p style={{ color: "#93378a" }}>{noItemsMsg}</p>
                      </>
                    ) : (
                      <></>
                    )}
                  </div>
                );
              }}
            </Droppable>
          </React.Fragment>
        );
      })}
      {onNewPage && pagesVars.length < maxPages ? (
        <CButton type="button" className="add_page_btn" onClick={onNewPage}>
          <PlusSVG style={{ height: "18px" }} /> Añadir
        </CButton>
      ) : (
        ""
      )}
    </DragDropContext>
  );
}

export default DraggableVariables;
