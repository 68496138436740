import React, { useEffect, useRef, useState } from "react";
import { Button, FormGroup, Label } from "reactstrap";
import { Modal } from "react-bootstrap";
import { Form } from "react-bootstrap";

import { useDispatch } from "react-redux";
import { logout } from "../../actions/auth";
import { Redirect } from "react-router-dom";
import UserService from "../../service/user.service";
import Multiselect from "multiselect-react-dropdown";
import { CFormLabel, CInputGroup, CInputGroupText } from "@coreui/react";
import useArray from "../../utils/CustomHooks/useArray";
import tagService from "../../service/tag.service";

const EditUser = (props) => {
  const {
    title,
    showEdiMod,
    setshowEdiMod,
    user,
    isView,
    isAdmin,
    setrefreshData,
    refreshData,
    ModAlertMod,
    setModalData,
  } = props;
  const ModEdiCateSate = () => setshowEdiMod(!showEdiMod);
  const [data, setData] = useState({
    name: "",
    email: "",
    active: false,
  });
  const [close, setClose] = useState(false);
  const { 
    array: tags_array, 
    set: tags_set, 
    push: tags_push, 
    //filter :  tags_filter, 
    //update :  tags_update, 
    //remove :  tags_remove,  
    //clear :  tags_clear  
  } = useArray([{name: 'Option 1️⃣', id: 1},{name: 'nueba opcion', id: 2}]) 

  const { 
    array: selected_tags_array, 
    set: selected_tags_set, 
    push: selected_tags_push, 
    //filter :  selected_tags_filter, 
    //update :  selected_tags_update, 
    //remove :  selected_tags_remove,  
    //clear :  selected_tags_clear  
  } = useArray([]) 
  
  
  const [couldCrateTag, setCouldCrateTag] = useState(false)
  const tagSearch = useRef('')
  const [rolesSelect, setRoles] = useState("administrator");
  const multiselectRef = useRef();

  const rolesData = JSON.stringify(user.roles);
  const dispatch = useDispatch();

  const logOut = () => {
    dispatch(logout());
    setClose(true);
  };

  useEffect(() => {

    if (user) {
      
      setData({
        name: user.name,
        email: user.email,
        active: `${user.active}`,
      });
      selected_tags_set(user.tags ?? [])
    } else {
      setData({ name: "", email: "", active: false });
      selected_tags_set( [])
    }

    return () => {};
  }, [user]);

  useEffect(() => {
    tagService.getTags().then((tags)=>{
      tags_set(tags.data)
    })
  
    return () => {
      tagSearch.current = ''
    }
  }, [])

  useEffect(() => {
  
    return () => {
      tagSearch.current = ''
      selected_tags_set([])
    }
  }, [showEdiMod])
  

  const handleChange = (e) => {
    const { name, value = "" } = e.target;

    setData((prev) => {
      const newDta = { ...prev, [name]: value };
      return newDta;
    });
  };
  const handleChangeRole = (e) => {
    setRoles(e.target.value);
  };

  const handleChangeMultiSelect = (e) =>{
    tagSearch.current = e
    setCouldCrateTag(tags_array.findIndex(({name}) => name.match(RegExp(`(${e})`,'i'))) < 0)
  }

  const handleCreateTag = (e) =>{
    
    tagService.createTag(tagSearch.current).then((tags)=>{
      tags_push(tags.data)
      setCouldCrateTag(tags_array.findIndex(({name}) => name.match(RegExp(`(${e})`,'i'))) < 0)
      selected_tags_push(tags.data)
    })
  }

  const handleSubmit = (id, select) => {
    const datatoSend = { ...data,
      roles: [select],
      tags : selected_tags_array
    };
    
    let service = UserService.rolesUser
    if(!isAdmin) service = UserService.updateUser

    service(id, datatoSend).then(
      (response) => {
        setModalData({
          title: "Muy bien",
          body: response.data.message,
          border: "bg-success",
        });

        ModEdiCateSate();
        setrefreshData(!refreshData);
        ModAlertMod();
      },
      (error) => {
        const _content =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        if (_content === "Unauthenticated") {
          logOut();
        } else {
          setModalData({
            title: "Lo sentimos",
            body: "No se pudo editar el usuario.\n" + error.data,
            border: "bg-danger",
          });
          ModEdiCateSate();
          ModAlertMod();
        }
      }
    );
  };
  return (
    <Modal show={showEdiMod} id="ModalEdit" onHide={ModEdiCateSate}>
      {close && <Redirect to="/login/admin" />}
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Form role="form">
        <Modal.Body>
          <FormGroup className="fields__new">
            <Label>Nombre completo</Label>
            <Form.Control
              type="text"
              name="name"
              onChange={handleChange}
              value={data.name}
            ></Form.Control>
          </FormGroup>
          <FormGroup className="fields__new">
            <Label>email</Label>
            <Form.Control
              type="email"
              name="email"
              onChange={handleChange}
              value={data.email}
            ></Form.Control>
          </FormGroup>
          <FormGroup className="fields__new">
            <Label>Estado</Label>
            <Form.Control
              as="select"
              name="active"
              onChange={handleChange}
              value={data.active}
            >
              <option value={'true'}>Activo</option>
              <option value={'false'}>Inactivo</option>
            </Form.Control>
          </FormGroup>
          {isAdmin ? <FormGroup className="fields__new">
            <Label>Rol</Label>
            {(isView) ? (
              <Form.Control
                type="text"
                name="document_id"
                value={rolesData}
                disabled
              ></Form.Control>
            ) : (
              <Form.Control
                as="select"
                name="edit_badge"
                onChange={handleChangeRole}
                required
              >
                <option value="0" disabled>
                  Seleciona un opcion
                </option>
                <option value="administrator">Administrador</option>
                <option value="superadministrator">Superadministrator</option>
              </Form.Control>
            )}
          </FormGroup>
          :
          <div className="input_stepper" key={`$'}_fragment`}>
            <CFormLabel className="">
              Tags
            </CFormLabel>
            <CInputGroup style={{bordeRadius: '5px', border: '1px solid #dee2e6'}} className="input-group-multiselect w-100 border-1 ">
              <Multiselect
              ref={multiselectRef}
                avoidHighlightFirstOption
                // className={`w-100  ${
                //   !metaData.multi && listSelected?.[listName]
                //     ? "hide_input_select"
                //     : ""
                // }`}
                className="w-100"
                emptyRecordMsg="No hay categorías Disponibles"
                options={tags_array} // Options to display in the dropdown
                selectedValues={selected_tags_array} // Preselected value to persist in dropdown
                onSelect={(e) => {
                  selected_tags_set(e)
                }} // Function will trigger on select event
                onRemove={(e) => {
                  selected_tags_set(e)
                }} // Function will trigger on remove event
                onSearch={handleChangeMultiSelect} // Function will trigger on remove event
                displayValue="name" // Property name to display in the dropdown options
              />
              <CInputGroupText className="p-0">
                <Button type="button" disabled={!couldCrateTag} onClick={handleCreateTag}>
                  Crear tag 
                </Button>
              </CInputGroupText>
            </CInputGroup>
          </div>
          }
        </Modal.Body>
        <Modal.Footer>
          <Button color="danger" onClick={ModEdiCateSate}>
            Cerrar
          </Button>
          {isView ? (
            ""
          ) : (
            <Button
              color="success"
              onClick={(e) => handleSubmit(user.id, rolesSelect)}
            >
              Guardar Cambios
            </Button>
          )}
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default EditUser;
