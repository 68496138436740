import React, { useEffect, useState } from "react";
import {
  CButton,
  CCard,
  CCardBody,
  CCol,
  CFormLabel,
  CInputGroup,
  CInputGroupText,
} from "@coreui/react";
import CurrencyFormat from "react-currency-format";
import DoubleRadiusInput from "./FormComponents/DoubleRadiusInput";
import signService from "../service/sign.service";
import useToast from "../utils/CustomHooks/useToast";

function ExternalSignSettingsSection() {

  const [formDta, setFormDta] = useState({
    price: 0,
    free: true,
  });
  const addToast = useToast()
  useEffect(() => {
    const requestExternalSign= async () => {
        const externalSignSettings = await signService.requestExternalSignSettings()
        setFormDta(externalSignSettings)
    }
    requestExternalSign()
    return () => {
      
    }
  }, [])
  
  const handleChange = (e) => {
    let { name, value } = e.target;
    if (name === "free") value = value === "true";
    else if (name === "price") value = parseFloat(value.replace(/,/g, ""));
    setFormDta((prev) => {
      return { ...prev, [name]: value };
    });
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    
    signService.saveExternalSignSettings({...formDta}).then((value)=>{

      addToast({
        title:'Cambios efectuados',
        mode:'primary'
      })
    })
    
  };
  return (
    <div className="show_detail">
      <div className="table-responsive">
        <div className="header__field">
          <div className="text__information">
            <h4>Firmas externas</h4>
          </div>
        </div>

        <div className="container">
          <CCard>
            <CCardBody>
              <form onSubmit={handleSubmit}>
                <div className="row">
                  <CCol xs="auto" md="2" className="mt-2">
                    <DoubleRadiusInput
                      title="¿Firma gratuita?"
                      onChange={handleChange}
                      name="free"
                      value={`${formDta.free}`}
                    />
                  </CCol>
                  <CCol>
                    <CFormLabel>Valor a pagar</CFormLabel>
                    <CInputGroup className={formDta.free ? "disabled" : ""}>
                      <CInputGroupText id="basic-addon1"></CInputGroupText>
                      <CurrencyFormat
                        className="form-control"
                        allowNegative={false}
                        thousandSeparator={true}
                        disabled={formDta.free}
                        aria-label="price"
                        name="price"
                        value={!formDta.free ? formDta.price : 0}
                        onChange={handleChange}
                        aria-describedby="basic-addon1"
                      />
                    </CInputGroup>
                  </CCol>
                  <CCol xs="12" className="mb-2"></CCol>
                  <CCol></CCol>
                  <CCol xs="auto">
                    <CButton type="submit">Guardar</CButton>
                  </CCol>
                </div>
              </form>
            </CCardBody>
          </CCard>
        </div>
      </div>
    </div>
  );
}

export default ExternalSignSettingsSection;
