import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import "./assets/styles/App.scss";

import SingIn from "./containers/LoginUserReg";
import SingInAdmin from "./containers/LoginUserAdmin";
import PanelControl from "./containers/PanelControl";
import Payment from "./containers/Payment";
import ValidatePayment from "./containers/ValidatePayment";
import HomeAdmin from "./containers/HomeAdmin";

import { history } from "./helpers/history";
import Profile from "./containers/ProfilePage";
import Register from "./containers/Register";
import Page404 from "./containers/Page404";
import ResetPassword from "./containers/ResetPassword";
import { useDispatch } from "react-redux";
import { LOGIN_SUCCESS } from "./actions/types";
import userService from "./service/user.service";
import Routes from "./routes/Routes";
import { ToastContextProvider } from "./utils/Contexts/toast-configuration";
import { pdfjs } from "react-pdf";


const url = `//cdn.jsdelivr.net/npm/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`
pdfjs.GlobalWorkerOptions.workerSrc = url
// pdfjs.GlobalWorkerOptions.workerSrc = new URL(
//   "pdfjs-dist/build/pdf.worker.min.js",
//   import.meta.url
// ).toString();

const App = () => {
  const dispatch = useDispatch();
  const [loaded, setLoaded] = useState(false);
  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("user"));
    const requestUser = async () => {
      try {
        const dataUser = await userService.getUserBoard();
        const save = { ...dataUser.data, token: user.token };
        dispatch({
          type: LOGIN_SUCCESS,
          payload: { user: save, token: user.token },
        });
      } catch (error) {
        console.error(error)
      }
      setLoaded(true);
    };
    if (user) {
      requestUser();
    } else {
      setLoaded(true);
    }
    return () => {};
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return loaded ? (
    <ToastContextProvider>
      <Router history={history}>
        <Switch>

          <Route exact path={Routes.login()} component={SingIn} />
          <Route exact path={Routes.login(true)} component={SingInAdmin} />
          
          <Route exact path={Routes.methods(':id')} component={Payment} />
          <Route exact path={Routes.methods()} component={Payment} />
          
          <Route exact path={Routes.ValidatePayment()} component={ValidatePayment} />

          <Route exact path={Routes.profile} component={Profile} />

          <Route exact path={Routes.panel(':id')} component={PanelControl} />
          <Route exact path={Routes.panel()} component={PanelControl} />
          <Route exact path={Routes.base} component={PanelControl} />

          <Route path={Routes.admin()} component={HomeAdmin} />
          <Route exact path={Routes.register} component={Register} />
          <Route exact path={Routes.resetPassword} component={ResetPassword} />
          <Route path='*' component={Page404} />
        </Switch>
      </Router>
    </ToastContextProvider>
  ) : (
    <div
      style={{ height: "100vh" }}
      className="w-100 d-flex justify-content-center align-items-center"
    >
      <span className="spinner-border "></span>
    </div>
  );
};

export default App;
