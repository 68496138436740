import {
  REGISTER_SUCCESS,
  REGISTER_FAIL,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT,
  SET_MESSAGE,
  POST_LIST_SUCCESS,
  POST_LIST_FAIL,
  LIST_DYNAMIC,
  DELETE_LIST_DYNAMIC,
  ELEMENT_LIST,
} from "./types";

import AuthService from "../service/auth.service";

export const register = (name, email, password, password2) => (dispatch) => {
  return AuthService.register(name, email, password, password2).then(
    (data) => {
      
      if (data.token) {
        dispatch({
          type: REGISTER_SUCCESS,
          payload: { user: data, token: data.token },
        });
      } else {
        let message = data.data.message;
        if (data.data?.errors?.email) {
          message = "El correo ya se encuentra registrado en la plataforma"
        }

        dispatch({
          type: REGISTER_FAIL,
        });

        dispatch({
          type: SET_MESSAGE,
          payload: message,
        });
      }
      return data;
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: REGISTER_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });
    }
  );
};

export const login = (username, password, typeUser) => (dispatch) => {
  return AuthService.login(username, password, typeUser).then(
    (data) => {
      
      if (data.token) {
        dispatch({
          type: LOGIN_SUCCESS,
          payload: {
            user: data,
            typeUser: typeUser ? "admin" : "user",
            token: data.token,
          },
        });
      } else {
        let message = data.message;
        if (message === 'Las credenciales no son validas.') message = "Las credenciales no son válidas."
        dispatch({
          type: LOGIN_FAIL,
        });

        dispatch({
          type: SET_MESSAGE,
          payload: message,
        });
      }
      return data;
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      console.error(message);

      dispatch({
        type: LOGIN_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return error.response.data;
    }
  );
};

export const logout = () => (dispatch) => {
  AuthService.logout();

  dispatch({
    type: LOGOUT,
  });
};

export const getListDinamic = () => (dispatch) => {
  return AuthService.getList().then(
    (data) => {
      dispatch({
        type: LIST_DYNAMIC,
      });
      return Promise.resolve() && data;
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: POST_LIST_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    }
  );
};
export const postList = (name, description, data) => (dispatch) => {
  return AuthService.postList(name, description, data).then(
    (response) => {
      dispatch({
        type: POST_LIST_SUCCESS,
      });

      return Promise.resolve() && response;
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: POST_LIST_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    }
  );
};

export const deleteListDinamic = (id) => (dispatch) => {
  return AuthService.deleteList(id).then(
    (data) => {
      dispatch({
        type: DELETE_LIST_DYNAMIC,
      });
      return Promise.resolve() && data;
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: POST_LIST_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    }
  );
};

export const getListElement = (id__list) => (dispatch) => {
  return AuthService.getListElement(id__list).then(
    (response) => {
      dispatch({
        type: ELEMENT_LIST,
      });
      return Promise.resolve() && response;
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: POST_LIST_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    }
  );
};

export const deleteElementList = (id, position) => (dispatch) => {
  return AuthService.getDeleteElement(id, position).then(
    (data) => {
      dispatch({
        type: ELEMENT_LIST,
      });
      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: POST_LIST_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    }
  );
};

export const postNewUser =
  (name, email, password, password2, document_id, type) => async (dispatch) => {
    try {
      const axiosResponse = await AuthService.postNewUsers(
        name,
        email,
        password,
        password2,
        document_id,
        type
      );
      dispatch({
        type: POST_LIST_SUCCESS,
      });

      return axiosResponse;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: POST_LIST_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    }
  };

export const postChangeUser = (user_id) => (dispatch) => {
  return AuthService.changeUserPassword(user_id).then(
    (response) => {
      dispatch({
        type: POST_LIST_SUCCESS,
      });

      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: POST_LIST_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    }
  );
};

export const postInfoUser = (data__user) => (dispatch) => {
  return AuthService.EditInfoUser(data__user).then(
    (response) => {
      dispatch({
        type: POST_LIST_SUCCESS,
      });

      return Promise.resolve() && response;
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: POST_LIST_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    }
  );
};

export const postNewCateg = (name, description, icon_path) => (dispatch) => {
  return AuthService.postNewCateg(name, description, icon_path).then(
    (response) => {
      dispatch({
        type: POST_LIST_SUCCESS,
      });

      return Promise.resolve() && response;
    },
    (error) => {
      const message = error.response;
      console.error(message);
      dispatch({
        type: POST_LIST_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message.toString(),
      });

      return Promise.reject() && error;
    }
  );
};

export const updateCateg = (dataToSend, id) => (dispatch) => {
  return AuthService.updateCateg(dataToSend, id).then(
    (response) => {
      return Promise.resolve() && response;
    },
    (error) => {
      const message = error.response;
      console.error(message);
      dispatch({
        type: SET_MESSAGE,
        payload: message.toString(),
      });

      return Promise.reject() && error;
    }
  );
};

export const createTemplates =
  (
    name,
    doc,
    prices,
    supervice,
    porcent,
    price_super,
    categories,
    vars,
    lists,
    iva,
    need_firm,
    firm_price,
    stepperPages,
    optionals_variables_list,
    tags
  ) =>
  (dispatch) => {
    return AuthService.createTemplates(
      name,
      doc,
      prices,
      supervice,
      porcent,
      price_super,
      categories,
      vars,
      lists,
      iva,
      need_firm,
      firm_price,
      stepperPages,
      optionals_variables_list,
      tags
    ).then(
      (response) => {
        dispatch({
          type: POST_LIST_SUCCESS,
        });

        return Promise.resolve() && response;
      },
      (error) => {
        console.error(error.response);
        const message = error.response.data.message;

        dispatch({
          type: POST_LIST_FAIL,
        });

        dispatch({
          type: SET_MESSAGE,
          payload: message,
        });

        return Promise.reject();
      }
    );
  };
export const updateTemplates =
  (
    id,
    name,
    doc,
    prices,
    supervice,
    porcent,
    price_super,
    categories,
    vars,
    lists,
    iva,
    need_firm,
    firm_price,
    stepperPages,
    optionals_variables_list,
    tags
  ) =>
  (dispatch) => {
    return AuthService.updateTemplates(
      id,
      name,
      doc,
      prices,
      supervice,
      porcent,
      price_super,
      categories,
      vars,
      lists,
      iva,
      need_firm,
      firm_price,
      stepperPages,
      optionals_variables_list,
      tags
    ).then(
      (response) => {
        dispatch({
          type: POST_LIST_SUCCESS,
        });

        return Promise.resolve() && response;
      },
      (error) => {
        console.error(error.response);
        const message = error.response.data.message;

        dispatch({
          type: POST_LIST_FAIL,
        });

        dispatch({
          type: SET_MESSAGE,
          payload: message,
        });

        return Promise.reject();
      }
    );
  };

export const createDocumentsUser = (data) => (dispatch) => {
  return AuthService.createDocumentsUser(data).then(
    (response) => {
      dispatch({
        type: POST_LIST_SUCCESS,
      });

      return Promise.resolve() && response;
    },
    (error) => {
      const message = error.response.data;
      console.error(message);
      dispatch({
        type: POST_LIST_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    }
  );
};
