/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";

import "../assets/styles/components/SideNav.scss";
import { useDispatch } from "react-redux";
import { logout } from "../actions/auth";
import { Link, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { getTypeUser } from "../reducers/auth";
import Routes from "../routes/Routes";

const SideNav = (props) => {
  const { setPagesAdmin, name, typeUser } = props;
  const [list, setList] = useState(false);
  const [users, setUsers] = useState(false);

  const dispatch = useDispatch();
  const [close, setClose] = useState(false);
  const logOut = () => {
    dispatch(logout());
    setClose(true);
  };

  const clickOptionAdmin = (page) => {
    setPagesAdmin(page);
    //if (innerWidth < 766) setSideNav(false);
  };

  const onHanldeShow = (func, rever) => func(!rever);
  const havePer = typeUser === "superadmin";
  return (
    <nav id="sidebar" className="sidebar-wrapper">
      {close && <Redirect to="/login/admin" />}
      <div className="sidebar-content">
        <div className="sidebar-header">
          <div className="user-info">
            <span className="user-name">
              <strong>{name}</strong>
            </span>
            <span className="user-role">Administrator</span>
            <span className="user-status">
              <i className="fa fa-circle"></i>
              <span>Online</span>
            </span>
          </div>
        </div>
        <div className="sidebar-menu">
          <ul>
            <li className="header-menu">
              <span>General</span>
            </li>
            <li className="sidebar-dropdown">
              <a
                onClick={(e) => {
                  e.preventDefault();
                  onHanldeShow(setList, list);
                }}
              >
                <i className="far fa-list-alt"></i>
                <span>Listas</span>
                <span className="badge badge-pill badge-warning">New</span>
              </a>
              {list && (
                <div className="submenu">
                  <ul>
                    <li onClick={() => clickOptionAdmin({ listDynamic: true })}>
                      <Link to={Routes.admin("listas-dinamicas")}>
                        Dinamicas
                        <span className="badge badge-pill badge-success">
                          Pro
                        </span>
                      </Link>
                    </li>
                  </ul>
                </div>
              )}
            </li>
            {havePer && (
              <>
                <li className="sidebar-dropdown">
                  <a
                    href="#"
                    onClick={(e) => {
                      e.preventDefault();
                      onHanldeShow(setUsers, users);
                    }}
                  >
                    <i className="fas fa-users"></i>
                    <span>Usuarios</span>
                    <span className="badge badge-pill badge-danger">3</span>
                  </a>
                  {users && (
                    <div className="submenu">
                      <ul>
                        <li
                          onClick={() =>
                            clickOptionAdmin({
                              usersReg: true,
                              userAdmin: false,
                              userSuperAdmin: false,
                            })
                          }
                        >
                          <Link to={Routes.admin("users?type=admin")}>
                            Administradores
                          </Link>
                        </li>
                        <li
                          onClick={() =>
                            clickOptionAdmin({
                              usersReg: false,
                              userAdmin: true,
                              userSuperAdmin: false,
                            })
                          }
                        >
                          <Link to={Routes.admin("users?type=regular")}>
                            Regulares
                          </Link>
                        </li>
                        <li
                          onClick={() =>
                            clickOptionAdmin({
                              usersReg: false,
                              userAdmin: false,
                              userSuperAdmin: true,
                            })
                          }
                        >
                          <Link to={Routes.admin("users?type=superadmin")}>
                            Super Admin
                          </Link>
                        </li>
                      </ul>
                    </div>
                  )}
                </li>
                <li>
                  <Link
                    to={Routes.admin("categories")}
                    onClick={() => clickOptionAdmin({ catego: true })}
                  >
                    <i className="fas fa-folder-open"></i>
                    <span>Categorias</span>
                  </Link>
                </li>
              </>
            )}
            <li>
              <Link
                to={Routes.admin("templates")}
                onClick={() => clickOptionAdmin({ template: true })}
              >
                <i className="fas fa-file-alt"></i>
                <span>Plantillas</span>
              </Link>
            </li>
            <li>
              <Link
                to={Routes.admin("external-sign")}
                onClick={() => clickOptionAdmin({ externalSign: true })}
              >
                <i className="fas fa-file-alt"></i>
                <span>Firma externa</span>
              </Link>
            </li>
            <li>
              <Link
                to={Routes.admin("purchases")}
                onClick={() => clickOptionAdmin({ purchaseHistories: true })}
              >
                <i className="fas fa-shopping-cart"></i>
                <span>Historial de compras</span>
              </Link>
            </li>
            <li>
              <Link
                to={Routes.admin("external-sign-purchases")}
                onClick={() => clickOptionAdmin({ externalSignPurchaseHistories: true })}
              >
                <i className="fas fa-shopping-cart"></i>
                <span>Historial de compras de firmas externas</span>
              </Link>
            </li>

            {/* 
            <li className="header-menu">
              <span>Extra</span>
            </li>
            <li>
              <a href="#">
                <i className="fa fa-chart-line"></i>
                <span>Comentarios</span>
              </a>
            </li>
            <li>
              <a href="#">
                <i className="fa fa-globe"></i>
                <span>Noticias</span>
              </a>
            </li>
            <li>
              <a href="#">
                <i className="fa fa-book"></i>
                <span>Documentación</span>
              </a>
            </li> 
            */}
          </ul>
        </div>
      </div>
      <div className="sidebar-footer">
        <a href="#">
          <i className="fa fa-cog"></i>
          <span className="badge-sonar"></span>
        </a>
        <div
          onClick={(e) => {
            e.preventDefault();
            logOut();
          }}
        >
          <i className="fa fa-power-off"></i>
        </div>
      </div>
    </nav>
  );
};

const mapStateToProps = (state) => {
  return {
    typeUser: getTypeUser(state),
  };
};
const mapDispatchToProps = (dispatch) => ({});
export default connect(mapStateToProps, mapDispatchToProps)(SideNav);
