import React, { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { getListDinamic } from "../actions/auth";
import "../assets/styles/components/HomeAdmin.scss";
import ListEdit from "./ListEdit";
import Header from "../components/Header";
import SideNav from "../components/SideNav";
import PageUsers from "../components/PageUsers";
import TableCatego from "../components/TableCatego";
import PurchaseHistories from "../components/PurchaseHistories";
import Templates from "./Templates";

import "../assets/styles/components/SideNav.scss";
import { getDataUser } from "../reducers/auth";
import { protectComponent } from "../utils/protectAdminComponent";
import Routes from "../routes/Routes";
import { Route, Switch, useLocation } from "react-router-dom";
import externalSignSettingsSection from "../components/externalSignSettings.section";
import ExternalSignsPurchaseHistories from "../components/ExternalSignsPurchaseHistories";

const HomeAdmin = () => {
  // const dataUser = useSelector(getDataUser)
  // const typeUser = useSelector(getTypeUser)
  const [state, setState] = useState([]);
  // eslint-disable-next-line no-restricted-globals
  const [sideNav, setSideNav] = useState(screen.width < 766 ? false : true);
  const [loader, setLoader] = useState(true);
  const [refreshData, setrefreshData] = useState(false);
  const location= useLocation()
  
  let query = new URLSearchParams(location.search);
  //const [dataPerson, setDataPerson] = useState({});

  const dispatch = useDispatch();
  const dataPerson = useSelector(getDataUser);

  const [pagesAdmin, setPagesAdmin] = useState({
    usersReg: false,
    userAdmin: false,
    userSuperAdmin: false,
    listDynamic: true,
    catego: false,
    setting: false,
    template: false,
    purchaseHistories: false,
  });

  useEffect(() => {
    let index = 0;
    let stateArray = [];
    setLoader(true);
    dispatch(getListDinamic()).then((response) => {
      for (const key in response) {
        stateArray.push(response[key]);
        stateArray[index].id = key;
        index++;
      }
      setState(stateArray);
      setLoader(false);
    });
  }, [refreshData]);

  const handleClose = (sideNav) => {
    if (sideNav) {
      setSideNav(false);
    }
  };

  return (
    // return user ? (
    <div className="page-wrapper chiller-theme toggled">
      <Header
        isHomeAdmin
        setSideNav={setSideNav}
        handleClose={handleClose}
        sideNav={sideNav}
        name={dataPerson.name}
      />
      <div className="body__home">
        {sideNav && (
          <SideNav
            name={dataPerson.name}
            setPagesAdmin={()=>{}}
            setSideNav={setSideNav}
          />
        )}
        <div className="body__information p-0">
          <Switch>
            <Route
              exact
              path={Routes.admin("categories")}
              component={TableCatego}
            />
            <Route
              exact
              path={Routes.admin("users")}
              render={()=>{
                return <PageUsers
                  isUserReg={query.get('type') === 'regular'}
                  isSuperUser={query.get('type') === 'superadmin'}
                />
              }}
            />
            <Route
              exact
              path={Routes.admin("templates")}
              render={() => {
                return <Templates state={state} />;
              }}
            />
            <Route
              exact
              path={Routes.admin("purchases")}
              component={PurchaseHistories}
            />
            <Route
              exact
              path={Routes.admin("external-sign-purchases")}
              component={ExternalSignsPurchaseHistories}
            />
            <Route
              exact
              path={Routes.admin("external-sign")}
              component={externalSignSettingsSection}
            />
            <Route
              render={() => {
                return (
                  <ListEdit
                    state={state}
                    loader={loader}
                    setLoader={setLoader}
                    refreshData={refreshData}
                    setrefreshData={setrefreshData}
                  />
                );
              }}
            />
          </Switch>
        </div>
      </div>
    </div>
  );
};

export default protectComponent(HomeAdmin);
