import React from "react";
import Login from "../components/LoginAdmin";

const SingInAdmin = (props) => {
  return (
    <>
      <Login IsAdmin props={props} />
    </>
  );
};

export default SingInAdmin;
