import React, { useRef, useState } from "react";
import { Modal } from "react-bootstrap";
import userService from "../../service/user.service";
import { CButtonGroup } from "@coreui/react";
import { Button } from "reactstrap";
import AttachmentsDoc from "./AttachmentsDoc";

const PayedDocuments = ({
  showHook=[],
  documents=[],
  name,
}) => {
  const [show, setShow] = showHook
  const [attachments, setAttachments] = useState([]);
  const refPage = useRef(null)
  const close = () => {
    setShow(null);
  };
  const downloadDocument = async (docName) =>{
    try {
      const link = await userService.getDocumentStorageBlob(docName)
      var downloadLink = document.createElement('a')
      downloadLink.href = link
      downloadLink.download  = name+'.pdf'
      downloadLink.target = '_blank'
      
      document.body.appendChild(downloadLink)
      downloadLink.click()
      document.body.removeChild(downloadLink)
      downloadLink.remove()

    } catch (error) {
      console.error(error)
      
    }
    
  }
  const downloadAttachments = async (documentAttach) =>{
    try {
      const documentFile = await userService.getDocumentFirebase(documentAttach)
      setAttachments(documentFile?.attachments.map(it=>({filename:it,created_at : ''}))|| [])
      /*
      */
    } catch (error) {
      console.error(error)
    }
    


  }
  return (
    <>
    <Modal
      show={show && attachments.length===0}
      id="ModalAttachments"
      onHide={() => close()}
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>{`${name} `}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div
        ref={refPage}
          style={{
            fontSize: "20px",
            marginTop: "5px",
            marginBottom: "10px",
          }}
        >
          
        </div>
        {documents.length>0 ? (
          <table className="table table-light table-striped">
            <thead>
              <tr>
                <th className="text-center">#</th>
                <th className="text-center">Fecha de creación</th>
                <th className="text-center">Descargar</th>
              </tr>
            </thead>
            <tbody>
              {documents.map((file, i) => {
                const [DD,MM,YYYY,HH,mm] = file.document_number.slice(0,16).split('_')
                let date = `${DD}/${MM}/${YYYY} ${HH}:${mm}`
                if(!DD || !MM || !YYYY||!HH ||!mm){
                  date=file.purchase_date
                }
                return (
                  <tr className="attachmentFile" role="button" key={i} onClick={()=>{}}>
                    <td>{i+1}</td>
                    <td>{date}</td>
                    <td className="d-flex justify-content-center">
                    <CButtonGroup>
                      <Button
                      onClick={()=>{
                        downloadDocument(file.document_number)
                      }}
                       className="primary_custom_btn">Descargar</Button>
                      <Button
                      onClick={()=>{
                        downloadAttachments(file.firebase_document_id)
                      }} className="secondary_custom_btn">Adjuntos</Button>
                    </CButtonGroup>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        ) : (
          <div>No hay documentos adjuntos</div>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button
          type="submit"
          style={{
            backgroundColor: "#cf37cf",
            color: "white",
          }}
          onClick={() => close()}
        >
          Aceptar
        </Button>
      </Modal.Footer>
    </Modal>
    <AttachmentsDoc
    files={attachments}
    selectDoc={{name:'Documentos Adjuntos',id:''}}
    showAttachMod={attachments.length>0}
    setListAttachments={()=> {setAttachments([])}}


    />
    </>
  );
};

export default React.memo(PayedDocuments);
