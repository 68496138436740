import React, { useState, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, Redirect } from "react-router-dom";

import "../assets/styles/components/Login.scss";

import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";

import ImageLogin from "../assets/static/martillo.jpg";

import { login } from "../actions/auth";
import Header from "./Header";

const Login = (props) => {
  const form = useRef();
  const checkBtn = useRef();

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const [loading, setLoading] = useState(false);
  const { isLoggedIn } = useSelector((state) => state.auth);
  const { message } = useSelector((state) => state.message);
  const { typeUser } = useSelector((state) => state.auth);

  const dispatch = useDispatch();
  const onChangeUsername = (e) => {
    const username = e.target.value;
    setUsername(username);
  };

  const onChangePassword = (e) => {
    const password = e.target.value;
    setPassword(password);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    setLoading(true);
    form.current.validateAll();
    if (checkBtn.current.context._errors.length === 0) {
      dispatch(login(username, password, true))
        .then((response) => {
          if (response.token) {
            props.props.history.push("/admin");
          }
          setLoading(false);

          //window.location.reload();
        })
        .catch(() => {
          setLoading(false);
        });
    } else {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (isLoggedIn && typeUser === "admin") {
      return <Redirect to="/admin" />;
    }
  }, []);

  return (
    <>
      <div className="login__page__admin isUpdated">
        <Header isLoginAdmin />
        <section className="form__login d-flex justify-content-center">
          <div className="login__body">
            <div className="login__admin">
              <img src={ImageLogin} alt="" />
              <div className="formulario">
                <Form onSubmit={handleSubmit} ref={form}>
                  <div className="input-group form-group">
                    <Input
                      type="text"
                      className="form-control"
                      placeholder="Usuario Administrador"
                      value={username}
                      onChange={onChangeUsername}
                      required="required"
                    />
                  </div>
                  <div className="input-group form-group">
                    <Input
                      type="password"
                      className="form-control"
                      placeholder="Contraseña"
                      onChange={onChangePassword}
                      required="required"
                    />
                  </div>

                  <div className="form-group">
                    <button
                      type="submit"
                      className="login_btn"
                      disabled={loading}
                    >
                      {loading ? (
                        <span className="spinner-border spinner-border-sm"></span>
                      ) : (
                        <span>Ingresar</span>
                      )}
                    </button>
                  </div>

                  <CheckButton style={{ display: "none" }} ref={checkBtn} />
                </Form>
                <div className="d-flex justify-content-center">
                  <Link
                    className="contra__login"
                    to={{
                      pathname: "/reset-password",
                      state: { isAdmin: true },
                    }}
                  >
                    Olvide mi contraseña
                  </Link>
                </div>
                {message && (
                  <div className="form-group">
                    <div
                      className="alert alert-danger error__login"
                      role="alert"
                    >
                      {message}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default Login;
