import React, { useEffect, useState } from "react";

import UserService from "../service/user.service";
import "../assets/styles/components/TableUser.scss";
import { Button } from "reactstrap";
import PaginationFull from "./subcomponents/PaginationFull";
import moment from "moment";

const TableUser = (props) => {
  const {
    isUserRegTable,
    data,
    state,
    OpenModalDelete,
    OpenModalEdit,
    totalPage,
    handleChangesPage,
    currentPage,
    OpenModalAlert,
  } = props;

  const [dataPerson, setDataPerson] = useState({
    name: "",
    birth: "",
    phone: "",
    email: "",
  });

  useEffect(() => {
    UserService.getUserBoard().then((response) => {
      setDataPerson(response.data);
    });
  }, []);

  return (
    <>
      <div className="card card-users mb-4">
        <div className="card-header">
          <i className="fas fa-table mr-1"></i>
          Usuarios
        </div>
        <div className="card-body">
          <div className="table-responsive">
            <table
              id="dataTable"
              className="table table-bordered table-striped "
            >
              <thead id="dataTableHead">
                <tr>
                  <th>NOMBRES</th>
                  <th>CORREO ELECTRONICO</th>
                  <th>ESTADO</th>
                  <th>FECHA DE ACTUALIZACION</th>
                  {isUserRegTable ? (
                    <th>RESTAURAR CONTRASEÑA</th>
                  ) : (
                    <th>ACCIONES</th>
                  )}
                </tr>
              </thead>
              <tbody>
                {data.length > 0
                  ? data.map((user, index) => {
                      return (
                        <tr key={index}>
                          <td>{user.name}</td>
                          <td>{user.email}</td>
                          <td>
                            {user.active ? (
                              <div className="d-flex align-items-center bole">
                                <div className="esfera color"></div>
                                <span>Activo</span>
                              </div>
                            ) : (
                              <div className="d-flex align-items-center bole">
                                <div className="esfera color-danger"></div>
                                <span>Inactivo</span>
                              </div>
                            )}
                          </td>
                          <td>{moment(user.updated_at).format("DD/MM/YY")}</td>
                          <td className="buttones">
                            {isUserRegTable ? (
                                <Button
                                  className="mr-4 shadow btn-circle"
                                  color="info"
                                  size="sm"
                                  onClick={(e) => {
                                    OpenModalEdit(e, user);
                                  }}
                                >
                                  <i className="fas fa-pencil-alt"></i>
                                </Button>
                            ) : (
                              <>
                                <Button
                                  className="mr-4 shadow btn-circle"
                                  color="info"
                                  size="sm"
                                  onClick={(e) => {
                                    OpenModalEdit(e, user);
                                  }}
                                >
                                  <i className="fas fa-pencil-alt"></i>
                                </Button>
                                <Button
                                  className="mr-4 shadow btn-circle"
                                  color="warning"
                                  size="sm"
                                  onClick={(e) => {
                                    OpenModalEdit(e, user, true);
                                  }}
                                >
                                  <i className="fas fa-eye"></i>
                                </Button>
                                {dataPerson.email === user.email ? (
                                  <Button
                                    className="mr-4 shadow btn-circle"
                                    color="danger"
                                    size="sm"
                                  >
                                    <i className="far fa-times-circle"></i>
                                  </Button>
                                ) : (
                                  <Button
                                    className="mr-4 shadow btn-circle"
                                    color="danger"
                                    size="sm"
                                    onClick={(e) =>
                                      OpenModalDelete(e, user.name, user.id)
                                    }
                                  >
                                    <i className="far fa-trash-alt white"></i>
                                  </Button>
                                )}
                              </>
                            )}
                          </td>
                        </tr>
                      );
                    })
                  : state.map((data, index) => {
                      return (
                        <tr key={index}>
                          <td>{data.name}</td>
                          <td>{data.email}</td>
                          <td>
                            {data.active ? (
                              <div className="d-flex align-items-center bole">
                                <div className="esfera color"></div>
                                <span>Activo</span>
                              </div>
                            ) : (
                              <div className="d-flex align-items-center bole">
                                <div className="esfera color-danger"></div>
                                <span>Inactivo</span>
                              </div>
                            )}
                          </td>
                          <td>{moment(data.updated_at).format("DD/MM/YY")}</td>
                          <td className="buttones">
                            {isUserRegTable ? (
                                <Button
                                  className="mr-4 shadow btn-circle"
                                  color="info"
                                  size="sm"
                                  onClick={(e) => {
                                    OpenModalEdit(e, data);
                                  }}
                                >
                                  <i className="fas fa-pencil-alt"></i>
                                </Button>
                            ) : (
                              <>
                                <Button
                                  className="mr-4 shadow btn-circle"
                                  color="info"
                                  size="sm"
                                  onClick={(e) => {
                                    OpenModalEdit(e, data, false);
                                  }}
                                >
                                  <i className="fas fa-pencil-alt"></i>
                                </Button>
                                <Button
                                  className="mr-4 shadow btn-circle"
                                  color="warning"
                                  size="sm"
                                  onClick={(e) => {
                                    OpenModalEdit(e, data, true);
                                  }}
                                >
                                  <i className="fas fa-eye"></i>
                                </Button>
                                {dataPerson.email === data.email ? (
                                  <Button
                                    className="mr-4 shadow btn-circle"
                                    color="danger"
                                    size="sm"
                                    onClick={(e) => OpenModalAlert(e)}
                                  >
                                    <i className="far fa-times-circle"></i>
                                  </Button>
                                ) : (
                                  <Button
                                    className="mr-4 shadow btn-circle"
                                    color="danger"
                                    size="sm"
                                    onClick={(e) =>
                                      OpenModalDelete(e, data.name, data.id)
                                    }
                                  >
                                    <i className="far fa-trash-alt white"></i>
                                  </Button>
                                )}
                              </>
                            )}
                          </td>
                        </tr>
                      );
                    })}
              </tbody>
            </table>
          </div>
          <PaginationFull
            maxBtnPerSide={2}
            currentPage={currentPage}
            totalPage={totalPage}
            onChangePage={handleChangesPage}
          />
        </div>
      </div>
    </>
  );
};

export default TableUser;
