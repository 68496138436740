import React from "react";
import { useSelector } from "react-redux";

import { Redirect } from "react-router-dom";
import Profile from "../components/Profiles";
import { getDataUser } from "../reducers/auth";

const ProfilePage = (props) => {
  const user = useSelector(getDataUser)
  return user ? <Profile /> : <Redirect to="/login" />;
};

export default ProfilePage;
