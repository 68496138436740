import React from "react";
import PageRegister from "../components/PageRegister";

const Register = (props) => {
  return (
      <PageRegister props={props}/>
  );
};

export default Register;
