import React, { useState } from "react";

import "../../assets/styles/components/NewFields.scss";
import { Button, FormGroup, Label } from "reactstrap";
import { Form, Modal } from "react-bootstrap";


import { useDispatch } from "react-redux";
import { deleteElementList,  } from "../../actions/auth";

function Row(props) {
  // const { row, onRemove, index, id} = props;
  const { row } = props;
  return (
    <div className="fieldGroup">
      <FormGroup className=" fieldElement">
        <Label>Nombre del campo</Label>
        <input value={row?.name ? row.name : ""} disabled />
      </FormGroup>

      <FormGroup className="fieldElement">
        <Label>Descripción del campo</Label>
        <textarea
          value={row?.description ? row.description : ""}
          disabled
        />
      </FormGroup>
      {
        /*<Button
        className="delete__button"
        color="danger"
        onClick={() => onRemove(id,index )}
      >
        Eliminar
      </Button>
      */
      }
    </div>
  );
}

function RowList({ onChange=()=>{}, onRemove=()=>{}, nombre='', select }) {
  return (
    <>
      {select && (
        <>
          <FormGroup className="">
            <Label>Nombre del elemento</Label>
            <Form.Control
              type="text"
              value={nombre}
              onChange={(e) => onChange("name", e.target.value)}
              required
            ></Form.Control>
          </FormGroup>

          <FormGroup className="">
            <Label>Descripción del elemento</Label>
            <Form.Control
              as="textarea"
              name="description"
              rows="3"
              onChange={(e) => onChange("description", e.target.value)}
              required
            ></Form.Control>
          </FormGroup>
          <Button className="delete__button" onClick={onRemove}>
            Eliminar
          </Button>
        </>
      )}
    </>
  );
}

const ViewList = (props) => {
  const { ModViewCateSate, showViewMod, idList, elementView } = props;

  const defaultState = {
    name: "",
    description: "",
  };

  const [rows, setRows] = useState([defaultState]);
  const [select, setSelect] = useState(false);
  const { items } = elementView;
  const dispatch = useDispatch();


  const handleChange = (index, name, value) => {
    const copyRows = [...rows];
    copyRows[index] = {
      ...copyRows[index],
      [name]: value,
    };
    setRows(copyRows);
    //setform({ name: nameHook, description: descriptionHook, items: rows });
  };


  const onRemoveFields = (id, position) => {
    dispatch(deleteElementList(idList, position));
  };
  const handleOnRemove = (index) => {
    const copyRows = [...rows];
    copyRows.splice(index, 1);
    setRows(copyRows);
  };

  // const handleOnAdd = () => {
  //   setRows(rows.concat(defaultState));
  //   setSelect(true);
  // };
  return (
    <Modal show={showViewMod} id="ModalAdd" onHide={ModViewCateSate}>
      <Modal.Header closeButton>
        <Modal.Title>
          <h2>{elementView?.name}</h2>{" "}
          <p className="small">{elementView?.description}</p>
        </Modal.Title>
      </Modal.Header>
      <Form role="form">
        <Modal.Body>
          <FormGroup className="fields__new ViewListCont">
            {elementView.items === [] && elementView ? (
              ""
            ) : items === undefined ? (
              <h6>No hay campos para mostrar</h6>
            ) : (
              items.map((data, index) => (
                <Row
                  row={data}
                  key={index}
                  onRemove={onRemoveFields}
                  index={index}
                  id={elementView.id}
                />
              ))
            )}

            {rows.map((row, index) => (
              <RowList
                {...row}
                onChange={(name, value) => handleChange(index, name, value)}
                onRemove={() => handleOnRemove(index)}
                key={index}
                setSelect={setSelect}
                select={select}
              />
            ))}
            {/* 
            //decidi no borrarlo para cuando vaya a agregarse funcionalidad
            <Button className="btn" onClick={handleOnAdd}>
              Mas campos
            </Button> 
            */}
          </FormGroup>
        </Modal.Body>
        <Modal.Footer>
          <Button color="danger" onClick={ModViewCateSate}>
            Cerrar
          </Button>
          <Button type="submit" color="success">
            Guardar Cambios
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default ViewList;
