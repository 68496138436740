import axios from "axios";
import authHeader from "./auth-header";
import { ref, push, update, child, get, remove } from "firebase/database";
import { realTimeDB, storage } from "../utils/firebase.config.js";
import mercadoPagoOrdersApi from "../utils/mercadoPagoOrdersApi";
import { ref as storageRef, uploadBytes } from "firebase/storage";
import htmlToPdfmake from "html-to-pdfmake";
import pdfMake from "pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import moment from "moment";
import Base64 from "../utils/Base64";
import userService from "./user.service";
import { removeAccents } from "../utils/TextUtils";
import bodyZapSign from "../utils/bodyZapSign.json";
import LOGO_final from "../assets/static/LOGO_final.png";
import getBase64Image from "../utils/ImgToBase64";

const API_URL = process.env.REACT_APP_API_URL;

const register = async (name, email, password, password2) => {
  try {
    const registerRequest = await axios.post(API_URL + "register", {
      name: name,
      email: email,
      password: password,
      password_confirmation: password2,
    });

    let retObj = { ...registerRequest.data };
    if (registerRequest.data.token) {
      localStorage.setItem("user", JSON.stringify(registerRequest.data));
      const requestData = await axios.get(API_URL + "user", {
        headers: { Authorization: `Bearer ${registerRequest.data.token}` },
      });
      retObj = { ...requestData.data, token: registerRequest.data.token };
    }
    return retObj;
  } catch (error) {
    console.error(error.response);
    return error.response;
  }
};

const login = async (username, password, isTypeUser) => {
  const value = isTypeUser ? "admin/login" : "login";
  try {
    const requestLogin = await axios.post(API_URL + value, {
      email: username,
      password: password,
      password_confirmation: password,
    });

    let retObj = { ...requestLogin.data };
    if (requestLogin.data.token) {
      localStorage.setItem("user", JSON.stringify(requestLogin.data));
      const requestData = await axios.get(API_URL + "user", {
        headers: authHeader(),
      });
      retObj = { ...requestData.data, token: requestLogin.data.token };
    }
    return retObj;
  } catch (error) {
    console.error(error);
    return error.response.data;
  }
};

const logout = () => {
  localStorage.removeItem("user");
};

const getList = () => {
  return axios
    .get(API_URL + "list", { headers: authHeader() })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.error(error);
    });
};

const postList = (name, description, data) => {
  return axios
    .post(
      API_URL + "list",
      {
        name: name,
        description: description,
        items: data,
      },
      { headers: authHeader() }
    )
    .then((response) => {
      return response.data;
    });
};
const deleteList = (id) => {
  return axios.delete(API_URL + "list/" + id, { headers: authHeader() });
};

const getListElement = (id__list) => {
  return axios
    .get(`${API_URL}list/${id__list}`, { headers: authHeader() })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error;
    });
};

const getDeleteElement = (id, position) => {
  return axios
    .delete(
      API_URL + "list/element",
      {
        id: id,
        idElement: position,
      },
      { headers: authHeader() }
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error;
    });
};

const postNewUsers = (name, email, password, password2, document_id, type) => {
  return axios.post(
    API_URL + "userAdmins",
    {
      name: name,
      email: email,
      password: password,
      password_confirmation: password2,
      document_id: document_id,
      rol: type,
    },
    { headers: authHeader() }
  );
};

const changeUserPassword = (user_id) => {
  return axios.post(API_URL + `users/${user_id}/resetPassword`, {
    headers: authHeader(),
  });
};
const updateCateg = (dataToSend, id) => {
  return axios.put(API_URL + `categories/${id}`, dataToSend, {
    headers: authHeader(),
  });
};

const EditInfoUser = (data) => {
  return axios.post(API_URL + "user", data, {
    headers: authHeader(),
  });
};

const postNewCateg = (name, description, icon_path) => {
  return axios.post(
    API_URL + "categories",
    {
      name: name,
      description: description,
      icon_path: icon_path,
    },
    { headers: authHeader() }
  );
};

const createTemplates = async (
  name,
  doc,
  price,
  supervice,
  porcent,
  price_supervice,
  categories,
  vars,
  list,
  iva,
  need_firm,
  firm_price,
  stepperPages,
  optionals_variables_list = [],
  tags
) => {
  const templateReference = ref(realTimeDB, "templates");
  try {
    const dataToSend = {
      categorias: categories,
      document: doc,
      iva: iva,
      lists: list,
      name: name,
      percentage_check: porcent,
      price: parseFloat(price),
      status: "BORRADOR",
      supervise: supervice,
      supervise_price: price_supervice,
      vars: vars,
      need_firm,
      firm_price,
      stepperPages,
      optionals_variables_list,
      tags : tags.map(tag=>tag.id)
    };
    if (!dataToSend.need_firm) {
      delete dataToSend.firm_price;
    }
    if (!dataToSend.supervise) {
      delete dataToSend.supervise_price;
      delete dataToSend.percentage_check;
    }
    const request = await push(templateReference, dataToSend);

    return request;
  } catch (error) {
    console.error(error);
  }
  return;
  /*
  return axios.post(
    API_URL + "templates",
    {
      name: name,
      document: doc,
      price: prices,
      supervise: supervice,
      percentage_check: porcent,
      supervise_price: price_super,
      categorias: categories,
      vars: vars,
      lists: list,
      iva: iva,
    },
    { headers: authHeader() }
  );
  */
};
const updateTemplates = async (
  id,
  name,
  doc,
  price,
  supervice,
  porcent,
  price_supervice,
  categories,
  vars,
  list,
  iva,
  need_firm,
  firm_price,
  stepperPages,
  optionals_variables_list = [],
  tags
) => {
  const updates = {};
  const dbRef = ref(realTimeDB);
  const firebaseDoc = await get(child(dbRef, `/templates/${id}`));
  try {
    const dataToSend = {
      categorias: categories,
      document: doc,
      iva: iva,
      lists: list,
      name: name,
      percentage_check: porcent,
      price: parseFloat(price),
      supervise: supervice,
      supervise_price: price_supervice,
      vars: vars,
      need_firm,
      firm_price,
      stepperPages,
      optionals_variables_list,
    };
    if (tags) dataToSend.tags = tags.map(tag=>tag.id)
    const firebaseDTA = { ...firebaseDoc.val(), ...dataToSend };
    if (!firebaseDTA.need_firm) {
      delete firebaseDTA.firm_price;
    }
    if (!firebaseDTA.supervise) {
      delete firebaseDTA.supervise_price;
      delete firebaseDTA.percentage_check;
    }

    updates["/templates/" + id] = firebaseDTA;
    await update(dbRef, updates);
    return firebaseDTA;
  } catch (error) {
    console.error(error);
  }
  return;
  /*
  return axios.post(
    API_URL + "templates",
    {
      name: name,
      document: doc,
      price: prices,
      supervise: supervice,
      percentage_check: porcent,
      supervise_price: price_super,
      categorias: categories,
      vars: vars,
      lists: list,
      iva: iva,
    },
    { headers: authHeader() }
  );
  */
};

const getPayedDocumentsUser = async ({ userID, orderID }) => {
  try {
    let url = `/orders`;
    if (userID) {
      const coded = Base64.encode(`${userID}`);
      url += `/user/${coded}`;
    } else if (orderID) {
      const coded = Base64.encode(`${orderID}`);
      url += `/${coded}`;
    }
    const { data } = await mercadoPagoOrdersApi.get(url);
    return data.data;
  } catch (error) {
    console.error(error);
    return [];
  }
  /*
  return axios.post(API_URL + "documents/create", data_completa, {
    headers: authHeader(),
  });
  */
};
const createDocumentsUser = async ({
  document,
  user = {},
  lists,
  vars,
  list2,
  vars2,
  template_id,
  status_document = "borrador",
  transaction_amount,
  name,
  attachments,
  need_firm,
  ...rest
}) => {
  const templateReference = ref(realTimeDB, "documents");
  let document_id;
  delete vars2["conf1rm_f1rmed_Document"];
  try {
    const dataToSend = {
      created_at: moment().toDate(),
      document: document,
      user_id: user.id,
      user_lists: list2,
      user_vars: vars2,
      status: status_document,
      template_id,
      attachments,
      name,
      need_firm,
    };
    const request = await push(templateReference, dataToSend);
    const orderToSend = {
      state: "pendiente",
      email: user.email,
      transaction_amount,
      document_type: template_id,
      document_number: "pendiente",
      firebase_document_id: request.key,
      user_regular_id: user.id,
      name: name,
      payment_id: null,
    };
    document_id = request.key;
    const { data } = await mercadoPagoOrdersApi.post("/orders", orderToSend);

    return { ...data.data, ...data.meta };
  } catch (error) {
    if (document_id) {
      await remove(ref(realTimeDB, `/documents/${document_id}`));
    }
    console.error(error);
  }
  return;
  /*
  return axios.post(API_URL + "documents/create", data_completa, {
    headers: authHeader(),
  });
  */
};
const updateDocument = async ({
  document_id,
  payment_id,
  order_id,
  //user={},
  status_document = "borrador",
  ...rest
}) => {
  try {
    // Write the new post's data simultaneously in the posts list and the //user's post list.
    const updates = {};
    const dbRef = ref(realTimeDB);

    const firebaseDoc = await get(child(dbRef, `/documents/${document_id}`));
    const firebaseDTA = firebaseDoc.val();
    const docId = firebaseDoc.key;
    const nameDocument = removeAccents(
      `${moment().format("DD_MM_YYYY_HH_mm")}-${docId}.pdf`
    );
    let state_document =
      status_document === "approved" ? "pagado" : status_document;
    if (firebaseDTA.need_firm && status_document === "approved") {
      state_document = "necesita_firma";
    }
    const orderToSend = {
      id: order_id,
      state: state_document,
      document_type: firebaseDTA.template_id,
      document_number: nameDocument,
      payment_id,
    };

    const coded = Base64.encode(`${order_id}`);
    let url = `/orders/${coded}`;
    const { data } = await mercadoPagoOrdersApi.put(url, orderToSend);

    if (status_document === "approved") {
      //id="[A-Za-z0-9_]*"
      const formatedDoc = firebaseDTA.document.replace(/id=/g, "html_id=");
      var html = htmlToPdfmake(formatedDoc, {
        defaultStyles: {
          p: { alignment: "justify" },
        },
        removeExtraBlanks: true,
      }).map((p) => {
        const newP = { ...p };
        delete newP.positions;
        delete newP.nodeInfo;
        newP.margin = [0, 0, 0, 9];
        if (typeof newP.text === typeof []) {
          newP.text = newP.text.map((subP) => {
            const newSubP = { ...subP };
            if (newSubP.text?.includes("**")) {
              newSubP.text = newSubP.text.replaceAll("**", "");
              newP.pageBreak = "after";
            }
            return newSubP;
          });
        }
        if (newP.text?.includes("**")) {
          newP.text = newP.text.replaceAll("**", "");
          newP.pageBreak = "after";
        }
        return newP;
      });

      const img = new Image();
      img.src = LOGO_final;
      const imgFormatted = await new Promise((resolve, reject) => {
        img.onload = function () {
          try {
            resolve(img);
          } catch (error) {
            console.log(error);
            reject(error);
          }
        };
      });
      const base64IMG = getBase64Image(imgFormatted);

      const documentDefinition = {
        info: {
          title: nameDocument,
        },
        defaultStyle: {
          fontSize: 11,
          lineHeight: 0.9,
          alignment: "justify",
        },
        defaultFileName: nameDocument,
        content: html,

        // [left, top, right, bottom] or [horizontal, vertical] or just a number for equal margins
        pageMargins: [65, 90, 65, 70],
        header: {
          // you can also fit the image inside a rectangle
          image: base64IMG,
          fit: [90, 90],
          margin: [60, 0, 0, 60],
          alignment: "right",
        },

        footer: [
          { text: "www.yolitigo.com", alignment: "center", fontSize: 8 },
          {
            text: "servicioalcliente@yolitigo.com",
            alignment: "center",
            fontSize: 8,
          },
          { text: "Bogotá D.C. - Colombia", alignment: "center", fontSize: 8 },
        ],

        pageBreakBefore: function (
          currentNode,
          followingNodesOnPage,
          nodesOnNextPage,
          previousNodesOnPage
        ) {
          return (
            currentNode.style &&
            currentNode.style.indexOf("pdf-pagebreak-before") > -1
          );
        },
        unbreakable: true,
      };
      pdfMake.vfs = pdfFonts.pdfMake.vfs;

      await new Promise((resolve, reject) => {
        pdfMake.createPdf(documentDefinition).getBlob(async (blob) => {
          const storageReference = storageRef(storage, nameDocument);

          // 'file' comes from the Blob or File API
          try {
            await uploadBytes(storageReference, blob);
            resolve();
          } catch (error) {
            reject({ error: true });
          }
        });
      });
      firebaseDTA.document_file_name = nameDocument;
    }

    firebaseDTA.status = state_document;
    firebaseDTA.payment_id = payment_id;
    updates["/documents/" + document_id] = firebaseDTA;
    await update(ref(realTimeDB), updates);

    const templateDoc = await get(
      child(dbRef, `/templates/${firebaseDTA.template_id}`)
    );

    return {
      ...data.data,
      template: { ...templateDoc.val(), template_id: firebaseDTA.template_id },
    };
  } catch (error) {
    console.error(error);
  }
  return;
  /*
  return axios.post(API_URL + "documents/create", data_completa, {
    headers: authHeader(),
  });
  */
};
const createTestDocument = async ({ documentHTML }) => {
  try {
    const formatedDoc = documentHTML.trim().replace(/id=/g, "html_id=");
    var html = htmlToPdfmake(formatedDoc, {
      defaultStyles: {
        p: { alignment: "justify" },
      },
      removeExtraBlanks: true,
    }).map((p) => {
      const newP = { ...p };
      delete newP.positions;
      delete newP.nodeInfo;
      newP.margin = [0, 0, 0, 9];
      if (typeof newP.text === typeof []) {
        newP.text = newP.text.map((subP) => {
          const newSubP = { ...subP };
          if (newSubP.text?.includes("**")) {
            newSubP.text = newSubP.text.replaceAll("**", "");
            newP.pageBreak = "after";
          }
          return newSubP;
        });
      }
      if (newP.text?.includes("**")) {
        newP.text = newP.text.replaceAll("**", "");
        newP.pageBreak = "after";
      }
      return newP;
    });
    // console.log(html);
    const img = new Image();
    img.src = LOGO_final;
    const imgFormatted = await new Promise((resolve, reject) => {
      img.onload = function () {
        try {
          resolve(img);
        } catch (error) {
          console.log(error);
          reject(error);
        }
      };
    });
    const base64IMG = getBase64Image(imgFormatted);
    const documentDefinition = {
      info: {
        title: "testDocument.pdf",
      },
      pageSize: "A4",

      // [left, top, right, bottom] or [horizontal, vertical] or just a number for equal margins
      pageMargins: [65, 90, 65, 70],
      defaultFileName: "testDocument.pdf",
      content: [html],

      header: {
        // you can also fit the image inside a rectangle
        image: base64IMG,
        fit: [90, 90],
        margin: [60, 0, 0, 60],
        alignment: "right",
      },

      footer: [
        { text: "www.yolitigo.com", alignment: "center", fontSize: 8 },
        {
          text: "servicioalcliente@yolitigo.com",
          alignment: "center",
          fontSize: 8,
        },
        { text: "Bogotá D.C. - Colombia", alignment: "center", fontSize: 8 },
      ],

      defaultStyle: {
        fontSize: 11,
        lineHeight: 0.9,
        alignment: "justify",
      },

      pageBreakBefore: function (
        currentNode,
        followingNodesOnPage,
        nodesOnNextPage,
        previousNodesOnPage
      ) {
        return (
          currentNode.style &&
          currentNode.style.indexOf("pdf-pagebreak-before") > -1
        );
      },
      unbreakable: true,
    };
    pdfMake.vfs = pdfFonts.pdfMake.vfs;

    const response = await new Promise((resolve, reject) => {
      pdfMake.createPdf(documentDefinition).getDataUrl(async (blob) => {
        // 'file' comes from the Blob or File API
        try {
          resolve(blob);
        } catch (error) {
          reject({ error: true });
        }
      });
    });
    return response;
  } catch (error) {
    console.error(error);
    throw error;
  }
  /*
  return axios.post(API_URL + "documents/create", data_completa, {
    headers: authHeader(),
  });
  */
};
/**
 *
 * @param {
 * document_id: String,
 * order_id: String,
 * document_name: String,
 * signers: Array,
 * options: Object
 * } params
 * @returns {
 *    orderDta: Object,
 *    zapSignDta: Object
 *  }
 */
const updateFirmDocument = async ({
  document_id,
  order_id,
  document_name,
  signers,
  options,
  //user={},
  ...rest
}) => {
  try {
    // Write the new post's data simultaneously in the posts list and the //user's post list.
    const updates = {};
    const dbRef = ref(realTimeDB);
    const firebaseDoc = await get(child(dbRef, `/documents/${document_id}`));
    const firebaseDTA = firebaseDoc.val();
    
    const link2 = await userService.getDocumentStorageLink(document_name);
    const dataToSend = { ...bodyZapSign, ...options };
    dataToSend.name = firebaseDTA.name;
    delete dataToSend.url_pdf;
    // dataToSend.base64_pdf = link2;
    dataToSend.url_pdf = link2;
    dataToSend.signers = signers;
    const { data: zapsignData } = await mercadoPagoOrdersApi.post(
      "/zapsign/docs",
      dataToSend
    );
    let state_document = "por_firmar";
    const orderToSend = {
      document_number: zapsignData.data.token,
      state: state_document,
    };

    const coded = Base64.encode(`${order_id}`);
    let url = `/orders/${coded}`;
    const { data } = await mercadoPagoOrdersApi.put(url, orderToSend);

    firebaseDTA.zapSign_token = zapsignData.data.token;
    firebaseDTA.status = state_document;
    updates["/documents/" + document_id] = firebaseDTA;
    await update(ref(realTimeDB), updates);

    return {
      orderDta: { ...data.data },
      zapSignDta: { ...zapsignData.data },
    };
  } catch (error) {
    console.error(error);
    return Promise.reject(error);
  }
};
/**
 *
 * @param {
 * signer_token: String,
 * document_token: String,
 *  new_signer: Object,
 * } params
 * @returns {
 *    zapSignDta: Object
 *  }
 */
const updateSignersDocument = async ({
  signer_token_to_delete,
  sign_document_token,
  new_signer,
  //user={},
  ...rest
}) => {
  try {
    if (signer_token_to_delete) {
      await mercadoPagoOrdersApi.delete(
        `/zapsign/signer/${signer_token_to_delete}`
      );
    }
    if (new_signer) {
      const dataToSend = { ...bodyZapSign.signers[0], ...new_signer };
      await mercadoPagoOrdersApi.post(
        `/zapsign/docs/${sign_document_token}/signer`,
        dataToSend
      );
    }
    const { data: zapsignData } = await mercadoPagoOrdersApi.get(
      `/zapsign/docs/${sign_document_token}`
    );

    return {
      zapSignDta: { ...zapsignData.data },
    };
  } catch (error) {
    console.error(error);
    return Promise.reject(error);
  }
};
const createDraftUser = (body) => {
  return axios.post(API_URL + "documents/createDraft", body, {
    headers: authHeader(),
  });
};

const getDocumentsByUser = (body) => {
  return axios.post(API_URL + "documents/getDraftsUserId", body, {
    headers: authHeader(),
  });
};

const AuthService = {
  getPayedDocumentsUser,
  createDraftUser,
  register,
  login,
  logout,
  getList,
  getDocumentsByUser,
  postList,
  deleteList,
  getListElement,
  getDeleteElement,
  postNewUsers,
  changeUserPassword,
  EditInfoUser,
  postNewCateg,
  updateCateg,
  createTemplates,
  updateTemplates,
  createDocumentsUser,
  updateDocument,
  createTestDocument,
  updateFirmDocument,
  updateSignersDocument,
};
export default AuthService;
