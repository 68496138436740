import React, { useEffect, useState } from "react";
import Alert from "@material-ui/lab/Alert";
import Collapse from "@material-ui/core/Collapse";
import CloseIcon from "@material-ui/icons/Close";

const AlertMax = (props) => {
  const { visible, code, dataAlert } = props;
  const [, setState] = useState();
  const [alert1, setAlert ] =useState(false);
  useEffect(() => {
    if (!visible) {
      setState(
        "alert fixed-bottom mx-auto col-8 col-md-3 mb-2 text-dark bg-success-lighten-20 d-none"
      );
    } else {
      if (code === 200) {
        setState(
          "alert fixed-bottom mx-auto col-8 col-md-3 mb-2 text-dark bg-success-lighten-20"
        );
      } else if (code === 400) {
        setState(
          "alert fixed-bottom mx-auto col-8 col-md-3 mb-2 text-dark bg-wrong-darken-10"
        );
      } else {
        setState(
          "alert fixed-bottom mx-auto col-8 col-md-3 mb-2 text-dark bg-warn-lighten-10"
        );
      }
    }
  }, [visible]);
  return (
    <Collapse in={alert1}>
      {visible && code === 200 && (
        <Alert
          variant="outlined"
          severity="success"
          action={
            <CloseIcon
              fontSize="inherit"
              onClick={() => {
                setAlert(false);
              }}
            />
          }
        >
          {dataAlert}
        </Alert>
      )}
      {visible && code === 400 && (
        <Alert
          variant="outlined"
          severity="error"
          action={
            <CloseIcon
              fontSize="inherit"
              onClick={() => {
                setAlert(false);
              }}
            />
          }
        >
          {dataAlert}
        </Alert>
      )}
      {visible === false && (
        <footer className="main-footer">
          <strong>
            Copyright &copy; 2020-2021{" "}
            <a href="http://adminlte.io">Yolitigo</a>.
          </strong>
          Todos los derechos reservados.
          <div className="float-right d-none d-sm-inline-block">
            <b>Version</b> 1.0.1
          </div>
        </footer>
      )}
    </Collapse>
  );
};

export default AlertMax;
