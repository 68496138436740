import React, { useRef } from "react";
import { Modal } from "react-bootstrap";
import { Button } from "reactstrap";
import userService from "../../service/user.service";
import { CButtonGroup } from "@coreui/react";

const AttachmentsDoc = ({
  showAttachMod,
  setShowAttachMod,
  files,
  selectDoc,
  setListAttachments,
}) => {
  const close = () => {
    setListAttachments(null);
    setShowAttachMod(!showAttachMod);
  };
  const refDoc = useRef(null)
  const handleDownload = async(docName) => {
    
    // Create a reference from a Google Cloud Storage URI
    try {
      const link = await userService.getDocumentStorageBlob(docName)
      var downloadLink = document.createElement('a')
      downloadLink.href = link
      downloadLink.download  = docName
      downloadLink.target = '_blank'
      refDoc.current.appendChild(downloadLink)
      downloadLink.click()
      refDoc.current.removeChild(downloadLink)
      downloadLink.remove()
    } catch (error) {
      console.error(error)
      
    }
  }
  const handleView = async(docName) => {
    
    // Create a reference from a Google Cloud Storage URI
    try {
      const link = await userService.getDocumentStorageLink(docName)
      var downloadLink = document.createElement('a')
      downloadLink.href = link
      downloadLink.download  = docName
      downloadLink.target = '_blank'
      refDoc.current.appendChild(downloadLink)
      downloadLink.click()
      refDoc.current.removeChild(downloadLink)
      downloadLink.remove()
    } catch (error) {
      console.error(error)
      
    }
  }

  return (
    <Modal
      show={showAttachMod}
      id="ModalAttachments"
      onHide={() => close()}
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>Documentos adjuntos</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div
        ref={refDoc}
          style={{
            fontSize: "20px",
            marginTop: "5px",
            marginBottom: "10px",
          }}
        >
          {`${selectDoc?.name} `}
          <span
            style={{
              color: "#9b9b9b",
            }}
          >{`${selectDoc?.id}`}</span>
        </div>
        {files ? (
          <table className="table table-light table-striped">
            <thead>
              <tr>
                <th>Nombre</th>
                <th>Posición</th>
                <th>Acciones</th>
              </tr>
            </thead>
            <tbody>
              {files.map((file, i) => {
                return (
                  <tr className="attachmentFile" role="button" key={`test_${i}`} >
                    <td>{file.filename}</td>
                    <td>{file?.position || i}</td>
                    <td>
                    <CButtonGroup>
                      <Button
                      onClick={()=>{
                        handleDownload(file.filename)
                      }}
                       className="primary_custom_btn">Descargar</Button>
                      <Button
                      onClick={()=>{
                        handleView(file.filename)
                      }} className="secondary_custom_btn">Ver</Button>
                    </CButtonGroup></td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        ) : (
          <div>No hay archivos adjuntos</div>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button
          type="submit"
          style={{
            backgroundColor: "#cf37cf",
            color: "white",
          }}
          onClick={() => close()}
        >
          Aceptar
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default React.memo(AttachmentsDoc);
