import { CButton, CButtonGroup, CCol, CRow, CTooltip } from "@coreui/react";
import React, { useRef, useState } from "react";
import SignerInput from "./SignerInput";
//import { ReactComponent as CheckSVG } from "../../../assets/svg/circle_check.svg";
import { ReactComponent as PlusSVG } from "../../../assets/svg/plus.svg";
import CheckIMG from "../../../assets/svg/circle_check.png";
import SignPlacement from "./SignPlacement";
import signService from "../../../service/sign.service";
import useToast from "../../../utils/CustomHooks/useToast";
const EmptySigner = Object.freeze({
  name: "",
  email: "",
  auth_mode: "assinaturaTela",
  send_automatic_email: true,
  send_automatic_whatsapp: false,
  order_group: null,
  custom_message: "",
  phone_country: "",
  phone_number: "",
  lock_email: false,
  blank_email: false,
  hide_email: false,
  lock_phone: false,
  blank_phone: false,
  hide_phone: false,
  lock_name: false,
  require_selfie_photo: false,
  require_document_photo: false,
  selfie_validation_type: "liveness-document-match",
  qualification: "",
  external_id: "",
  redirect_link: "",
});
function ZapSignInfo({
  data = {
    sandbox: false,

    external_id: "None",

    open_id: 7,

    token: "434181e1-82e0-4f87-9e81-b485a26e66b8",

    name: "doc con firma",

    folder_path: "/",

    status: "pending",

    rejected_reason: null,

    lang: "es",

    original_file:
      "https://zapsign.s3.amazonaws.com/sandbox/dev/2023/8/pdf/99df93bc-5d18-4f49-b078-61d6d4811c2b/5ae50e46-621a-444d-8d32-2d2939a5a5d4.pdf",

    signed_file: null,

    extra_docs: [],

    created_through: "api",

    deleted: false,

    deleted_at: null,

    signed_file_only_finished: false,

    disable_signer_emails: false,

    brand_logo: "",

    brand_primary_color: "",

    created_at: "2023-08-25T18:57:00.728744Z",

    last_update_at: "2023-08-25T18:57:00.728760Z",

    created_by: { email: "hector58472@gmail.com" },

    template: null,

    signers: [
      {
        external_id: "",

        sign_url:
          "http://sandbox.app.zapsign.com.br/verificar/7f97d049-0e76-4454-b331-d38baef9fb1a",

        token: "7f97d049-0e76-4454-b331-d38baef9fb1a",

        status: "new",

        name: "Angel",

        lock_name: false,

        email: "",

        lock_email: false,

        hide_email: false,

        blank_email: false,

        phone_country: "55",

        phone_number: "",

        lock_phone: false,

        hide_phone: false,

        blank_phone: false,

        times_viewed: 0,

        last_view_at: null,

        signed_at: null,

        auth_mode: "assinaturaTela",

        qualification: "",

        require_selfie_photo: false,

        require_document_photo: false,

        geo_latitude: null,

        geo_longitude: null,

        redirect_link: "",

        signature_image: null,

        visto_image: null,

        document_photo_url: "",

        document_verse_photo_url: "",

        selfie_photo_url: "",

        selfie_photo_url2: "",

        send_via: "email",
      },
    ],

    answers: [],

    auto_reminder: 0,

    signature_report: null,
  },
  onRemoveSign = async () => {},
  onNewSign = async () => {},
  onRefreshData = ()=>{}
}) {
  const [tooltipText, setTooltipText] = useState("Copiar");
  const addToast= useToast();
  const [urlPDF, setUrlPDF] = useState(null);
  const tokenUserSigner = useRef("");
  const [openFormAddSigner, setOpenFormAddSigner] = useState(false);
  const [loading, setLoading] = useState(false);
  const [newSigner, setNewSigner] = useState({ ...EmptySigner });
  const handleRemove = (token,i) => {
    onRemoveSign(token,i);
  };
  const openLink = async (url) => {
    try {
      if (!url) return;
      var downloadLink = document.createElement("a");
      downloadLink.href = url;
      //downloadLink.download = removeAccents(doc.name) + ".zip";
      downloadLink.target = "_blank";

      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
      downloadLink.remove();
    } catch (error) {
      console.error(error);
    }
  };
  const copyToClipboard = (str) => {
    if (navigator && navigator.clipboard && navigator.clipboard.writeText) {
      setTooltipText("Copiado!");
      setTimeout(() => {
        setTooltipText("Copiar");
      }, 1000);
      return navigator.clipboard.writeText(str);
    }
    return Promise.reject("The Clipboard API is not available.");
  };
  const handleSaveNewSigner = async () => {
    setLoading(true);
    const created = await onNewSign({ ...newSigner });
    if (created) {
      setNewSigner({ ...EmptySigner });
      setOpenFormAddSigner(false);
    }
    setLoading(false);
  };
  const handleSaveSignatures= async (signatures)=>{
    try {
      await signService.placeSignatures(data.token,tokenUserSigner.current,signatures)
      addToast({
        title:'Firmas Posicionadas',
        mode:'primary'
      })
      tokenUserSigner.current = ''
      setUrlPDF(null)
      onRefreshData()
    } catch (error) {
      
    }
  }
  return (
    <>
      <div className="zapsign_info_container">
        <CRow>
          <CCol xs={"auto"} className="">
            <div
              style={{
                width: "40px",
                transform: data.status === "pending" ? "rotate(45deg)" : "",
              }}
              className="h-100 d-flex justify-content-center align-items-center"
            >
              {
                //<CheckSVG />
                data.status === "pending" ? (
                  <PlusSVG />
                ) : (
                  <img src={CheckIMG} alt="" width={"100%"}></img>
                )
              }
            </div>
          </CCol>
          <CCol className="form_sign_header pl-0">
            <h5>
              {data.status === "pending"
                ? "Documento a la espera de firma"
                : "Documento Firmado"}
            </h5>
            <p className="subtitle">
              {data.status === "pending"
                ? "Ya el documento fue creado y esta a la espera de ser firmado, envíales el link a tus firmantes para que firmen"
                : "El documento Firmado esta disponible para descargar"}
            </p>
          </CCol>
          <CCol
            className="mb-1 d-flex justify-content-start align-items-center mb-3 mt-3"
            xs="12"
          >
            <CButtonGroup role="group" aria-label="Basic example">
              <CButton
                onClick={() => {
                  openLink(data.original_file);
                }}
                type="button"
                color=""
                className="btn_firm_alt m-0  px-3 py-2"
              >
                Documento Sin firmar
              </CButton>
              {data.status === "pending" ? (
                <CButton
                  color=""
                  disabled={loading}
                  className="btn_firm px-3 py-2"
                  type="button"
                  onClick={() => {
                    setOpenFormAddSigner((prev) => !prev);
                  }}
                >
                  {!openFormAddSigner ? (
                    <>
                      <i className="fas fa-plus-circle"></i> Nuevo firmante
                    </>
                  ) : (
                    "Cancelar"
                  )}
                </CButton>
              ) : (
                ""
              )}
              {data.signed_file ? (
                <CButton
                  onClick={() => {
                    openLink(data.signed_file);
                  }}
                  type="button"
                  color=""
                  className="btn_firm_alt px-3 py-2"
                >
                  Documento Firmado
                </CButton>
              ) : (
                ""
              )}
            </CButtonGroup>
          </CCol>
          <CCol xs="12">
            {openFormAddSigner ? (
              <SignerInput
                advanced
                disabled={loading}
                id={`signer_extra`}
                values={newSigner}
                onChange={(values) => {
                  setNewSigner((prev) => ({ ...prev, ...values }));
                }}
                onSave={
                  data.status === "pending" ? handleSaveNewSigner : undefined
                }
              />
            ) : (
              ""
            )}
          </CCol>
        </CRow>

        {data.signers.map((sign,i) => {
          return (
            <div className="signer_container mb-2" key={sign.token}>
              <CRow>
                <CCol
                  className="d-flex justify-content-center align-items-center"
                  xs="auto"
                >
                  <p className="h6">{sign.name || "Sin nombre"}</p>
                </CCol>
                <CCol
                  className="d-flex justify-content-center align-items-center"
                  xs="auto"
                >
                  <p className="subtitle">{sign.email}</p>
                </CCol>
                <CCol>
                  <CRow>
                    {sign.status === "signed" ? (
                      <>
                        <CCol>
                          <CButtonGroup>
                            <button className="btn btn_firm" type="button" disabled>
                              <i className="far fa-check-circle"></i> Firmado
                            </button>
                            <CButton
                              onClick={() => {
                                tokenUserSigner.current = sign.token;
                                setUrlPDF(data.signed_file);
                              }}
                              type="button"
                              color=""
                              className="btn_firm_alt px-3 py-2"
                            >
                              Posicionar firma
                            </CButton>
                          </CButtonGroup>
                        </CCol>
                      </>
                    ) : (
                      <>
                        <CCol>
                          {data.signers.length > 1 ? (
                            <button
                              disabled={loading}
                              className="btn_firm"
                              type="button"
                              onClick={() => {
                                handleRemove(sign.token, i);
                              }}
                            >
                              <i className="fas fa-minus-circle"></i> Quitar
                            </button>
                          ) : (
                            ""
                          )}
                        </CCol>
                      </>
                    )}
                  </CRow>
                </CCol>
                <CCol className="mt-2" xs="12">
                  <div className="url_container">
                    <a href={sign.sign_url} target="_blank" rel="noreferrer"   className="w-100">{sign.sign_url}</a>
                    <CTooltip
                      content={tooltipText}
                      trigger={["hover", "focus"]}
                    >
                      <button
                        type="button"
                        onClick={() => {
                          copyToClipboard(sign.sign_url);
                        }}
                      >
                        <i className="far fa-copy"></i>
                      </button>
                    </CTooltip>
                  </div>
                </CCol>
              </CRow>
            </div>
          );
        })}
      </div>
      <SignPlacement
        urlPDF={urlPDF}
        setUrlPDF={setUrlPDF}
        onSaveSignatures={handleSaveSignatures}
      ></SignPlacement>
    </>
  );
}

export default ZapSignInfo;
