import React, { useEffect, useState } from "react";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import ReactHTMLTableToExcel from "react-html-table-to-excel";

import UserService from "../service/user.service";
// import { useDispatch } from "react-redux";
// import { logout } from "../actions/auth";

import "../assets/styles/components/TableUser.scss";
import moment from "moment/moment";
import signService from "../service/sign.service";

const ExternalSignsPurchaseHistories = () => {
  const [, setLoader] = useState(true);
  const [state, setState] = useState([]);
  // const [transfor, setTransform] = useState(false);
  // const [infoCatg, setCatg] = useState({
  //   name: "",
  //   description: "",
  //   icon_path: "",
  // });
  const [dateStart, setDateStart] = useState(
    moment().subtract(1, "month").format("YYYY-MM-DD")
  );
  const [dateEnd, setDateEnd] = useState(moment().format("YYYY-MM-DD"));
  
  const [refreshData] = useState(false);

  useEffect(() => {
    setLoader(true);
    getPurchase();
  }, [refreshData]);


  // const onChangeDate = (date, funSetState) => {
  //   funSetState(date.target.value);
  //   // funSetState(date.target.value);
  // };

  const getPurchase = async () => {
    setLoader(true);
    
    try {
      const userHistory = await signService.requestAllSignDocuments();
      
      setState(userHistory);
      
    } catch (error) {
      setState([]);
      
      console.error(error);
    }
    setLoader(false);
  };

  return (
    <div className="show_detail">
      <div className="table-responsive">
        <div className="header__field__purchase">
          <div className="image__list">
            <i className="fas fa-angle-double-up"></i>
          </div>
          <div className="text__information">
            <h4>Historial de compras</h4>
          </div>
        </div>

        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
        >
          {/* <form className="pl-3" noValidate>
            <TextField
              id="dateStart"
              onChange={(date) => onChangeDate(date, setDateStart)}
              value={dateStart}
              label="Fecha de inicio"
              type="date"
              className="pr-5 pb-3"
              InputLabelProps={{
                shrink: true,
              }}
            />
            <TextField
              id="dateEnd"
              onChange={(date) => onChangeDate(date, setDateEnd)}
              label="Fecha de fin"
              value={dateEnd}
              type="date"
              className="pr-5 pb-3"
              InputLabelProps={{
                shrink: true,
              }}
            />
          </form>
          <button
            className={"btn-shadow btn btn-dark"}
            onClick={() => getPurchase()}
          >
            <i className="fas fa-search"></i>
          </button> */}
          <ReactHTMLTableToExcel
            id="test-table-xls-button"
            className="btn-success p-2"
            table="tablePurchaseBuy"
            filename="historialdecompras"
            sheet="tablexls"
            buttonText="Generar excel"
          />
        </Grid>

        <table
          className="table table-light table-striped"
          id="tablePurchaseBuy"
        >
          <thead id="dataTableHead">
            <tr className="head-table">
              <th scope="col">id</th>
              <th scope="col">Fecha de compra</th>
              <th scope="col">Documento</th>
              <th scope="col">Precio</th>
              <th scope="col">Estado</th>
              <th scope="col">Link del documento</th>
              <th scope="col">User id</th>

              <th
               //className="d-none" 
               scope="col">
                Nombre de usuario
              </th>
              <th
               //className="d-none" 
               scope="col">
                Correo
              </th>
              <th
               //className="d-none" 
               scope="col">
                Teléfono
              </th>
              <th
               //className="d-none" 
               scope="col">
                Tipo de identificación
              </th>
              <th
               //className="d-none" 
               scope="col">
                Numero de identificación
              </th>
            </tr>
          </thead>
          <tbody className="">
            {state ? (
              state.length > 0 ? (
                state.map((data, i) => {
                  return (
                    <tr key={i}>
                      <td>{data.id}</td>
                      <td>{data.created_at}</td>
                      <td>{data.name}</td>
                      <td>{data.price}</td>
                      <td>Pagado</td>
                      <td>{data.original_file}</td>
                      <td>{data?.user_regular_id}</td>

                      <td 
                      //className="d-none"
                      >
                        {data?.payment_data?.payer.first_name ?? data?.user?.name} 
                        {data?.payment_data?.payer.last_name}
                      </td>
                      <td 
                      //className="d-none"
                      >
                        {data?.payment_data?.payer?.email ?? data?.user?.email}
                      </td>
                      <td 
                      //className="d-none"
                      >
                        {
                          data?.payment_data?.payer?.phone ? 
                          `${data?.payment_data?.payer?.phone?.area_code}-${data.payment_data?.payer?.phone?.extension}-${data.payment_data?.payer?.phone?.number}`:
                          (data?.user?.phone ?? data?.user?.person?.phone)
                        }
                      </td>
                      <td 
                      //className="d-none"
                      >
                      {
                        data?.payment_data?.payer?.identification?.type??
                        (data?.user?.person?.type_id )
                      }
                      </td>
                      <td 
                      //className="d-none"
                      >
                      {
                        data?.payment_data?.payer?.identification?.number??
                        (data?.user?.person?.cedula )
                      }
                      </td>
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td colSpan={'100%'}>No hay documentos diligenciados aun</td>
                </tr>
              )
            ) : (
              <div className="loader-page"></div>
            )}
          </tbody>
        </table>
        {/* <CPagination
          align="center"
          addListClass="some-class"
          activePage={currentPage}
          pages={totalPage}
          onActivePageChange={handleChangesPage}
        /> */}
        <br></br>
      </div>
    </div>
  );
};

export default ExternalSignsPurchaseHistories;
