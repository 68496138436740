/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Link, withRouter } from "react-router-dom";

import "../assets/styles/components/PaymentMethod.scss";
import Download from "../assets/static/download.png";
import Editar from "../assets/static/editar.png";
import Asesor from "../assets/static/asesor.png";
import UserService from "../service/user.service";
import AuthService from "../service/auth.service";

import { useRef } from "react";
import LogoCategorie from "../assets/static/logos-categorias-02.png";
import ModalStepperForm from "./modals/StepperFormDocument/ModalStepperform.js";
//import { useSelector } from "react-redux";
import PayedDocuments from "./modals/PayedDocuments";
import { removeAccents } from "../utils/TextUtils";
import FirmDocumentModal from "./modals/FirmDocumentModal";

// import { JSDOM } from 'jsdom';

const PaymentMethod = (props) => {
  /*
  const doc =
    localStorage.getItem("doc") === null
      ? { doc: { template_id: null } }
      : JSON.parse(localStorage.getItem("doc"));
      */
  const doc_id =
    localStorage.getItem("doc_id") === null
      ? { template_id: "" }
      : JSON.parse(localStorage.getItem("doc_id"));

  let query = new URLSearchParams(props.location.search);
  //const { id: user_id } = useSelector((state) => state?.auth?.user);
  const [doc, setDoc] = useState({ doc: { template_id: null } });

  const [modalShow, setModalShow] = useState(false);
  const [firmModalShow, setFirmModalShow] = useState(false);
  const [payedModalShow, setPayedModalShow] = useState(false);
  const [payedDocument, setPayedDocument] = useState(undefined);
  const [data, setData] = useState({});
  const [rawDoc, setRawDoc] = useState({});
  const [inputs, setInput] = useState([]);
  const [fieldsTop, setFieldsTop] = useState([]);

  //const [modalPayment, setModalPayment] = useState();
  const dataPerson = useRef(null);

  useEffect(() => {
    if (props?.location?.state?.doc) {
      setDoc(props.location.state.doc);
      setRawDoc(props.location.state.doc.document);
    } else if (localStorage.getItem("doc")) {
      setDoc(JSON.parse(localStorage.getItem("doc")));
      setRawDoc(JSON.parse(localStorage.getItem("doc")).document);
    }

    return () => {};
  }, []);

  useEffect(() => {
    const requestPayed = async () => {
      try {
        const orderPayed = query.get("order_id");
        const payed = await AuthService.getPayedDocumentsUser({
          orderID: orderPayed,
        });
        const valid_states = ["pagado", "necesita_firma"];
        if (valid_states.includes(payed?.state)) setPayedDocument(payed);
      } catch (error) {
        console.error(error);
      }
    };
    requestPayed();
    return () => {};
  }, []);

  /*
  useEffect(() => {
    if (localStorage.getItem("order_id") !== null && status !== "approved") {
      userService.getUserBoard().then((response) => {
        dataPerson.current = response.data;

        userService
          .putPurchaseUpdate(localStorage.getItem("order_id"), {
            email: dataPerson.current.email,
            user_regular_id: dataPerson.current.id,
            purchase_date: formDate(),
            value: doc.price,
            state: "pendiente",
            name: doc.name,
            user: dataPerson.current,
          })
          .then((response) => {
            localStorage.removeItem("order_id");
          })
          .catch((error) => console.error(error ));
      });
    }
  }, [modalPayment]);
*/

  const handleModal = (document) => {
    let new_dict = [];
    let arrayData = [];
    localStorage.removeItem(`attach_${doc_id.id}`);

    for (const property in document.lists) {
      new_dict.push({ name: property, list: document.lists[property] });
    }
    setInput(new_dict);

    for (const key_data in document.vars) {
      arrayData.push({ type_data: document.vars[key_data], msg: key_data });
    }

    setFieldsTop(arrayData);
    setData(document);
    //setRawDoc(document.document)
    setModalShow(true);
  };
  /*
  const handleSubmit = (e, data) => {
    setPayedModalShow(true);
  };
  */

  const handleDownload = () => {
    if(payedDocument.state === 'pagado') {
      downloadDocument()
    } else {
      setFirmModalShow(true)
    }
  }
  const downloadDocument = async () => {
    try {
      if (!payedDocument.document_number) return;
      const link = await UserService.getDocumentAndAttachmentsBlobUrl(
        payedDocument.firebase_document_id
      );
      var downloadLink = document.createElement("a");
      downloadLink.href = link;
      downloadLink.download = removeAccents(doc.name) + ".zip";
      downloadLink.target = "_blank";
      
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
      downloadLink.remove();
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <main className="main__payment">
      <section className="d-flex justify-content-center">
        <figure className="title__documentos">
          <img src={LogoCategorie} alt="" />

          {doc ? (
            <div className="names__documentos">
              <h3>{doc.name}</h3>
              <hr></hr>
              <span>{"Sin Descripción"}</span>
            </div>
          ) : (
            <div className="names__documentos">
              <h5>Categoría no seleccionada</h5>
              <span>
                <Link to="/panel">Regresar</Link>
              </span>
            </div>
          )}
        </figure>
      </section>
      <section className="d-flex justify-content-center">
        <div className="selection__pay">
          {payedDocument ? (
            <div onClick={handleDownload /*handleSubmit */}>
              <figure className="selection__value download-img">
                <img src={Download} alt="" />
                <h5>{payedDocument.state === 'pagado' ? 'Descargar Documento' : 'Proceder a firmar'}</h5>
              </figure>
            </div>
          ) : (
            <figure className="selection__value">
              <img src={Download} alt="" />

              <h5>No disponible la Descarga</h5>
            </figure>
          )}
          <figure className="selection__value">
            {doc ? (
              <>
                <img
                  src={Editar}
                  alt=""
                  onClick={() => {
                    handleModal(doc);
                  }}
                />
                <h5 onClick={() => handleModal(doc)}>Diligenciar documento </h5>
              </>
            ) : (
              <>
                <img src={Editar} alt="" />
                <h5>Selecciona documento para diligenciar</h5>
              </>
            )}
          </figure>
          <figure className="selection__value">
            <img src={Asesor} alt="" />
            <h5>Ayuda en linea</h5>
          </figure>
        </div>
      </section>
      {/*

      {modalShow && (
        <ModalMethod
          topRight={true}
          modalPayment={modalPayment}
          setModalPayment={setModalPayment}
          //setFiles={setFiles}
          rawDoc={rawDoc}
          data={data}
          setData={setData}
          setModalShow={setModalShow}
          title={doc.name}
          dataPdf={dataPdf}
          setDataPdf={setDataPdf}
          inputs={inputs}
          fieldsTop={fieldsTop}
          doc={doc}
          dataPerson={dataPerson}
        />
      )}
  documentDta = {},
  showHook = [],
  dataHook = [],

  rawDoc,
  fieldsTop,
  inputs,
        */}
        <ModalStepperForm
          documentDta={doc}
          showHook={[modalShow, setModalShow]}
          dataHook={[data, setData]}
          rawDoc={rawDoc}
          fieldsTop={fieldsTop}
          inputs={inputs}
          personDta={dataPerson}
        />
      <PayedDocuments
        name={doc.name}
        showHook={[payedModalShow, setPayedModalShow]}
        documents={payedDocument ? [payedDocument] : []}
      />
      {payedDocument ? <FirmDocumentModal
        showHook={[firmModalShow, setFirmModalShow]}
        dataHook={[payedDocument, setPayedDocument]}
      /> : ''}
    </main>
  );
};

export default React.memo(withRouter(PaymentMethod), () => false);
