import React from "react";

import { Button, FormGroup, Label } from "reactstrap";
import { Form, Modal } from "react-bootstrap";

const NewCatg = (props) => {
  const { showNewMod, ModNewCateSate, handleSubmit, handleChange } = props;

  return (
    <Modal show={showNewMod} id="ModalAdd" onHide={ModNewCateSate}>
      <Modal.Header closeButton>
        <Modal.Title>Crear Categoria</Modal.Title>
      </Modal.Header>
      <Form role="form" onSubmit={handleSubmit}>
        <Modal.Body>
          <FormGroup className="fields__new">
            <Label>Nombre de categoria</Label>
            <Form.Control
              type="text"
              name="name"
              required
              onChange={handleChange}
            ></Form.Control>
          </FormGroup>
          <FormGroup className="fields__new">
            <Label>Descripción de categoria</Label>
            <Form.Control
              as="textarea"
              name="description"
              rows="2"
              onChange={handleChange}
            ></Form.Control>
          </FormGroup>
          <FormGroup className="fields__new">
            <Label>Imagen de categoria</Label>
            <Form.Control
              type="text"
              name="icon_path"
              required
              onChange={handleChange}
            ></Form.Control>
          </FormGroup>
        </Modal.Body>
        <Modal.Footer>
          <Button color="danger" onClick={ModNewCateSate}>
            Close
          </Button>
          <Button type="submit" color="success">
            Save Changes
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default NewCatg;
