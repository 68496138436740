import axios from "axios";
import authHeader from "./auth-header";

const API_URL = process.env.REACT_APP_API_URL;
const tagService = {}

// const user = JSON.parse(localStorage.getItem("user"));

tagService.getTags = () => {
  return axios.get(API_URL + "tags", { headers: authHeader() });
};
tagService.createTag = (name) => {
  return axios.post(API_URL + "tags",{name}, { headers: authHeader() });
};
tagService.updateTag = (id, name) => {
  return axios.post(API_URL + "tags/"+id ,{name}, { headers: authHeader() });
};
tagService.deleteTag = (id,) => {
  return axios.post(API_URL + "tags" + id, { headers: authHeader() });
};

export default tagService;
