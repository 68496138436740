import React from "react";
import "../assets/styles/components/DerechoPeticion.scss";
import "../assets/styles/components/ModalDocument.scss";

const DocumentEdit = (props) => {
  // const { setTopRight, setFieldsTop, document, fieldsTop, inputs } = props;
  const { document, fieldsTop, inputs } = props;
  // const [name, setName] = useState(false);
  // const [data, setData] = useState({
  //   date: "",
  // });
  let _dataToConvert = document;
  if (document.pagado) {
    let _arrayWithoutBlur = document.document.replaceAll(
      'class= "blurFilterLitigo"',
      ""
    );
    _dataToConvert.document = _arrayWithoutBlur;
  } else {
    if (!document.document.includes('<p class= "blurFilterLitigo"')) {
      let _arrayBlur = document.document.split("<p");
      let _documentBlur = "";
      let _blur = '<p class= "blurFilterLitigo"';
      let _withoutBlur = "<p";

      _arrayBlur.forEach((itemToBlur, index) => {
        let itHasVar = false;
        fieldsTop.forEach(function (field, ind) {
          let msg = field.msg.replace(/['"]+/g, "");
          if (msg.includes(" ")) {
            msg = msg.replaceAll(" ", "_");
          }
          if (itemToBlur.includes("|" + msg + "|")) {
            itHasVar = true;
          }
        });
        inputs.forEach(function (list, i) {
          if (itemToBlur.includes("~" + list.name + "~")) {
            itHasVar = true;
          }
        });
        if (itHasVar) {
          if (_documentBlur === "") {
            _documentBlur = itemToBlur;
          } else {
            _documentBlur = _documentBlur + _withoutBlur + itemToBlur;
          }
        } else {
          if (_documentBlur === "") {
            _documentBlur = itemToBlur;
          } else {
            _documentBlur = _documentBlur + _blur + itemToBlur;
          }
        }
      });

      // for( const property of  _dataToConvert.vars){
        
      //   _documentBlur.replaceAll(property, property.replaceAll("_", " "))
      // }
      

      _dataToConvert.document = _documentBlur;
    }
  }

  // const handleChange = (event, varsUse = {}) => {
  //   setTopRight(true);
  //   let arrayData = [];
  //   for (const key_data in varsUse) {
  //     arrayData.push({ type_data: varsUse[key_data], msg: key_data });
  //   }

  //   setFieldsTop(arrayData);
  //   setData({
  //     ...data,
  //     [event.target.name]: event.target.value,
  //   });
  //   setName(true);
  // };
  return (
    <div
      className="row document__peticion noselect"
      style={{ margin: 0 }}
      onCopy={(e) => {
        e.preventDefault();
        return false;
      }}
    >
      {document ? (
        <div
          dangerouslySetInnerHTML={{
            __html: "<p ".concat(
              _dataToConvert.document
                .replaceAll("width: 896px;", "width: 100%;")
                .replaceAll('style="', 'style="overflow-wrap: anywhere;')
            ),
          }}
          // onClick={(event) => handleChange(event, document.vars)}
        ></div>
      ) : (
        <h3>Espera un momento!</h3>
      )}
    </div>
  );
};

export default DocumentEdit;
