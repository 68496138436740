/**
 * returns a filtered list of elements
 * @param {Array} data list of elements that have the propiety name
 * @param {String} query the word that going
 * @returns 
 */
export function searchElementByName(data,query) {
    return data.filter((doc) => {
        return doc.name.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase().includes(query.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase());
      })
}