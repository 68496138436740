

 const regeTest = {
    caps: new RegExp('(?=.*[A-Z])'),
    small : new RegExp('(?=.*[a-z])'),
    length :new RegExp('(?=.{8,})'),
    special: new RegExp('(?=.*[^A-Za-z0-9])'),
    numbers: new RegExp('(?=.*[0-9])'),
 }
/**
 * 
 * @param {String} password string to test
 * @returns Number (0,1) a nunber between 0 and 1 
 */
const strongPaswordTester = (password) => {
  let strengthIndicator = 0;
  for(const [,reg] of Object.entries(regeTest)){
    const test = reg.test(password)
    if (test) {
        strengthIndicator++;
      }
  }
  if(password.length>40){
    strengthIndicator=0
  }
  /*for (let index = 0; index < password.length; index++) {
    let char = password.charCodeAt(index);
    if (!strengthValue.caps && char >= 65 && char <= 90) {
      strengthValue.caps = true;
    } else if (!strengthValue.numbers && char >= 48 && char <= 57) {
      strengthValue.numbers = true;
    } else if (!strengthValue.small && char >= 97 && char <= 122) {
      strengthValue.small = true;
    } else if (!strengthValue.numbers && char >= 48 && char <= 57) {
      strengthValue.numbers = true;
    } else if (
      (!strengthValue.special && char >= 33 && char <= 47) ||
      (char >= 58 && char <= 64)
    ) {
      strengthValue.special = true;
    }
  }
  */
  return strengthIndicator/Object.keys(regeTest).length
};

export default strongPaswordTester