import { CToast, CToastBody, CToastClose, CToastHeader, CToaster } from "@coreui/react";
import moment from "moment";
import React, { createContext, useCallback, useRef, useState } from "react";
import { useEffect } from "react";

const toastContext = createContext();

export default toastContext;

export function ToastContextProvider({ children }) {
  const [toast, setToast] = useState(null);
  const toaster = useRef(null)
  /*
  useEffect(() => {
    let timer;
    if (toast.length > 0) {
      timer = setTimeout(
        () => setToast((prevToast) => prevToast.slice(1)),
        5000
      );
    }
    return () => {
      timer && clearTimeout(timer);
    };
  }, [toast]);
  */
  const addToast = useCallback(
    function ({
      title = "toast",
      subtitle = "",
      icon = <i className="far fa-bell me-2"></i>,
      body = "",
      autohide = true,
      mode='full', //'simple'
      jsx
    }) {
      let toastJsx 
      if(mode==='full') {toastJsx = (
        <CToast
          id={`toast-card-id-${moment().unix() + 1}`}
          //key={`toast-card-${moment().unix() + 1}`}
          autohide={true} visible={true}>
          <CToastHeader closeButton>
            {icon}
            <div className="fw-bold me-auto">{title}</div>
            <small>{subtitle}</small>
          </CToastHeader>
          <CToastBody>{body}</CToastBody>
        </CToast>
      )} 
      else if(jsx) {toastJsx = jsx}
      else { toastJsx = (<CToast autohide={autohide} visible={true} color={mode} className="text-white align-items-center">
        <div className="d-flex">
          <CToastBody className="d-flex color-white">
            {icon}
            <div className="fw-bold me-auto">{title}</div>
            <small>{subtitle}</small>
          </CToastBody>
          <CToastClose className="me-2 m-auto" white />
        </div>
      </CToast>)}
      //setToast((prevToast) => [...prevToast, toastJsx]);
      setToast((prevToast) => toastJsx);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setToast]
  );
  return (
    <toastContext.Provider value={addToast}>
      {children}

      <CToaster ref={toaster} push={toast} placement="top-end" />
    </toastContext.Provider>
  );
}
