import { CCol, CRow } from "@coreui/react";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { ReactComponent as CalendarSVG } from "../../assets/svg/calendar.svg";

function InputDateCustom({
  value = "DD-MM-YYYY",
  format = "DD-MM-YYYY",
  onChange = () => {},
  name,
}) {
  const [dayData, setDayData] = useState({
    DD: "",
    MM: "",
    YYYY: "",
  });
  useEffect(() => {
    let [DD, MM, YYYY] = value.split("-");
    
    DD = DD.slice(0,2)
    MM = MM.slice(0,2)
    YYYY = YYYY.slice(0,4)
    setDayData({ DD, MM, YYYY });
    return () => {};
  }, [value]);

  const handleChange = (e) => {
    let { name:nameInput, value } = e.target;
    const dayDataCopy = { ...dayData };
    value = parseInt(value);
    if(nameInput==='DD' && value>31) value= 31
    if(nameInput==='MM' && value>12) value= 12
    value = `${value}`;
    if (value.length === 1) value = `0${value}`;
    dayDataCopy[nameInput] = value;
    
    onChange({
      target: {
        value: `${dayDataCopy.DD}-${dayDataCopy.MM}-${dayDataCopy.YYYY}`,
        name: name,
      },
    });
  };
  const handleSelectDate=(e)=>{
    let {value} = e.target
    value = moment(value, "YYYY-MM-DD").format(format)
    const [DD, MM, YYYY] = value.split("-");
    onChange({
      target: {
        value: `${DD}-${MM}-${YYYY}`,
        name: name,
      },
    });
  }
  return (
    <CRow className="input_date_custom_container">
      <CCol xs="3" className="input_date">
        <input
          name={"DD"}
          value={dayData.DD}
          type="number"
          onChange={handleChange}
          min={0}
          max={31}
          placeholder="DD"
        ></input>
      </CCol>
      <CCol xs="3" className="input_date">
        <input
          name={"MM"}
          value={dayData.MM}
          type="number"
          onChange={handleChange}
          min={0}
          max={12}
          placeholder="MM"
        ></input>
      </CCol>
      <CCol xs="3" className="input_date">
        <input
          name={"YYYY"}
          value={dayData.YYYY}
          type="number"
          onChange={handleChange}
          min={1900}
          max={3000}
          placeholder="AAAA"
        ></input>
      </CCol>
      <CCol xs="auto">
        <div className="date_selector_custom d-flex justify-content-center align-items-center">
          <CalendarSVG />
          <input
            onChange={handleSelectDate}
            value={moment(value, format).format("YYYY-MM-DD")}
            type="date"
          ></input>
        </div>
      </CCol>
    </CRow>
  );
}

export default InputDateCustom;
