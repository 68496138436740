import React from "react";
import { Button } from "reactstrap";
import { Modal } from "react-bootstrap";

import UserService from "../../service/user.service";
import { useSelector } from "react-redux";
const DeleteDoc = (props) => {
  const {
    showDelMod,
    setshowDelMod,
    message,
    setrefreshData,
    refreshData,
    ModAlertMod,
    setModalData,
    // idInfo,
    data,
  } = props;
  const ModDelCateSate = () => setshowDelMod(!showDelMod);
  const { id: user_id } = useSelector((state) => state?.auth?.user);

  const handleDelete = async (e) => {
    e.preventDefault();
    
    try {
      const response = await UserService.deleteDocumentUser(data,user_id)
      
      
      if (response.status === 200) {
        setModalData({
          title: "Excelente",
          body: response.data.message,
          border: "bg-success",
        });
        ModDelCateSate();
        ModAlertMod();
      }
    } catch (error) {
      
      
      console.error(error.response);
      setModalData({
        title: "Lo sentimos",
        body: "No se pudo eliminar la plantilla.\n" + error.response,
        border: "bg-danger",
      });
      ModAlertMod();
    }
    
    ModDelCateSate();
    ModAlertMod();
    setrefreshData(!refreshData);
  };
  return (
    <Modal show={showDelMod} id="ModalDelete" onHide={ModDelCateSate}>
      <Modal.Header closeButton>
        <Modal.Title>{data.name}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{message}</Modal.Body>
      <Modal.Footer>
        <Button color="secundary" onClick={ModDelCateSate}>
          Cancelar
        </Button>
        <Button
          type="submit"
          color="danger"
          id="btn_dele_move_move"
          onClick={(e) => handleDelete(e)}
        >
          Borrar
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default DeleteDoc;
