import axios from "axios";
import authHeader from "./auth-header";
import mercadoPagoOrdersApi from "../utils/mercadoPagoOrdersApi";
import { realTimeDB, storage } from "../utils/firebase.config";
import { getDownloadURL, getBlob, deleteObject, ref } from "firebase/storage";
import {ref as dbRef, child, get, remove  } from "firebase/database";
import JSZip from "jszip";
import Base64 from "../utils/Base64";
import blobToBase64 from "../utils/blobToBase64";

const API_URL = process.env.REACT_APP_API_URL;

// const user = JSON.parse(localStorage.getItem("user"));

const getPublicContent = () => {
  return axios.get(API_URL + "all", { headers: authHeader() });
};

const getUserBoard = () => {
  return axios.get(API_URL + "user", { headers: authHeader() });
};

const getCetegorias = (typedata, page) => {
  return axios.get(API_URL + `categories?${typedata}=${page}`, {
    headers: authHeader(),
  });
};
const GetPayment = (id) => {
  const verifyGift = id.includes("gift_");
  const coded= Base64.encode(`${id}`)
  
  let url = `/payment/${coded}`
  if(verifyGift) url+='/gift'
  return mercadoPagoOrdersApi.get(url);
  // return axios.get(API_URL + `categories?${typedata}=${page}`, { headers: authHeader() });
};
const GetOrder = (id) => {
  const coded= Base64.encode(`${id}`)
  let url = `/orders/${coded}`
  return mercadoPagoOrdersApi.get(url);
  // return axios.get(API_URL + `categories?${typedata}=${page}`, { headers: authHeader() });
};
const postPurchaseCreate = (data) => {
  return mercadoPagoOrdersApi.post('/payment',data);
  // return axios.get(API_URL + `categories?${typedata}=${page}`, { headers: authHeader() });
};
const putPurchaseUpdate = (id, data) => {
  return axios.put(API_URL + `orders/${id}`, data, { headers: authHeader() });
  // return axios.get(API_URL + `categories?${typedata}=${page}`, { headers: authHeader() });
};

const getPurchaseHistory = (from, to) => {
  return axios.post(
    API_URL + `orders/userdate`, // `orders/regulardate`,
    { from: new Date(from), to: new Date(to) },
    { headers: authHeader() }
  );
  // return axios.get(API_URL + `categories?${typedata}=${page}`, { headers: authHeader() });
};

const getUsersAdminBoard = (number__page) => {
  return axios.get(API_URL + `userAdmins?page=${number__page}`, {
    headers: authHeader(),
  });
};
const getUserSuperAdminBoard = (number__page) => {
  return axios.get(API_URL + `userAdmins/list?page=${number__page}`, {
    headers: authHeader(),
  });
};

const getUsersRegBoard = (number__page) => {
  return axios.get(API_URL + `users?page=${number__page}`, {
    headers: authHeader(),
  });
};
const deleteUser = (id) => {
  return axios.delete(API_URL + `userAdmins/${id}`, { headers: authHeader() });
};
const updatePasswordUser = (actual, new_password, confirm) => {
  return axios.post(
    API_URL + `user/updatePassword`,
    {
      old_password: actual,
      password: new_password,
      password_confirmation: confirm,
    },
    { headers: authHeader() }
  );
};

const getDataCategories = (id, tags=[]) => {
  return axios.get(API_URL + `templates/category/${id}` , {
    headers: authHeader(),
  });
};

const deleteCategory = (id) => {
  return axios.delete(API_URL + `categories/${id}`, { headers: authHeader() });
};

const rolesUser = (id, data) => {
  const datatoSend = {
    name: data.name,
    email: data.email,
    active: data.active==='true' ? 1 : 0,
    roles: data.roles,
  };
  return axios.post(API_URL + `userAdmins/${id}/assignRole`, datatoSend, {
    headers: authHeader(),
  });
};
const updateUser = async (id, data) => {
  
  const datatoSend = {
    name: data.name,
    email: data.email,
    active: data.active==='true',
  };
  const response = await axios.put(API_URL + `users/${id}/`, datatoSend, {
    headers: authHeader(),
  })
  const tags = data?.tags.map(tag => tag.id) ?? []
  const responseTags  =  await axios.put(API_URL + `users/${id}/tags`, {tags}, {
    headers: authHeader(),
  })
  
  return responseTags; 
};

const validationDocument = (document) => {
  return axios.post(
    API_URL + `templates/validate`,
    { document: document },
    { headers: authHeader() }
  );
};

const getAllTemplate = () => {
  
  return axios.get(API_URL + "templates", { headers: authHeader() });
};
const postPublicTemplate = (id_template) => {
  return axios.post(
    API_URL + `templates/publish/${id_template}`,
    {},
    { headers: authHeader() }
  );
};

const postDesPublicTemplate = (id_template) => {
  return axios.post(
    API_URL + `templates/withdraw/${id_template}`,
    {},
    { headers: authHeader() }
  );
};

const deleteTemplate = (id) => {
  return axios.delete(API_URL + `templates/${id}`, { headers: authHeader() });
};

const showTemplate = (id) => {
  return axios.get(API_URL + `templates/${id}`, { headers: authHeader() });
};

const editDocuments = (
  id,
  name,
  doc,
  prices,
  supervice,
  porcent,
  price_super,
  categories,
  vars,
  list,
  iva
) => {
  return axios.post(
    API_URL + `templates/${id}`,
    {
      name: name,
      document: doc,
      price: prices,
      supervise: supervice,
      percentage_check: porcent,
      supervise_price: price_super,
      categorias: categories,
      vars: vars,
      lists: list,
      iva: iva,
    },
    { headers: authHeader() }
  );
};

const getAllCategorias = () => {
  return axios.get(API_URL + `categories/all`, { headers: authHeader() });
};

const getAllDocumentos = () => {
  return axios.get(API_URL + `documents`, { headers: authHeader() });
};

const getUserDocument = (id) => {
  return axios.get(API_URL + `documents/user/${id}`, { headers: authHeader() });
};

const updateDocuments = (body) => {
  return axios.put(API_URL + `documents`, body, { headers: authHeader() });
};
/*
const deleteDocumento = (id) => {
  return axios.delete(API_URL + `documents/deleteDocument`, {
    headers: authHeader(),
    data: {
      draft_id: id,
    },
  });
};
*/

const createDocumento = (id) => {
  return axios.post(
    API_URL + `documents/create`,
    { id: id },
    { headers: authHeader() }
  );
};

const resetPassword = (type, email) => {
  const url = !type ? "user/resetPassword" : "admin/resetPassword";
  return axios.post(API_URL + url, { email: email }, { headers: authHeader() });
};

const postAttachement = (id, data) => {
  return axios.post(API_URL + `documents/attachment/${id}`, data, {
    headers: {
      ...authHeader(),
      "Content-Type": " multipart/form-data",
    },
  });
};
const getAttachement = (id) => {
  return axios.get(API_URL + `attachments/${id}`, {
    headers: {
      ...authHeader(),
      "Content-Type": " multipart/form-data",
    },
  });
};

const getListId = (id) => {
  return axios.get(API_URL + `list/element/${id}`, { headers: authHeader() });
};
const createPayment = (data) => {
  return axios.post(API_URL + `payment`, data, { headers: authHeader() });
};

const verifyPayment = (data) => {
  return axios.post(API_URL + `pay`, data, { headers: authHeader() });
};

const makePdf = (id, data) => {
  return axios.post(API_URL + `documents/pdf/${id}`, data, {
    headers: authHeader(),
  });
};


const getDocumentStorageLink = async(docName) => {
    
  // Create a reference from a Google Cloud Storage URI
  try {
    const gsReference = ref(storage, docName);
    //const uldDownload = 'https://firebasestorage.googleapis.com/v0/b/yolitigo-20bd2.appspot.com/o/logos-categorias-03.png?alt=media&token=92cf4657-40b6-4f0a-be09-3236977f9015'
    const uldDownload = await getDownloadURL(gsReference)
    return uldDownload
  } catch (error) {
    console.error(error)
    
  }
}
const getDocumentStorageBlob= async(docName) => {
    
  // Create a reference from a Google Cloud Storage URI
  try {
    const gsReference = ref(storage, docName);
    //const uldDownload = 'https://firebasestorage.googleapis.com/v0/b/yolitigo-20bd2.appspot.com/o/logos-categorias-03.png?alt=media&token=92cf4657-40b6-4f0a-be09-3236977f9015'
    const uldDownload = await getBlob(gsReference)
    return URL.createObjectURL(uldDownload)
  } catch (error) {
    console.error(error)
    
  }
}
const getDocumentStorageBase64= async(docName) => {
    
  // Create a reference from a Google Cloud Storage URI
  try {
    const gsReference = ref(storage, docName);
    //const uldDownload = 'https://firebasestorage.googleapis.com/v0/b/yolitigo-20bd2.appspot.com/o/logos-categorias-03.png?alt=media&token=92cf4657-40b6-4f0a-be09-3236977f9015'
    const uldDownload = await getBlob(gsReference)
    
    return blobToBase64(uldDownload)
  } catch (error) {
    console.error(error)
    
  }
}
const getDocumentAndAttachmentsBlobUrl= async(docId) => {
    
  // Create a reference from a Google Cloud Storage URI
  try {
    const dbRefe = dbRef(realTimeDB);
    const zip = new JSZip()
    const target =`/documents/${docId}`
    const templateDoc = await get(
      child(dbRefe, target)
    );

    const doc= { ...templateDoc.val(), id:templateDoc.key };
    //const uldDownload = 'https://firebasestorage.googleapis.com/v0/b/yolitigo-20bd2.appspot.com/o/logos-categorias-03.png?alt=media&token=92cf4657-40b6-4f0a-be09-3236977f9015'
    const documentBlob = await getBlob(ref(storage, doc.document_file_name))
    zip.file(`${doc.name}.pdf`,documentBlob)
    if(doc.attachments){
      for(const attach of doc.attachments){
        const attachBlob = await getBlob(ref(storage, attach))
        zip.folder("attachments").file(`${attach}`,attachBlob)
      }
    }
    const zipBloob = await zip.generateAsync({type:'blob'})
    return URL.createObjectURL(zipBloob)
  } catch (error) {
    console.error(error)
    
  }
}

const getDocumentFirebase = async (id) => {
  try {
    const dbRefe = dbRef(realTimeDB);

    
    const target =`/documents/${id}`
    const templateDoc = await get(
      child(dbRefe, target)
    );

    return { ...templateDoc.val(), id:templateDoc.key };
  } catch (error) {
    console.error(error);
  }
  return;
  /*
  return axios.post(API_URL + "documents/create", data_completa, {
    headers: authHeader(),
  });
  */
};
const deleteDocumentUser = async (doc,idUser) => {
  try {
    const coded= Base64.encode(`${doc.id}/${idUser}`)
    let url = `/orders/${coded}`
    const {data:status} = await mercadoPagoOrdersApi.delete(url);
    if(status.data.affectedRows===0) {
      return new Promise((resolve, reject) => {
        reject({
          response:'Documento no encontrado'
        })
      })
    }
    const templateDoc = await get(
      child(dbRef(realTimeDB), `/documents/${doc.firebase_document_id}`)
    );
    const firebaseDoc = { ...templateDoc.val(), id:templateDoc.key }
    if(firebaseDoc.attachments){
      for(const attach of doc.attachments){
        await deleteObject(ref(storage, attach))
      }
    }
    await remove(dbRef(realTimeDB, `/documents/${doc.firebase_document_id}`))
    return {
      status:200,
      data:{
        message:'El documento fue eliminado exitosamente'
      }
    };
  } catch (error) {
    console.error(error);
    return new Promise((resolve, reject) => {
      reject(error)
    })
  }
  /*
  return axios.post(API_URL + "documents/create", data_completa, {
    headers: authHeader(),
  });
  */
};

const functions = {
  getDocumentAndAttachmentsBlobUrl,
  getDocumentStorageLink,
  getDocumentStorageBlob,
  getDocumentStorageBase64,
  deleteDocumentUser,
  getDocumentFirebase,
  getUserSuperAdminBoard,
  getPublicContent,
  getUserBoard,
  getUsersAdminBoard,
  deleteUser,
  getUsersRegBoard,
  getCetegorias,
  getPurchaseHistory,
  postPurchaseCreate,
  putPurchaseUpdate,
  rolesUser,
  updateUser,
  getDataCategories,
  validationDocument,
  getAllTemplate,
  postPublicTemplate,
  deleteTemplate,
  showTemplate,
  editDocuments,
  updatePasswordUser,
  postDesPublicTemplate,
  getAllCategorias,
  getAllDocumentos,
  getUserDocument,
  //deleteDocumento,
  createDocumento,
  postAttachement,
  getAttachement,
  getListId,
  makePdf,
  resetPassword,
  updateDocuments,
  deleteCategory,
  createPayment,
  verifyPayment,
  GetPayment,
  GetOrder
};
export default functions;
