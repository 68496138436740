import { CCol, CFormCheck, CFormLabel, CRow } from "@coreui/react";
import React from "react";

function DoubleRadiusInput({
  className = "",
  onChange = () => {},
  value = "false",
  name = "doubleRadius",
  title = "Titulo",
  trueLabel = "Si",
  falseLabel = "No",
  trueValue = "true",
  falseValue = "false",
  disabled,
}) {
  return (
    <div className={className}>
      <CFormLabel>{title}</CFormLabel>
      <CRow>
        <CCol className={value === trueValue ? "checked" : ""}>
          <label className="mr-2">{trueLabel}</label>
          <CFormCheck
            disabled={disabled}
            type="radio"
            onChange={onChange}
            checked={value === trueValue}
            value={trueValue}
            style={{
              position: "relative",
              margin: 0,
              marginTop: ".2em",
            }}
            name={`${name}`}
            id={`${name}1`}
          />
        </CCol>
        <CCol className={value === falseValue ? "checked" : ""}>
          <label className="mr-2">{falseLabel}</label>
          <CFormCheck
            disabled={disabled}
            type="radio"
            onChange={onChange}
            checked={value === falseValue}
            value={falseValue}
            style={{
              position: "relative",
              margin: 0,
              marginTop: ".2em",
            }}
            name={`${name}`}
            id={`${name}1`}
          />
        </CCol>
      </CRow>
    </div>
  );
}

const propsAreEqual = (prev, post) => {
  return (prev.value === post.value &&
    prev.disabled === post.disabled
    );
};
export default React.memo(DoubleRadiusInput, propsAreEqual);
