import React from "react";
import { Button } from "reactstrap";
import { Modal } from "react-bootstrap";

import UserService from "../../service/user.service";
const DeleteCateg = (props) => {
  const {
    showDelMod,
    setshowDelMod,
    title,
    message,
    setrefreshData,
    refreshData,
    ModAlertMod,
    setModalData,
    isDocument,
    data,
  } = props;
  const ModDelCateSate = () => setshowDelMod(!showDelMod);

  const handleDelete = (e) => {
    e.preventDefault();
    isDocument
      ? UserService.deleteCategory(data.id)
          .then((response) => {
            if (response.status === 200) {
              setModalData({
                title: "Excelente",
                body: response.data.message,
                border: "bg-success",
              });
              ModDelCateSate();
              ModAlertMod();
            }
          })
          .catch((error) => {
            console.error(error.response);
            setModalData({
              title: "Lo sentimos",
              body: "No se pudo eliminar la plantilla.\n" + error.response,
              border: "bg-danger",
            });
            ModAlertMod();
          })
      : setModalData({
          title: "Lo sentimos",
          body: "No se pudo eliminar la categoria.\n",
          border: "bg-danger",
        });
    ModDelCateSate();
    ModAlertMod();
    setrefreshData(!refreshData);
  };
  return (
    <Modal show={showDelMod} id="ModalDelete" onHide={ModDelCateSate}>
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{message}</Modal.Body>
      <Modal.Footer>
        <Button color="secundary" onClick={ModDelCateSate}>
          Cancel
        </Button>
        <Button
          type="submit"
          color="danger"
          id="btn_dele_move_move"
          onClick={(e) => handleDelete(e)}
        >
          Delete
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default DeleteCateg;
