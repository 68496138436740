import React, { useEffect } from "react";
// import Icons from "../assets/static/iconos.png";
import LogoCategorie from "../assets/static/logos-categorias-02.png";
import Flecha from "../assets/static/flecha.png";
import "../assets/styles/components/Panel.scss";
import { useState } from "react";
import { searchElementByName } from "../utils/searchElementByName";

const SearchCatg = (props) => {
  const { handleOnPage, data, handleSubmitDocument, title, iconPath, loading } =
    props;
  const [filteredDocuments, setFilteredDocuments] = useState([]);

  /**
   * useSearchDocuemnt is a custom hook that filters an array of documents based on a search query.
   * @param {Event} event - The event object passed to the onChange event of the input element.
   * @returns {void}
   */
  const useSearchDocuemnt = (event) => {
    if (event.target.value) {
      const allItems = Object.values(data).flat();
      setFilteredDocuments(searchElementByName(allItems, event.target.value));
    } else {
      setFilteredDocuments([]);
    }
  };

  /**
   * Starts the filtered documents with the data prop
   */
  useEffect(() => {
    setFilteredDocuments(data);
  }, [data]);

  return !loading ? (
    <>
      <section className="back__panel">
        <figure className="back" onClick={(e) => handleOnPage()}>
          <img src={Flecha} alt="" />
          Regresar
        </figure>
        <figure>
          {iconPath && iconPath != "Sin icono" ? (
            <img src={iconPath} alt="" />
          ) : (
            <img
              src={LogoCategorie}
              style={{
                borderRadius: "65px",
                height: "35px",
                width: "40px",
              }}
              alt=""
            />
          )}

          {title}
        </figure>
      </section>
      <section className="selection__panel">
        <h6>Selecciona el documento</h6>
        <input
          type="text"
          placeholder="Busca el documento que quieres generar"
          onChange={useSearchDocuemnt}
        />
      </section>
      {filteredDocuments.length >= 1 ? (
        <section className="list__categories">
          {filteredDocuments.map((doc, i) => (
            <div key={`documentCat-${i}`} className="link">
              <div
                className="categories"
                onClick={(e) => handleSubmitDocument(doc)}
              >
                <img src={LogoCategorie} alt="" />
                <h6>{doc.name}</h6>{" "}
              </div>
            </div>
          ))}
        </section>
      ) : (
        Object.entries(data).map(([key, value]) => {
          return (
            <>
              <section className="selection__panel">
                <h6>{key}</h6>
              </section>

              <section className="list__categories">
                {value.length > 0 ? (
                  value.map((doc, i) => (
                    <div key={`documentCat-${i}`} className="link">
                      <div
                        className="categories"
                        onClick={(e) => handleSubmitDocument(doc)}
                      >
                        <img src={LogoCategorie} alt="" />
                        <h6>{doc.name}</h6>{" "}
                      </div>
                    </div>
                  ))
                ) : (
                  <div>
                    <h2>
                      !Lo sentimos esta categoria no tiene documentos cargados!
                      
                    </h2>
                  </div>
                )}
              </section>
            </>
          );
        })
      )}
    </>
  ) : (
    <div className="w-100 h-100 d-flex justify-content-center align-items-center">
      <span className="spinner-border "></span>
    </div>
  );
};

export default SearchCatg;
