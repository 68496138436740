import React, { useEffect, useState } from "react";
import { Button } from "reactstrap";
import { Modal } from "react-bootstrap";
import UserService from "../../service/user.service";
import "../../assets/styles/components/NewFields.scss";

const ListCategories = (props) => {
  const {
    showDelMod,
    setshowDelMod,
    title,
    selectCat,
    setSelectCat,
    viewList,
    info,
    setInfo,
    IsEdit,
  } = props;
  const [allCatg, setDataCat] = useState([]);
  const [selectedCatg, setSelectedCatg] = useState([...selectCat.selectCate]);
  useEffect(() => {
    UserService.getAllCategorias().then(
      (response) => {
        setDataCat(response.data);
      },
      (error) => {
        const _content =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        console.error(_content);
      }
    );
  }, []);
  const ModDelCateSate = () => setshowDelMod(!showDelMod);

  const handleChangeCatg = (e, id) => {

    let _categories = [...selectedCatg];
    var index = _categories.indexOf(`${id}`);
    if (index > -1) {
      _categories.splice(index, 1);
    } else {
      _categories.push(`${id}`);
    }
    setSelectedCatg(_categories);
  };

  const handleSubmit = (e, arraySelectCatg) => {
    e.preventDefault();
    setSelectCat({
      title: selectCat.title,
      selectCate: selectedCatg,
      viewList: selectCat.viewList,
    });
    setInfo({ ...info, categories: selectedCatg });
    ModDelCateSate();
  };

  return (
    <Modal show={showDelMod} id="ModalDelete" onHide={ModDelCateSate}>
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="body__listModal">
          {IsEdit &&
            allCatg?.map((catg, i) => {
              return (
                <>
                  <div className="d-flex info__catgModa cc" key={i}>
                    <input
                      type="checkbox"
                      onClick={(e) => handleChangeCatg(e, catg.id)}
                    />
                    <h6>{catg.name}</h6>
                  </div>
                </>
              );
            })}
          {!IsEdit &&
            viewList === true &&
            allCatg.map((catg, i) => {
              const finded = selectedCatg.find((cat) => {
                return catg.id === parseInt(cat);
              });
              if (!finded) return <></>;
              return (
                <>
                  <div className="d-flex info__catgModa aa" key={i}>
                    <input
                      type="checkbox"
                      disabled={true}
                      defaultChecked={finded}
                    />
                    <h6>{catg.name}</h6>
                  </div>
                </>
              );
            })}
          {!IsEdit &&
            viewList !== true &&
            allCatg.map((catg, i) => {
              const finded = selectedCatg.find((cat) => {
                return catg.id === parseInt(cat);
              });
              return (
                <>
                  <div className="d-flex info__catgModa aa" key={i}>
                    <input
                      type="checkbox"
                      defaultChecked={finded}
                      onClick={(e) => handleChangeCatg(e, catg.id)}
                    />
                    <h6>{catg.name}</h6>
                  </div>
                </>
              );
            })}
        </div>
      </Modal.Body>
      <Modal.Footer>
        {IsEdit ? (
          <>
            <Button color="warning" onClick={ModDelCateSate}>
              Cancel
            </Button>
            <Button
              color="success"
              id="btn_dele_move_move"
              onClick={(e) => handleSubmit(e, selectCat)}
            >
              Guardar
            </Button>
          </>
        ) : viewList === true ? (
          <Button color="info" onClick={ModDelCateSate}>
            Cerrar
          </Button>
        ) : (
          <>
            <Button color="warning" onClick={ModDelCateSate}>
              Cancel
            </Button>
            <Button
              type="submit"
              color="success"
              onClick={(e) => handleSubmit(e, selectCat)}
              id="btn_dele_move_move"
            >
              Guardar
            </Button>
          </>
        )}
      </Modal.Footer>
    </Modal>
  );
};

export default ListCategories;
