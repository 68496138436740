const Routes = {}

Routes.base = '/'

Routes.login = (isAdmin) =>{
    let url = '/login'
    if(isAdmin) {
        url = url + `/admin`
    }
    return url
}

Routes.register = '/register'
Routes.ValidatePayment = (method=':id') =>{
    let url = '/validate'
    if(method) {
        url = url + `/${method}`
    }
    return url
}

Routes.methods = (method) =>{
    let url = '/methods'
    if(method) {
        url = url + `/${method}`
    }
    return url
}

Routes.profile = '/profile'
Routes.resetPassword = '/reset-password'

Routes.panel = (section) =>{
    let url = '/panel'
    if(section) {
        url = url + `/${section}`
    }
    return url
}

Routes.admin = (id)=>{
    let url = '/admin'
    if(id) {
        url = url + `/${id}`
    }
    return url
}

export default Routes