import React from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";

import "../assets/styles/components/Page404.scss"
const Page404 = () => {
  return (
    <div classNameName="login__page">
      <Header isHome />
      <div className="container error-container">
        <div className="row  d-flex align-items-center justify-content-center">
          <div className="col-md-12 text-center">
            <h1 className="big-text">Oops!</h1>
            <h2 className="small-text">404 - PAGE NOT FOUND</h2>
          </div>
          <div className="col-md-6  text-center">
            <p>
              Es posible que se haya eliminado la página que está buscando, el
              nombre ha cambiado o no está disponible temporalmente.
            </p>
            <a href="/" className="button button-dark-blue iq-mt-15 text-center">
              Regresar al Inicio
            </a>
          </div>
        </div>
      </div>
      <Footer isHome />
    </div>
  );
};

export default Page404;
