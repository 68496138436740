import React from "react";
// reactstrap components
import { Button } from "reactstrap";
import { Modal } from "react-bootstrap";
import { useDispatch } from "react-redux";

import { deleteListDinamic } from "../../actions/auth";

const Modaldelete = (props) => {
  const {
    state,
    showDelMod,
    setshowDelMod,
    title,
    message,
    refreshData,
    setrefreshData,
    ModAlertMod,
    setModalData,
  } = props;
  const ModDelCateSate = () => setshowDelMod(!showDelMod);

  const dispatch = useDispatch();

  const handleDelete = (e, id) => {
    e.preventDefault();
    dispatch(deleteListDinamic(id))
      .then((response) => {
        setrefreshData(!refreshData);
        setModalData({
          title: "Felicidades",
          body: response.data.message,
          border: "bg-success",
        });
        ModDelCateSate();
        ModAlertMod();
        setrefreshData(!refreshData);
      })
      .catch((error) => {
        setModalData({
          title: "Lo sentimos",
          body:
            "No se realizo la creación solicitada.\n" +
            error.response.data.message,
          border: "bg-danger",
        });
        ModDelCateSate();
        ModAlertMod();
      });
  };

  return (
    <Modal show={showDelMod} id="ModalDelete" onHide={ModDelCateSate}>
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{message}</Modal.Body>
      <Modal.Footer>
        <Button color="secundary" onClick={ModDelCateSate}>
          Cancel
        </Button>
        <Button
          type="submit"
          color="danger"
          id="btn_dele_move_move"
          onClick={(e) => handleDelete(e, state.id)}
        >
          Delete
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
export default Modaldelete;
