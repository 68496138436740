import React, { useEffect, useState } from "react";
import { Button, FormGroup, Label } from "reactstrap";
import { Modal } from "react-bootstrap";
import { Form } from "react-bootstrap";

import { useDispatch } from "react-redux";
import { updateCateg } from "../../actions/auth";
import { Redirect } from "react-router-dom";

const EditCateg = (props) => {
  const {
    title,
    showEdiMod,
    data,
    setshowEdiMod,
    setrefreshData,
    refreshData,
    ModAlertMod,
    setModalData,
  } = props;
  const ModEdiCateSate = () => {
    if (editData) setEditData(!editData);
    setshowEdiMod(!showEdiMod);
  };

  const [close] = useState(false);
  const [editData, setEditData] = useState(false);
  const [dataToSend, setDataToSend] = useState({
    name: data.name,
    description: data.description,
    icon_path: data.icon_path,
  });

  const changeForm = () => setEditData(!editData);

  const dispatch = useDispatch();

  // const logOut = () => {
  //   dispatch(logout());
  //   setClose(true);
  // };

  useEffect(() => {
    setDataToSend({
      name: data.name,
      description: data.description,
      icon_path: data.icon_path,
    });
  }, [data]);

  const handleChange = (e) => {
    e.preventDefault();

    let fields = {
      ...dataToSend,
    };

    fields[`${e.target.name}`] = e.target.value;

    setDataToSend(fields);
    // setDataToSend({...dataToSend, `${e.target.name}` : e.target.value })

    // if (e.target.name === "category_name") {
    //   if (data.description !== null) {
    //     setDataToSend({
    //       description: data.description,
    //       name: e.target.value,
    //     });
    //   } else {
    //     setDataToSend({
    //       name: e.target.value,
    //     });
    //   }
    // } else if (e.target.name === "description") {
    //   setDataToSend({
    //     name: data.name,
    //     description: e.target.value,
    //   });
    // }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let dataSend = {
      name: dataToSend.name,
      description: dataToSend.description,
      icon_path: dataToSend.icon_path,
    };

    if (dataToSend.icon_path === "" || dataToSend.icon_path === null) {
      dataSend = {
        ...dataSend,
        icon_path: "Sin icono",
      };
    }

    if (dataToSend.description === "" || dataToSend.description === null) {
      dataSend = {
        ...dataSend,
        description: "Sin descripción",
      };
    }

    dispatch(updateCateg(dataSend, data.id))
      .then((response) => {
        if (response.status === 200) {
          setModalData({
            title: "Felicidades",
            body: response.data.message,
            border: "bg-success",
          });
          ModAlertMod();
        }
      })
      .catch((error) => {
        console.error(error);
        setModalData({
          title: "Lo sentimos",
          body: "No se pudo editar la categoria.\n",
          border: "bg-danger",
        });
      });
    ModEdiCateSate();
    setrefreshData(!refreshData);
    ModAlertMod();
  };
  return (
    <Modal show={showEdiMod} id="ModalEdit" onHide={ModEdiCateSate}>
      {close && <Redirect to="/login/admin" />}
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Form role="form" onSubmit={() => {}}>
        {editData ? (
          <>
            <Modal.Body>
              <FormGroup className="fields__new">
                <Label>Nombre categoria</Label>
                <Form.Control
                  type="text"
                  placeholder={data.name ? data.name : "Sin nombre"}
                  value={dataToSend.name ? dataToSend.name : ""}
                  name="name"
                  onChange={handleChange}
                ></Form.Control>
              </FormGroup>
              <FormGroup className="fields__new">
                <Label>Descripcion categoria</Label>
                <Form.Control
                  placeholder={
                    data.description ? data.description : "Sin descripción"
                  }
                  value={dataToSend.description ? dataToSend.description : ""}
                  type="text"
                  name="description"
                  onChange={handleChange}
                ></Form.Control>
              </FormGroup>
              <FormGroup className="fields__new">
                <Label>Fecha de Creacion</Label>
                <Form.Control
                  type="date"
                  value="2021-01-01"
                  placeholder="12/12/2021"
                  name="date"
                  disabled
                  onChange={handleChange}
                ></Form.Control>
              </FormGroup>
              <FormGroup className="fields__new">
                <Label>Url icon</Label>
                <Form.Control
                  type="text"
                  value={
                    dataToSend.icon_path !== "Sin icono"
                      ? dataToSend.icon_path
                      : ""
                  }
                  placeholder={data.icon_path ? data.icon_path : ""}
                  name="icon_path"
                  onChange={handleChange}
                ></Form.Control>
              </FormGroup>
            </Modal.Body>
            <Modal.Footer>
              <Button color="danger" onClick={ModEdiCateSate}>
                Cerrar
              </Button>
              <Button color="success" onClick={handleSubmit}>
                Guardar
              </Button>
            </Modal.Footer>
          </>
        ) : (
          <>
            <Modal.Body>
              <FormGroup className="fields__new">
                <Label>Nombre categoria</Label>
                <Form.Control
                  type="text"
                  value={data.name}
                  disabled
                ></Form.Control>
              </FormGroup>
              <FormGroup className="fields__new">
                <Label>Descripcion categoria</Label>
                <Form.Control
                  value={
                    data.description ? data.description : "Sin descripción"
                  }
                  disabled
                ></Form.Control>
              </FormGroup>
              <FormGroup className="fields__new">
                <Label>Fecha de Creacion</Label>
                <Form.Control
                  type="email"
                  value={"12/10/2020"}
                  disabled
                ></Form.Control>
              </FormGroup>
              <FormGroup className="fields__new">
                <Label>Url icon</Label>
                <Form.Control
                  type="text"
                  value={data.icon_path ? data.icon_path : ""}
                  disabled
                ></Form.Control>
              </FormGroup>
            </Modal.Body>
            <Modal.Footer>
              <Button color="danger" onClick={ModEdiCateSate}>
                Cerrar
              </Button>
              <Button color="success" onClick={changeForm}>
                Editar
              </Button>
            </Modal.Footer>
          </>
        )}
      </Form>
    </Modal>
  );
};

export default EditCateg;
