import React, { useState, useRef } from "react";
import Header from "../components/Header";
import "../assets/styles/components/Login.scss";
import ImageLogin from "../assets/static/martillo.jpg";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import { Row } from 'reactstrap';
import { Link} from "react-router-dom";
import AlertDialogSlide from "../components/modals/AlertDialogSlide";
import UserService from "../service/user.service";

const ResetPassword = (props) => {
    const [loading, setLoading] = useState(false);
    const [email, setEmail] = useState("");
    const [showAlertMod, setshowAlertMod] = useState(false);
    const [modalData, setModalData] = useState({
      title: "",
      body: "",
      border: "",
    });

    const form = useRef();

    const ModAlertMod = () => setshowAlertMod(!showAlertMod);

    const handleSubmit = (e) => {
        e.preventDefault();
        setLoading(true);
        UserService.resetPassword(props.location.state.isAdmin, email).then(
          (response) => {
            let message = response.data.message
            if (message.toLowerCase() === 'Contraseña de Usuario Generada Exitosamente'.toLowerCase()) message = "Has recibido un correo con las instrucciones para cambiar tu contraseña"
            setModalData({
              title: "Correo enviado exitosamente",
              body: message,
              border: "bg-success",
            });
            setshowAlertMod(true);

          },
          (error) => {
            debugger
            setModalData({
              title: "Ha ocurrido un error",
              body: "No existe un usuario registrado con este correo.",
              border: "bg-danger",
            });
            setshowAlertMod(true);
            console.error(error.response);
          }
        );
        setLoading(false);
      };

    const onChangeEmail = (e) => {
        const Email = e.target.value;
        setEmail(Email);
    };
    
  return (
    <>
      <div className="login__page__admin">
        <Header isLoginAdmin />
        <section className="form__login d-flex justify-content-center">
          <div className="login__body">
            <div className="login__admin">
              <img src={ImageLogin} alt="" />
              <div className="formulario">
              <Row className = "pb-2 justify-content-center text-title1">  
                Introduzca el correo electronico
              </Row>  
                <Form onSubmit={handleSubmit} ref={form}>
                  <div className="input-group form-group">
                    <Input
                      type="text"
                      className="form-control"
                      placeholder="Correo electronico"
                      value={email}
                      onChange={onChangeEmail}
                      required="required"
                    />
                  </div>
                  <div className="container-fluid">

                    <Row className = "pb-2 justify-content-center">  
                            <button
                                type="submit"
                                className="btn btn-primary w-50"
                                disabled={loading}
                            >
                                {loading ? (
                                <span className="spinner-border spinner-border-sm"></span>
                                ) : (
                                <span>Enviar correo</span>
                                )}
                            </button>
                    </Row>  
                    <Row className="justify-content-center">  
                      <button
                        type="button"
                        className="btn btn-primary w-50"
                        onClick={(e)=>props.history.push("/login")}
                        disabled={loading}
                      >
                        <Link className="contra__login" to="/login">                
                            Volver
                        </Link>
                      </button>
                    </Row>  
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </section>
        <AlertDialogSlide
        modaldata={modalData}
        show={showAlertMod}
        onHide={() => ModAlertMod()}
        />
      </div>
    </>
  );
};

export default ResetPassword;
