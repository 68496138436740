import React, { useEffect, useState } from "react";
import { Button } from "reactstrap";

import "../assets/styles/components/ViewTemplate.scss";
import UserService from "../service/user.service";
import AlertDialogSlide from "./modals/AlertDialogSlide";

import Logo from "../assets/static/folder.png";
import DeleteTemplate from "./modals/DeleteTemplate";
import ShowTemplate from "./modals/ShowTemplate";

const ViewTemplate = (props) => {
  const { state, setRefresh, refresh, catg, onEditDocument = ()=>{}, onTestDocument=()=>{} } = props;
  const [isEdit, setIsEdit] = useState(false);
  const [idTemplate, setIdTemplate] = useState("");
  const [idName, setIdName] = useState([]);
  const [templateInfo, setTemplateInfo] = useState({
    name: "",
    price: "",
    status: "",
    supervise_price: "",
    document: "",
  });
  // const [showEdiMod, setshowEdiMod] = useState(false);
  const [showDelMod, setshowDelMod] = useState(false);
  const [showNewMod, setshowNewMod] = useState(false);
  const [modalData, setModalData] = useState({
    title: "",
    body: "",
    border: "",
  });

  const [showAlertMod, setshowAlertMod] = useState(false);
  // const ModEdiCateSate = () => setshowEdiMod(!showEdiMod);
  const ModDelCateSate = () => setshowDelMod(!showDelMod);
  const ModNewCateSate = () => setshowNewMod(!showNewMod);
  const ModAlertMod = () => setshowAlertMod(!showAlertMod);

  useEffect(() => {
    return () => {
      
    };
  }, []);
  const publishTemplate = (e, id) => {
    e.preventDefault();
    UserService.postPublicTemplate(id)
      .then((response) => {
        setModalData({
          title: "Felicidades",
          body: response.data.message,
          border: "bg-success",
        });
        ModAlertMod();
      })
      .catch((error) => {
        console.error(error.response);
        setModalData({
          title: "Lo sentimos",
          body:
            "No se realizo el cambio solicitado.\n" +
            error.response.data.message ,
          border: "bg-danger",
        });
        ModAlertMod();
      });
    setRefresh(!refresh);
  };

  const DesPublishTemplate = (e, id) => {
    e.preventDefault();
    UserService.postDesPublicTemplate(id)
      .then((response) => {
        setModalData({
          title: "Felicidades",
          body: response.data.message,
          border: "bg-success",
        });
        ModAlertMod();
      })
      .catch((error) => {
        setModalData({
          title: "Lo sentimos",
          body:
            "No se realizo el cambio solicitado.\n" +
            error.response.data.message,
          border: "bg-danger",
        });
        ModAlertMod();
      });
    setRefresh(!refresh);
  };

  const OpenModalDel = (e, id_delete, name) => {
    e.preventDefault();
    setIdTemplate(id_delete);
    setIdName(name);
    ModDelCateSate();
  };
  const OpenModalView = (e, data, edit) => {
    e.preventDefault();
    //setIsEdit(edit);
    //setIdTemplate(data.id);
    onEditDocument(data)
    //setTemplateInfo(data)
    //ModNewCateSate();
  };
  return (
    <>
      <section id="t-cards">
        <div className="container">
          <div className="row">
            {state[0] == null
              ? "No ha documentos cargados"
              : state.map((data, i) => {
                  return (
                    <div className="col-sm-7 col-md-4 mt-4" key={`document_${i}`}>
                      <div className="panel panel-default panel-card">
                        <div className="panel-heading">
                          {data.status === "BORRADOR" ? (
                            <button
                              className="btn btn-success btn-sm"
                              onClick={(e) => publishTemplate(e, data.id)}
                            >
                              Publicar
                            </button>
                          ) : (
                            <button
                              className="btn btn-danger btn-sm"
                              onClick={(e) => DesPublishTemplate(e, data.id)}
                            >
                              Despublicar
                            </button>
                          )}
                        </div>
                        <div className="panel-figure">
                          <img
                            className="img-responsive img-circle"
                            src={Logo}
                            alt='logo'
                          />
                        </div>
                        <div className="panel-body text-center">
                          <h4 className="panel-header">
                            <p>{data.name}</p>
                          </h4>
                        </div>
                        <div className="panel-thumbnails">
                          <div className="row">
                            <div className="col-2 buttons__templ">
                              <Button
                                className="mr-4 shadow btn-circle"
                                color="info"
                                onClick={(e) => OpenModalView(e, data, true)}
                              >
                                <i className="fas fa-pencil-alt"></i>
                              </Button>
                            </div>
                            <div className="col-2 buttons__templ">
                              <Button
                                className="mr-4 shadow btn-circle"
                                color="warning"
                                onClick={(e) =>
                                  onTestDocument(data)
                                }
                              >
                                <i className="fas fa-eye"></i>
                              </Button>
                            </div>
                            <div className="col-2 buttons__templ">
                              <Button
                                className="mr-4 shadow btn-circle"
                                color="danger"
                                onClick={(e) =>
                                  OpenModalDel(e, data.id, data.name)
                                }
                              >
                                <i className="far fa-trash-alt white"></i>
                              </Button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
          </div>
        </div>
      </section>

      {showNewMod ? <ShowTemplate
        showDelMod={showNewMod}
        setshowDelMod={setshowNewMod}
        idTemplate={idTemplate}
        title={"Estas viendo el template: " + templateInfo.name}
        state={templateInfo}
        categories={templateInfo.categorias}
        vars={templateInfo.vars}
        lists={templateInfo.lists}
        setModalData={setModalData}
        isEdit={isEdit}
        refreshData={refresh}
        setrefreshData={setRefresh}
        ModAlertMod={ModAlertMod}
        categoriesSelect={templateInfo.categorias}
        catg={catg}
      /> : ''}
      <DeleteTemplate
        showDelMod={showDelMod}
        setshowDelMod={setshowDelMod}
        idTemplate={idTemplate}
        title={"Eliminar template: " + idName}
        message={"¿Estas seguro de eliminar el template de seleccionado?"}
        refreshData={refresh}
        setrefreshData={setRefresh}
        ModAlertMod={ModAlertMod}
        setModalData={setModalData}
      />

      <AlertDialogSlide
        modaldata={modalData}
        show={showAlertMod}
        onHide={() => ModAlertMod()}
      />
    </>
  );
};

export default ViewTemplate;
