import React, { useState, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, Redirect, useHistory } from "react-router-dom";

import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";
import { Container, Row, Col } from "reactstrap";

import Footer from "./Footer";
import Header from "./Header";

import { register } from "../actions/auth";
// import {
//   vemail,
//   vpassword,
//   vusername,
//   required,
// } from "../validations/Validaciones";

// import {
//   REGISTER_FAIL,
//   SET_MESSAGE,
// } from "../actions/types";

import Logo from "../assets/static/logo-yo-litigo-01.png";
import "../assets/styles/components/PageRegister.scss";
import strongPaswordTester from "../utils/strongPasswrdTest";
import { CFormCheck } from "@coreui/react";
import useToast from "../utils/CustomHooks/useToast";
import Routes from "../routes/Routes";

const PageRegister = (props) => {
  // envio de informacion
  const checkBtn = useRef();
  const addToast = useToast()

  const { isLoggedIn } = useSelector((state) => state.auth);
  const history = useHistory()
  const [loading, setLoading] = useState(false);
  const [termsAndConditions, setTermsAndConditions] = useState(false);
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [password2, setPassword2] = useState("");
  const [successful, setSuccessful] = useState(false);
  const [messageError, setMessageError] = useState("");
  const [strongLevel, setStrongLevel] = useState(0);
  const [errorsPassword, setErrorsPassword] = useState({
    password: false,
    password2: false,
  });

  const { message } = useSelector((state) => state.message);
  const dispatch = useDispatch();

  const onChangeUsername = (e) => {
    const username = e.target.value;
    setUsername(username);
  };

  const onChangeEmail = (e) => {
    const email = e.target.value;
    setEmail(email);
  };

  const onChangePassword = (e) => {
    const newpassword = e.target.value;
    setStrongLevel(strongPaswordTester(newpassword));
    setPassword(newpassword);
  };

  const onChangePassword2 = (e) => {
    const newpassword = e.target.value;
    if (newpassword === password) {
      setStrongLevel(strongPaswordTester(password));
      setErrorsPassword({ ...errorsPassword, password2: false });
    } else {
      setErrorsPassword({ ...errorsPassword, password2: true });
    }
    setPassword2(newpassword);
  };

  const handleRegister = (e) => {
    e.preventDefault();
    let _validations = false;
    let _message = "";
    if (username.length < 5) {
      _validations = true;
      _message = "El nombre de usuario debe tener al menos 4 caracteres";
    }
    if (strongLevel!==1) {
      _validations = true;
      _message = "La contraseña debe tener entre 8 y 40 caracteres, contener mayúsculas, minúsculas, numeros y caracteres especiales.";
    }
    if (password !== password2) {
      _validations = true;
      _message = "La contraseña deben coincidir";
    }
    if(!termsAndConditions){
      _validations = true;
      _message = "Para registrarte debes aceptar los términos y condiciones de uso";
    }
    if (_validations) {
      setMessageError(_message);
    } else {
      setMessageError("");
      if (checkBtn.current.context._errors.length === 0) {
        setLoading(true);
        dispatch(register(username, email, password, password2))
          .then((response) => {
            
            debugger
            setLoading(false);
            if(response.token){
              history.push(Routes.panel());
              addToast({
                title:'Usuario Creado',
                mode:'primary'
              })
              
            }
            
          })
          .catch(() => {
            debugger
            setSuccessful(false);
            setLoading(false);
            
            addToast({
              title:'Error al crear usuario',
              mode:'danger'
            })
          });
      } else {
        setSuccessful(false);
        setLoading(false);
        addToast({
          title:'Error al crear usuario',
          mode:'danger'
        })
      }
    }
  };

  useEffect(() => {
    if (isLoggedIn) {
      return <Redirect to="/panel" />;
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="container__register">
      {successful && <Redirect to="/panel" />}
      <Header isHome />
      <div className="d-flex justify-content-center">
        <div className="form-conf bg-light d-flex">
          <Form onSubmit={handleRegister} className="p-5">
            <div className="mb-2">
              <label className="form-label font-weight-bold">Nombre:</label>
              <Input
                type="text"
                className="form-control"
                id="name"
                name="name"
                value={username}
                onChange={onChangeUsername}
                required="required"
              />
            </div>
            <div className="mb-2">
              <label className="form-label font-weight-bold">Email:</label>
              <Input
                type="email"
                className="form-control"
                id="email"
                name="email"
                value={email}
                onChange={onChangeEmail}
                required="required"
              />
            </div>
            <div className="mb-2">
              <label className="form-label font-weight-bold">Contraseña:</label>
              <Input
                type="password"
                className="form-control"
                id="password"
                name="password"
                value={password}
                onChange={onChangePassword}
                required="required"
              />
            </div>
            <div className="progress mb-1">
              <div
                className={`progress-bar bg-${
                  strongLevel <= 0.4 ? "danger" : ""
                }${strongLevel > 0.4 && strongLevel <= 0.8 ? "warning" : ""}${
                  strongLevel > 0.8 ? "success" : ""
                }`}
                role="progressbar"
                style={{ width: `${strongLevel * 100}%` }}
                aria-valuenow={strongLevel * 100}
                aria-valuemin="0"
                aria-valuemax="100"
              ></div>
            </div>
            <div className="mb-2">
              <label className="form-label font-weight-bold updateComponent">
                Confirmar Contraseña:
              </label>
              <Input 
                type="password"
                className="form-control"
                id="confpassword"
                name="password_confirmation"
                value={password2}
                onChange={onChangePassword2}
                required="required"
              />
            </div>
            
            <p style={{fontSize:'.7rem'}} className="font-weight-lighter text-secondary">* La contraseña debe tener entre 8 y 40 caracteres, al menos un mayúscula, al menos un número y al menos un carácter especial</p>
            <div className="mb-2">
              <CFormCheck style={{
                position:'relative',
                marginLeft: 0,
                marginTop: '.2em',
                marginRight:'7px'
              }} id="termsAndConditionsCheck" 
                checked={termsAndConditions}
                onChange={(e)=>{
                  setTermsAndConditions(e.target.checked)
                }}
              />
              <label for='termsAndConditionsCheck' className="form-label font-weight-bold updateComponent">
                He leído y acepto los <a href="https://yolitigo.com/politica-privacidad/" target="_blank" rel="noreferrer">términos y condiciones de uso</a>
              </label>
            </div>
            <Container className="mb-2">
              <Row >
                <Col>
                  <button
                    type="submit"
                    className="btn btn-primary w-100"
                    disabled={loading}
                  >
                    {loading ? (
                      <span className="spinner-border spinner-border-sm"></span>
                    ) : (
                      <span>Registrarme</span>
                    )}
                  </button>
                </Col>
                <Col>
                  <Link className="contra__login" to="/login">
                    {" "}
                    <button
                      type="button"
                      className="btn btn-primary w-100"
                      disabled={loading}
                    >
                      Volver
                    </button>
                  </Link>
                </Col>
              </Row>
            </Container>

            {messageError === "" ? (
              message && (
                <div className="form-group">
                  <div
                    className={
                      successful ? "alert alert-success" : "alert alert-danger"
                    }
                    role="alert"
                  >
                    {message}
                  </div>
                </div>
              )
            ) : (
              <div className="alert alert-danger" role="alert">
                {messageError}
              </div>
            )}
            <CheckButton style={{ display: "none" }} ref={checkBtn} />
          </Form>
          <div className="image__register">
            <img src={Logo} alt="" />
          </div>
        </div>
      </div>
      <Footer isHome />
    </div>
  );
};

export default PageRegister;
