import React, { useState } from "react";
import { Formik } from "formik";
import { object, ref, string } from "yup";
import UserService from "../../service/user.service";
import Alert from "../Alert";

import "../../assets/styles/components/ResetPassword.scss";
import FormInputs from "./FormInputs.js";

function Index(props) {
  const { ModAlertMod, setModalData } = props;
  const [passChangeSuccess, setPassChangeSuccess] = useState(false);

  const _handleModalClose = () => {
    setPassChangeSuccess(false);
  };

  const _renderModal = () => {
    const onClick = () => {
      setPassChangeSuccess(false);
    };

    return (
      <Alert
        isOpen={passChangeSuccess}
        onClose={_handleModalClose}
        handleSubmit={onClick}
        title="Password Reset"
        text="Your password was changed successfully"
        submitButtonText="Done"
      />
    );
  };

  const _handleSubmit = ({
    currentPass,
    newPass,
    confirmPass,
    setSubmitting,
    resetForm,
  }) => {
    
    UserService.updatePasswordUser(currentPass, newPass, confirmPass)
      .then((response) => {
        setModalData({
          title: "Excelente",
          body: response.data.message,
          border: "bg-success",
        });
        ModAlertMod();
        setSubmitting(false);
        setPassChangeSuccess(true);
        resetForm();
      })
      .catch((error) => {
        console.error(error);
        setModalData({
          title: "Lo sentimos",
          body: "No se pudo actualizar la contraseña. \n" + error.data,
          border: "bg-danger",
        });
        ModAlertMod();
      });
  };

  /*

          const validateChange = (e) => {
            if (e.target.name === "password") {
              console.log("test");
              setStrongLevel(strongPaswordTester(e.target.value));
            }
            handleChange(e);
          };
          const validateSubmit = (e) =>{
            e.preventDefault()
            if((data.password === data.password2) && (strongLevel===1) ) {
              handleSubmit(e)
            }
          }
  */
  return (
    <div className="reset__pass">
      <h2>CAMBIAR CONTRASEÑA</h2>
      <hr />

      <Formik
        initialValues={{
          currentPass: "",
          newPass: "",
          confirmPass: "",
        }}
        validationSchema={object().shape({
          currentPass: string().required("Actual contraseña es requerida"),
          newPass: string().required("Nueva contraseña es requerida"),
          confirmPass: string()
            .oneOf([ref("newPass")], "Contraseña no iguales")
            .required("Contraseña es requerida"),
        })}
        validateOnChange={true}
  
        onSubmit={(
          { currentPass, newPass, confirmPass },
          { setSubmitting, resetForm }
        ) =>
          _handleSubmit({
            currentPass,
            newPass,
            confirmPass,
            setSubmitting,
            resetForm,
          })
        }>
          {props => {
            return <>
            <FormInputs {...props}/>
            {_renderModal()}
            </>
          }}
        </Formik>
    </div>
  );
};

export default Index;
