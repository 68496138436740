import React, { useState } from "react";

import { useDispatch } from "react-redux";
import { postChangeUser } from "../../actions/auth";
import { Button } from "reactstrap";
import { Modal } from "react-bootstrap";
import { Redirect } from "react-router-dom";

const ResetPassword = (props) => {
  const {
    showChangeMod,
    setshowChangeMod,
    title,
    message,
    userId,
    setrefreshData,
    refreshData,
    setModalData,
    ModAlertMod,
  } = props;

  const [close] = useState(false);
  const dispatch = useDispatch();

  // const logOut = () => {
  //   dispatch(logout());
  //   setClose(true);
  // };

  const ModChangeCateSate = () => setshowChangeMod(!showChangeMod);

  const handleChange = (e, userId) => {
    e.preventDefault();

    dispatch(postChangeUser(userId))
      .then((response) => {
        ModChangeCateSate();
        setModalData({
          title: "Excelente",
          body: response.data.message,
          border: "bg-success",
        });
        //ModDelCateSate();
        setrefreshData(!refreshData);
      })
      .catch((error) => {
        setModalData({
          title: "Lo sentimos",
          body: "No se pudo cambiar la contraseña del usuario.\n" + error.data,
          border: "bg-danger",
        });
        ModAlertMod();
      });
  };
  return (
    <>
      {close && <Redirect to="/login/admin" />}
      <Modal show={showChangeMod} id="ModalDelete" onHide={ModChangeCateSate}>
        <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{message}</Modal.Body>
        <Modal.Footer>
          <Button color="secundary" onClick={ModChangeCateSate}>
            Cancelar
          </Button>
          <Button
            type="submit"
            color="danger"
            id="btn_dele_move_move"
            onClick={(e) => handleChange(e, userId)}
          >
            Cambiar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ResetPassword;
