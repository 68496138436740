import React, { useCallback } from "react";
import { useDropzone } from "react-dropzone";
import { ReactComponent as DownloadSVG } from "../../assets/svg/download.svg";
import { ReactComponent as FileSVG } from "../../assets/svg/file.svg";

function DropzoneInput({
  loaded = false,
  className = "",
  children = (
    <>
      <p>Importar documento o arrastrar aquí</p>
    </>
  ),
  dragMsg = "Arrastra aqui el documento",
  onDrop = () => {},
}) {
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  return (
    <div className={`dropzone_input_cont ${className}`} {...getRootProps()}>
      <input {...getInputProps()} />
      <div className="d-flex flex-column justify-center align-items-center">
        <span className={`dropzone-icon ${loaded? 'dropzone-icon-loaded':''}`}>
          {!loaded ? (
            <DownloadSVG style={{ height: "50px" }}></DownloadSVG>
          ) : (
            <FileSVG style={{ height: "50px" }}/>
          )}
        </span>

        {isDragActive ? <p>{dragMsg}</p> : children}
      </div>
    </div>
  );
}

export default DropzoneInput;
