import {
  child,
  get,
  onValue,
  push,
  ref,
  remove,
  update,
} from "firebase/database";
import {
  ref as storageRef,
  uploadBytes,
  getDownloadURL,
  deleteObject,
} from "firebase/storage";
import { realTimeDB, storage } from "../utils/firebase.config";
import moment from "moment";
import { randomString } from "../utils/TextUtils";
import bodyZapSign from "../utils/bodyZapSign.json";
import mercadoPagoOrdersApi from "../utils/mercadoPagoOrdersApi";
import UserService from "./user.service";

const signService = {};
signService.requestExternalSignSettings = async () => {
  const dbRef = ref(realTimeDB);
  const firebaseDoc = await get(child(dbRef, `/external_sign_settings`));

  return firebaseDoc.val();
};

signService.saveExternalSignSettings = async ({ price, free }) => {
  const dbRef = ref(realTimeDB);
  const updates = {
    "/external_sign_settings": {
      price,
      free,
    },
  };
  await update(dbRef, updates);
  const firebaseDoc = await get(child(dbRef, `/external_sign_settings`));

  return firebaseDoc.val();
};

signService.requestExternalSignTemporalDocument = async (id) => {
  const dbRef = ref(realTimeDB);
  const firebaseDoc = await get(
    child(dbRef, `/external_documents_pending/` + id)
  );

  return firebaseDoc.val();
};

signService.createExternalFirmTemporalDocument = async ({
  document,
  user = {},
  transaction_amount,
  options,
  signers,
}) => {
  
  const templateReference = ref(realTimeDB, "external_documents_pending/"+ user.id);
  let document_id;
  try {
    const documentName = "temp/" + user.email + "/" + randomString(8) + ".pdf";
    const storageReference = storageRef(storage, documentName);
    await uploadBytes(storageReference, document);
    const dataToSend = {
      created_at: moment().toDate(),
      key_document: documentName,
      name: document.name,
      user_regular_id: user.id,
      user: {...user, token:null},
      state: "payment_pending",
      price: transaction_amount ?? 0,
      options,
      signers,
    };
    const request = await push(templateReference, dataToSend);
    document_id = request.key;
    return { ...dataToSend, firebase_id: document_id };
  } catch (error) {
    if (document_id) {
      await remove(
        ref(realTimeDB, `/external_documents_pending/${user.id}/${document_id}`)
      );
    }
    console.error(error);
  }
  return;
  /*
    return axios.post(API_URL + "documents/create", data_completa, {
      headers: authHeader(),
    });
    */
};

signService.requestExternalSignDocuments = async (id) => {
  try {
    const queryExternalRef = ref(realTimeDB, `/external_documents/` + id);
    return new Promise((resolve, reject) => {
      onValue(queryExternalRef, (snapshot) => {
        const data = snapshot.val();
        resolve(data ?? {});
      });
    });
  } catch (error) {
    throw error;
  }
};

signService.requestAllSignDocuments = async () => {
  try {
    const queryExternalRef = ref(realTimeDB, `/external_documents`);
    return new Promise((resolve, reject) => {
      onValue(queryExternalRef, (snapshot) => {
        if (!snapshot.val()) resolve([]);
        const data = Object.values(snapshot.val())
          .flat()
          .map((sign) => {
            return Object.entries(sign).map(([key, value]) => ({
              ...value,
              id: key,
            }));
          }).flat();
        resolve(data);
      });
    });
  } catch (error) {
    throw error;
  }
};

signService.createExternalFirmDocument = async (
  id,
  paymentId = "",
  payment
) => {
  let document_id;
  try {
    
    const firebaseDoc = await get(
      child(ref(realTimeDB), `/external_documents_pending/` + id)
    );
    const externalDocument = firebaseDoc.val();
    const downloadLinkDocument = await getDownloadURL(
      storageRef(storage, externalDocument.key_document)
    );

    const dataToSend = { ...bodyZapSign, ...externalDocument.options };
    dataToSend.name = externalDocument.name;
    delete dataToSend.url_pdf;
    // dataToSend.base64_pdf = link2;
    dataToSend.url_pdf = downloadLinkDocument;
    dataToSend.signers = externalDocument.signers;
    const { data: zapsignData } = await mercadoPagoOrdersApi.post(
      "/zapsign/docs",
      dataToSend
    );
    const temporalDocument = externalDocument.key_document;
    delete externalDocument.key_document;
    delete externalDocument.options;
    externalDocument.original_file = zapsignData.data.original_file;
    externalDocument.state = "por_firmar";
    externalDocument.zapsign_token = zapsignData.data.token;
    externalDocument.document_number = zapsignData.data.token;
    externalDocument.created_at = new Date().toISOString();
    externalDocument.payment_id = paymentId;
    externalDocument.payment_data = {
      payer: payment.payer,
      transaction_details: payment.transaction_details,
      taxes_amount: payment.taxes_amount,
      payment_method: payment.payment_method,
    };
    const request = await push(
      ref(realTimeDB, "external_documents/" + externalDocument.user_regular_id),
      externalDocument
    );
    document_id = request.key;
    await remove(ref(realTimeDB, `/external_documents_pending/${id}`));
    await deleteObject(storageRef(storage, temporalDocument));
    return { ...dataToSend, id: document_id };
  } catch (error) {
    if (document_id) {
      await remove(ref(realTimeDB, `/external_documents/${document_id}`));
    }
    console.error(error);
  }
  return;
  /*
    return axios.post(API_URL + "documents/create", data_completa, {
      headers: authHeader(),
    });
    */
};

signService.placeSignatures = async (
  token_document,
  signer_token,
  positions = []
) => {
  ;
  const dimensionSign = {
    relative_size_x: 19.55,
    relative_size_y: 9.42,
  };

  const rubricas = positions.map((position) => {
    return {
      page: position.page, //1st document page
      relative_position_bottom: position.y,
      relative_position_left: position.x,
      relative_size_x: dimensionSign.relative_size_x,
      relative_size_y: dimensionSign.relative_size_y,
      signer_token: signer_token,
    };
  });
  try {
    return await mercadoPagoOrdersApi.post(
      `/zapsign/docs/${token_document}/place-signatures`,
      { rubricas }
    );
  } catch (error) {
    console.error(error);
  }
  return;
  /*
    return axios.post(API_URL + "documents/create", data_completa, {
      headers: authHeader(),
    });
    */
};

export default signService;
