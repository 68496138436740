
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import { Button } from "reactstrap";
import Paper from "@material-ui/core/Paper";
import React, { useState } from 'react'
import Spinner from '../Slider';
import strongPaswordTester from "../../utils/strongPasswrdTest";


export default function FormInputs(props) {
    const {
      values,
      touched,
      errors,
      handleChange,
      handleBlur,
      handleSubmit,
      isValid,
      isSubmitting,
    } = props;
    const [strongLevel, setStrongLevel] = useState(0);
    
    const validateChange = (e) => {
      if (e.target.name === "newPass") {
        setStrongLevel(strongPaswordTester(e.target.value));
      }
      handleChange(e);
    };
    const validateSubmit = (e) =>{
      e.preventDefault()
      
      if((values.newPass === values.confirmPass) && (strongLevel===1) ) {
        handleSubmit(e)
      }
    }
    return isSubmitting ? (
      <Spinner />
    ) : (
      <Paper className="form form--wrapper" elevation={10}>
        <form className="form" onSubmit={(e) => validateSubmit(e)}>
          <FormControl fullWidth margin="dense">
            <InputLabel
              htmlFor="password-current"
              error={Boolean(touched.currentPass && errors.currentPass)}
            >
              {"Actual contraseña"}
            </InputLabel>
            <Input
              id="password-current"
              name="currentPass"
              type="password"
              value={values.currentPass}
              onChange={validateChange}
              onBlur={handleBlur}
              error={Boolean(touched.currentPass && errors.currentPass)}
            />
            <FormHelperText
              error={Boolean(touched.currentPass && errors.currentPass)}
            >
              {touched.currentPass && errors.currentPass
                ? errors.currentPass
                : ""}
            </FormHelperText>
          </FormControl>
          <FormControl
            fullWidth
            margin="dense"
            error={Boolean(touched.newPass && errors.newPass)}
          >
            <InputLabel
              htmlFor="password-new"
              error={Boolean(touched.newPass && errors.newPass)}
            >
              {"Nueva contraseña"}
            </InputLabel>
            <Input
              id="password-new"
              name="newPass"
              type="password"
              value={values.newPass}
              onChange={validateChange}
              onBlur={handleBlur}
              error={Boolean(touched.newPass && errors.newPass)}
              min={8}
            />
            <FormHelperText
              error={Boolean(touched.newPass && errors.newPass)}
            >
              {touched.newPass && errors.newPass ? errors.newPass : ""}
            </FormHelperText>
          </FormControl>
          <div className="progress mb-1 w-100">
            <div
              className={`progress-bar bg-${
                strongLevel <= 0.4 ? "danger" : ""
              }${strongLevel > 0.4 && strongLevel <= 0.8 ? "warning" : ""}${
                strongLevel > 0.8 ? "success" : ""
              }`}
              role="progressbar"
              style={{ width: `${strongLevel * 100}%` }}
              aria-valuenow={strongLevel * 100}
              aria-valuemin="0"
              aria-valuemax="100"
            ></div>
          </div>
          <FormControl
            fullWidth
            margin="dense"
            error={Boolean(touched.confirmPass && errors.confirmPass)}
          >
            <InputLabel
              htmlFor="password-confirm"
              error={Boolean(touched.confirmPass && errors.confirmPass)}
              >
              {"Confirma nueva contraseña"}
            </InputLabel>
            <Input
              id="password-confirm"
              name="confirmPass"
              type="password"
              value={values.confirmPass}
              onChange={validateChange}
              onBlur={handleBlur}
              error={Boolean(touched.confirmPass && errors.confirmPass)}
              min={8}
            />
            <FormHelperText
              error={Boolean(touched.confirmPass && errors.confirmPass)}
            >
              {touched.confirmPass && errors.confirmPass
                ? errors.confirmPass
                : ""}
            </FormHelperText>
          </FormControl>
          <Button
            type="submit"
            color="primary"
            disabled={Boolean(!isValid || isSubmitting)}
            style={{ margin: "16px" }}
          >
            {"Cambiar"}
          </Button>
        </form>
        
      </Paper>
    );
  }
