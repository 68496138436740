import React, { useState, useRef, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { Button, FormGroup, Label } from "reactstrap";
import UserService from "../../service/user.service";
import { Form } from "react-bootstrap";
import "../../assets/styles/components/NewFields.scss";
import CurrencyFormat from "react-currency-format";
import { useDispatch } from "react-redux";
import { getListDinamic } from "../../actions/auth";

import SunEditor from "suneditor-react";
import "suneditor/dist/css/suneditor.min.css";

import Select from "react-select";
import {
  Select as CSelect,
  FormControl,
  InputLabel,
  MenuItem,
} from "@material-ui/core";

import ListCat from "../modals/ListCategories";

const ShowTemplate = (props) => {
  const {
    showDelMod,
    setshowDelMod,
    state,
    title,
    vars,
    lists,
    isEdit,
    setrefreshData,
    refreshData,
    ModAlertMod,
    setModalData,
    idTemplate,
    categoriesSelect = [],
    categories = [],
  } = props;
  const dispatch = useDispatch();

  const ModDelCateSate = () => setshowDelMod(!showDelMod);
  const editor = useRef(null);
  // const [, setContent] = useState(`${dataDocument}`);
  // const config = {
  //   readonly: false,
  //   height: 400,
  // };
  const [data, setData] = useState({
    name: state.name,
    doc: state.document || "",
    iva: state.iva || 0,
    price: state.price,
    supervise: state.supervise || false,
    percentage_check: state.percentage_check || 0,
    supervise_price: state.supervise_price || 0,
    vars: state.vars,
    lists: state.lists,
  });
  const [select, setSelect] = useState(state.supervise);
  const [selectPrice, setSelectPrice] = useState(
    state.supervise && state.percentage_check >= 0
  );
  const [condition, setCondition] = useState(
    state.percentage_check
  );
  useEffect(() => {
    setData({
      name: state.name,
      doc: state.document || "",
      iva: state.iva || 0,
      price: state.price,
      supervise: state.supervise || false,
      percentage_check: state.percentage_check || 0,
      supervise_price: state.supervise_price || 0,
      vars: state.vars,
      lists: state.lists,
    });
    
    setSelect(state.supervise);
    setSelectPrice(state.supervise && state.percentage_check );
    setCondition(state.percentage_check);
    return () => {};
  }, [state]);

  const [showList, setShowList] = useState(false);
  const [catego, setCatego] = useState({
    title: "",
    selectCate: [...categories],
    all: [],
  });
  const [validartion, setValidation] = useState(false);
  const [loading, setLoading] = useState(false);
  const [variable, setVariable] = useState([]);
  const [listsEdit, setListsEdit] = useState([]);
  const [stateList, setStateList] = useState([]);
  // const [dataCat, setDataCat] = useState([]);
  // const [varsSelect, setVarsSelect] = useState({});
  // const [listSelect, setListSelect] = useState({});

  const ModShowList = () => setShowList(!showList);

  // const SaveInfo = (newC) => {
  //   setData({ ...data, doc: newC });
  //   setContent(newC);
  // };

  const handleChangeCheckbox = (e, value) => {
    if (e.target.checked && value === "Si") {
      setData({ ...data, [e.target.name]: true });
      setSelect(true);
      setCondition(true);
    } else {
      setSelect(false);
      setData({ ...data, [e.target.name]: false });
      setData({ ...data, supervise_price: 0 });
    }
  };

  const handleChange = (e) => {
    let name = e.target.name,
      value = e.target.value,
      type = e.target.type || "text";
    if (type === "number") {
      if (value === "") value = "0";
      value = parseFloat(value).toString();
    }
    setData({ ...data, [name]: value });
  };

  const handleChangeVars = (e) => {
    setData({
      ...data,
      vars: { ...data.vars, [e.target.name]: e.target.value },
    });
  };
  const handleChangeList = ({ value, name }) => {
    setData({
      ...data,
      lists: { ...data.lists, [name]: value },
    });
  };
  const onValidation = (event, doc) => {
    doc = doc === undefined ? data.dataDocument : doc;
    setVariable([]);
    setListsEdit([]);
    event.preventDefault();
    setLoading(true);
    UserService.validationDocument(doc)
      .then((response) => {
        setVariable(response.data.vars);
        setListsEdit(response.data.lists);
        setValidation(true);
        setLoading(false);
      })
      .catch((err) => {
        setValidation(false);
        setLoading(false);
        console.error(err);
      });

    let stateArray = [];
    dispatch(getListDinamic()).then((response) => {
      for (const key in response) {
        stateArray.push({ value: key, label: response[key].name });
      }
      setStateList(stateArray);
    });
  };

  const handleChangeCheckPor = (e, value) => {
    if (e.target.checked && value === "Si") {
      setCondition(1);
      setData({ ...data, [e.target.name]: true });
      setSelectPrice(true);
    } else if (e.target.checked && value === "No") {
      setSelectPrice(true);
      setCondition(0);
      setData({ ...data, [e.target.name]: false });
    }
  };

  const handleSubmit = (e, id, doc, document, vars, lists) => {
    e.preventDefault();
    doc.price = doc.price === "" ? state.price : doc.price;
    document = document === undefined ? data.dataDocument : document;
    let categorias = doc.categories === undefined ? categories : doc.categories;

    const categoriesOBJ = {};
    categorias.forEach((cate) => (categoriesOBJ[cate] = parseInt(cate)));
    categorias = Object.values(categoriesOBJ);
    vars = vars === undefined ? state.vars : vars;
    lists = lists === undefined ? state.lists : lists;
    if (parseFloat(`${doc.price}`.replace(/,/g, "")) < 1000) return;
    UserService.editDocuments(
      id,
      doc.name === "" ? state.name : doc.name,
      document,
      `${doc.price}`?.replace(/,/g, ""),
      doc.supervise,
      doc.percentage_check,
      doc.supervise_price,
      categorias,
      vars,
      lists,
      data.iva
    )
      .then((response) => {
        setModalData({
          title: "Excelente",
          body: response.data.message,
          border: "bg-success",
        });
        setrefreshData(!refreshData);
        ModDelCateSate();
        ModAlertMod();
      })
      .catch((error) => {
        console.error(error.response);
        setModalData({
          title: "Lo sentimos",
          body: "No se pudo editar la plantilla.\n" + error.response,
          border: "bg-danger",
        });
        ModDelCateSate();
        ModAlertMod();
      });
    setrefreshData(!refreshData);
  };

  const OpenModalCat = (e, title, categorie, view) => {
    let lists = [];
    if (categorie) {
      lists = Object.values(categorie);
    }
    setCatego({
      title: title,
      selectCate: lists,
      viewList: view,
    });
    ModShowList();
  };

  return (
    <Modal show={showDelMod} id="ModalDelete" onHide={ModDelCateSate} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Modal.Body>
          <FormGroup className="fields__new">
            <Label>Nombre del template</Label>
            {isEdit ? (
              <Form.Control
                type="text"
                name="name"
                onChange={(e) => handleChange(e)}
                placeholder={data.name}
                defaultValue={data.name}
              ></Form.Control>
            ) : (
              <Form.Control
                type="text"
                value={data.name}
                disabled
              ></Form.Control>
            )}
          </FormGroup>
          <FormGroup className="fields__new">
            <Label>Precio del documento</Label>
            {isEdit ? (
              <>
                <CurrencyFormat
                  thousandSeparator={true}
                  name="price"
                  onChange={(e) => handleChange(e)}
                  placeholder={data.price}
                />
                <label className="m-1">*min 10,000</label>
              </>
            ) : (
              <Form.Control
                type="number"
                value={data.price}
                disabled
              ></Form.Control>
            )}
          </FormGroup>
          <FormGroup className="fields__new"></FormGroup>
          <Label>IVA</Label>
          {isEdit ? (
            <div className="input-container">
              <i className="fa fa-dollar-sign icon"></i>
              <CurrencyFormat
                className="input-field"
                thousandSeparator={true}
                name="iva"
                value={data.iva}
                onChange={(e) => handleChange(e)}
              />
            </div>
          ) : (
            <Form.Control
              type="number"
              value={data.iva}
              disabled
            ></Form.Control>
          )}
          <FormGroup className="fields__new">
            <Label>Estado</Label>
            <Form.Control
              type="text"
              value={data.status}
              disabled
            ></Form.Control>
          </FormGroup>
          {/*
            state.supervise === 1 ? (
            <FormGroup className="fields__new">
              <Label>Pago por la supervicion</Label>
              {state.percentage__check === 0 ? (
                  <Form.Control
                    type="text"
                    placeholder={"$" + state.supervise_price}
                    disabled={!isEdit}
                  ></Form.Control>
              ) : isEdit ? (
                <Form.Control
                  type="text"
                  placeholder={state.supervise_price + "%"}
                ></Form.Control>
              ) : (
                <Form.Control
                  type="text"
                  value={state.supervise_price + "%"}
                  disabled
                ></Form.Control>
              )}
            </FormGroup>

          ) : <>
              <FormGroup className="fields__new">
                <Label>¿Necesitas Supervisor? </Label>
                <input
                  type="checkbox"
                  name="supervise"
                  disabled={!isEdit}
                  onClick={(e) => handleChangeCheckbox(e, "Si")}
                ></input>
              </FormGroup>
              {select && (
                <FormGroup className="fields__new">
                  <Label>¿Vas a pagar con % del valor del documento? </Label>
                  <label>
                    Si{" "}
                    <input
                      type="radio"
                      name="percentage_check"
                      onClick={(e) => handleChangeCheckPor(e, "Si")}
                    />{" "}
                    No{" "}
                    <input
                      type="radio"
                      name="percentage_check"
                      onClick={(e) => handleChangeCheckPor(e, "No")}
                    />{" "}
                  </label>
                </FormGroup>
              )}
              {selectPrice && select && (
                <>
                  {condition === 1 && (
                    <FormGroup>
                      <label>Porcentaje a pagar al supervisor</label>
                      <div className="input-container">
                        <i className="fas fa-percentage icon"></i>
                        <input
                          className="input-field"
                          type="number"
                          name="supervise_price"
                          onChange={(e) => handleChange(e)}
                          required
                        />
                      </div>
                    </FormGroup>
                  )}
                  {condition === 0 && (
                    <FormGroup>
                      <label>Valor total a pagar al supervisor</label>
                      <div className="input-container">
                        <i className="fa fa-dollar-sign icon"></i>
                        <CurrencyFormat
                          className="input-field"
                          name="supervise_price"
                          thousandSeparator={true}
                          onChange={(e) => handleChange(e)}
                        />
                      </div>
                    </FormGroup>
                  )}
                </>
              )}
            </>
            */}

          <>
            <FormGroup className="fields__new">
              <Label>¿Necesitas Supervisor? </Label>
              <input
                type="checkbox"
                name="supervise"
                disabled={!isEdit}
                checked={select}
                onClick={(e) => handleChangeCheckbox(e, "Si")}
              ></input>
            </FormGroup>
            {select && (
              <FormGroup className="fields__new">
                <Label>¿Vas a pagar con % del valor del documento? </Label>
                <label>
                  Si{" "}
                  <input
                    type="radio"
                    name="percentage_check"
                    disabled={!isEdit}
                    checked={condition}
                    onClick={(e) => handleChangeCheckPor(e, "Si")}
                  />{" "}
                  No{" "}
                  <input
                    type="radio"
                    name="percentage_check"
                    disabled={!isEdit}
                    checked={!condition}
                    onClick={(e) => handleChangeCheckPor(e, "No")}
                  />{" "}
                </label>
              </FormGroup>
            )}
            {(selectPrice && select) && (
              <>
                {condition && (
                  <FormGroup>
                    <label>Porcentaje a pagar al supervisor</label>
                    <div className="input-container">
                      <i className="fas fa-percentage icon"></i>
                      <input
                        disabled={!isEdit}
                        className="input-field"
                        type="number"
                        name="supervise_price"
                        value={data.supervise_price}
                        onChange={(e) => handleChange(e)}
                        required
                      />
                    </div>
                  </FormGroup>
                )}
                {!condition && (
                  <FormGroup>
                    <label>Valor total a pagar al supervisor</label>
                    <div className="input-container">
                      <i className="fa fa-dollar-sign icon"></i>
                      <CurrencyFormat
                        type="number"
                        disabled={!isEdit}
                        className="input-field"
                        name="supervise_price"
                        thousandSeparator={true}
                        value={data.supervise_price}
                        onChange={(e) => handleChange(e)}
                      />
                    </div>
                  </FormGroup>
                )}
              </>
            )}
          </>
          <FormGroup className="fields__new">
            <Label>Categorias</Label>
            {isEdit ? (
              <Button
                onClick={(e) =>
                  OpenModalCat(
                    e,
                    "Selecciona categorias",
                    categoriesSelect,
                    false
                  )
                }
              >
                Seleccionar otras categorias
              </Button>
            ) : categoriesSelect ? (
              <Button
                onClick={(e) =>
                  OpenModalCat(
                    e,
                    "Categorias seleccionadas",
                    categoriesSelect,
                    true
                  )
                }
              >
                Categorias seleccionadas
              </Button>
            ) : (
              <Button color="danger">No hay categorias seleccionadas</Button>
            )}
          </FormGroup>
          <FormGroup className="fields__new">
            <Label>Template creado</Label>
            {isEdit ? (
              <>
                {/* <JoditEditor
                  ref={editor}
                  value={dataDocument}
                  onChange={(newContet) => {
                  }}
                /> */}
                <SunEditor
                  ref={editor}
                  onChange={(newContet) => {
                    handleChange({ target: { value: newContet, name: "doc" } });
                  }}
                  defaultValue={data.doc}
                  setAllPlugins={true}
                  setOptions={{
                    buttonList: [
                      ["undo", "redo"],
                      ["font", "fontSize", "formatBlock"],
                      ["paragraphStyle", "blockquote"],
                      [
                        "bold",
                        "underline",
                        "italic",
                        "strike",
                        "subscript",
                        "superscript",
                      ],
                      ["fontColor", "hiliteColor", "textStyle"],
                      ["removeFormat"],
                      "/", // Line break
                      ["outdent", "indent"],
                      ["align", "horizontalRule", "list", "lineHeight"],
                      [
                        "table",
                        "link",
                        "image",
                        "video",
                        "audio" /** ,'math' */,
                      ], // You must add the 'katex' library at options to use the 'math' plugin.
                      /** ['imageGallery'] */ // You must add the "imageGalleryUrl".
                      ["fullScreen", "showBlocks", "codeView"],
                      ["preview", "print"],
                      ["save", "template"],
                      /** ['dir', 'dir_ltr', 'dir_rtl'] */ // "dir": Toggle text direction, "dir_ltr": Right to Left, "dir_rtl": Left to Right
                    ],
                  }}
                />

                {/* <RichText
                  setInfo={setData}
                  info={data}
                  place={state.document}
                /> */}
              </>
            ) : (
              <div
                className="info__template__doc"
                dangerouslySetInnerHTML={{ __html: data.doc }}
              ></div>
            )}
          </FormGroup>
          {isEdit ? (
            <section className="content">
              <div className="container-fluid">
                <div className="validations__text">
                  <Button
                    color="warning"
                    onClick={(e) => onValidation(e, data.doc)}
                    disabled={loading}
                  >
                    {loading ? (
                      <span className="spinner-border spinner-border-sm"></span>
                    ) : (
                      <span>Validar Documento</span>
                    )}
                  </Button>
                </div>
                {validartion ? (
                  <div className="info__adicional">
                    <div className="variable__select">
                      <h6>Variables creadas</h6>
                      <div className="table__variable">
                        <table className="table table-light table-striped">
                          <thead className=" ">
                            <tr className="">
                              <th scope="col">Nombre</th>
                              <th scope="col">Tipo</th>
                            </tr>
                          </thead>
                          <tbody>
                            {variable[0] === null
                              ? ""
                              : variable.map((vars, i) => {
                                  return (
                                    <tr key={i}>
                                      <td>{vars}</td>
                                      <td>
                                        {" "}
                                        <FormControl fullWidth>
                                          <InputLabel id="demo-simple-select-label">
                                            Variables
                                          </InputLabel>
                                          <CSelect
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={data?.vars?.[vars] || ""}
                                            name={vars}
                                            label={vars}
                                            onChange={handleChangeVars}
                                          >
                                            <MenuItem value="" selected hidden>
                                              Selecciona un tipo
                                            </MenuItem>
                                            <MenuItem value="String">
                                              Texto
                                            </MenuItem>
                                            <MenuItem value="Integer">
                                              Numero
                                            </MenuItem>
                                            <MenuItem value="Float">
                                              Numero con decimales
                                            </MenuItem>
                                            <MenuItem value="Date">
                                              Fecha
                                            </MenuItem>
                                            <MenuItem value="TextArea">
                                              Texto Largo
                                            </MenuItem>
                                            <MenuItem value="Checkbox">
                                              Si o no
                                            </MenuItem>
                                          </CSelect>
                                        </FormControl>
                                      </td>
                                    </tr>
                                  );
                                })}
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <div className="variable__select">
                      <h6>Listas dinamicas</h6>
                      <div className="table__variable">
                        <table className="table table-light table-striped">
                          <thead className=" ">
                            <tr className="">
                              <th scope="col">Nombre</th>
                              <th scope="col">Lista Disponibles</th>
                            </tr>
                          </thead>
                          <tbody>
                            {listsEdit[0] === null
                              ? ""
                              : listsEdit.map((list, i) => {
                                  return (
                                    <tr key={`dinamic_list_edit_${i}`}>
                                      <td>{list}</td>
                                      <td>
                                        {stateList.length > 0 && (
                                          <Select
                                            onChange={(e) => {
                                              handleChangeList({
                                                value: e.value,
                                                name: list,
                                              });
                                            }}
                                            styles={{
                                              control: (styles) => ({
                                                ...styles,
                                                backgroundColor: "transparent",
                                              }),
                                            }}
                                            options={stateList}
                                          />
                                        )}
                                      </td>
                                    </tr>
                                  );
                                })}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                ) : (
                  <></>
                )}
              </div>
            </section>
          ) : (
            <>
              {vars ? (
                <FormControl fullWidth>
                  <InputLabel id="selected-variables">
                    Variables creadas
                  </InputLabel>
                  <CSelect labelId="selected-variables" onChange={() => {}}>
                    {Object.keys(vars).map((varaible, i) => (
                      <MenuItem value="varaible" key={`selecterVar${i}`}>
                        {varaible}
                      </MenuItem>
                    ))}
                  </CSelect>
                </FormControl>
              ) : (
                ""
              )}
              {lists ? (
                <FormControl fullWidth>
                  <InputLabel id="selected-variables">
                    Listas creadas
                  </InputLabel>
                  <CSelect labelId="selected-variables" onChange={() => {}}>
                    {Object.keys(lists).map((lis, i) => (
                      <MenuItem value="lis" key={`selecterVar${i}`}>
                        {lis}
                      </MenuItem>
                    ))}
                  </CSelect>
                </FormControl>
              ) : (
                ""
              )}
            </>
          )}
        </Modal.Body>
      </Modal.Body>
      <Modal.Footer>
        {isEdit && (
          <Button
            type="submit"
            color="success"
            id="btn_dele_move_move"
            onClick={(e) => {
              handleSubmit(
                e,
                idTemplate,
                data,
                data.doc,
                data.vars,
                data.lists
              );
            }}
          >
            Guardar Cambios
          </Button>
        )}
        <Button
          type="submit"
          color="info"
          id="btn_dele_move_move"
          onClick={ModDelCateSate}
        >
          Cerrar
        </Button>
      </Modal.Footer>

      <ListCat
        showDelMod={showList}
        setshowDelMod={setShowList}
        title={catego.title}
        selectCat={catego}
        setSelectCat={setCatego}
        viewList={catego.viewList}
        info={data}
        setInfo={setData}
      />
    </Modal>
  );
};

export default ShowTemplate;
