import React, { useState } from "react";
import { Card, CardBody, CardHeader, Button, Row } from "reactstrap";

import "../assets/styles/components/Loading.scss";
import { searchElementByName } from "../utils/searchElementByName";

const AddFields = (props) => {
  const { state, OpenModalNew, OpenModalEdit, OpenModalDelete } = props;

  const [transfor, setTransform] = useState(false);
  const [data, setData] = useState([]);
  const [classinput, setClass] = useState({
    butn: "btn-shadow btn btn-dark",
  });

  const useSearchDocuemnt = (event) => {
    const value = searchElementByName(state,event.target.value);

    if (event.target.value === "") {
      setData([]);
    } else if (value.length !== 0) {
      setData(value);
    }
  };

  const handleClickSearch = () => {
    setTransform(true);
    setClass({ butn: "btn-shadow btn btn-dark btn-border" });
  };

  const handleClickClose = () => {
    setTransform(false);
    setClass({ butn: "btn-shadow btn btn-dark" });
  };
  return (
    <>
      <div className="panel__control">
        <main className="main__fields">
          <div className="header__field">
            <div className="image__list">
              <i className="far fa-list-alt"></i>
            </div>
            <div className="text__information">
              <h4>Lista Dinamicas</h4>
            </div>
            <div className="create__or__search active">
              <div className="input-search d-flex">
                {transfor ? (
                  <>
                    <button
                      className={classinput.butn}
                      onClick={(e) => handleClickClose()}
                    >
                      <i className="fas fa-times"></i>
                    </button>
                    <input
                      type="text"
                      className="search-input"
                      placeholder="Buscar lista"
                      onChange={useSearchDocuemnt}
                    />
                  </>
                ) : (
                  <button
                    className={classinput.butn}
                    onClick={(e) => handleClickSearch()}
                  >
                    <i className="fas fa-search"></i>
                  </button>
                )}
              </div>
              <Button onClick={(e) => OpenModalNew(e)} className="btn-search">
                <i className="fas fa-plus"></i>
                <span className="ml-3">Nueva Lista</span>
              </Button>
            </div>
          </div>
          <div className="list__dynamic">
            <div className="list__dynami">
              {data.length > 0
                ? data.map((data, index) => (
                    <Card className="shadow" key={index}>
                      <CardHeader className="border-0">
                        <Row>
                          <div className="col">
                            <h3 className="mb-0">{data.name.substr(0, 15)}</h3>
                          </div>
                        </Row>
                        <Row>
                          <div className="col">
                            Descripcion: {data.description.substr(0, 30)}
                          </div>
                        </Row>
                      </CardHeader>
                      <CardBody className="mt--4">
                        <Row className="col">
                          <Button
                            className="mr-4 shadow btn-circle"
                            color="info"
                            size="sm"
                            onClick={(e) =>
                              OpenModalEdit(e, data.name, data.id)
                            }
                          >
                            <i className="fas fa-eye"></i>
                          </Button>

                          <Button
                            className="mr-4 shadow btn-circle"
                            color="danger"
                            size="sm"
                            onClick={(e) =>
                              OpenModalDelete(e, data.name, data.id)
                            }
                          >
                            <i className="far fa-trash-alt white"></i>
                          </Button>
                        </Row>
                      </CardBody>
                    </Card>
                  ))
                : state.map((data, index) => (
                    <Card className="shadow" key={index}>
                      <CardHeader className="border-0">
                        <Row>
                          <div className="col">
                            <h3 className="mb-0">{data.name.substr(0, 23)}</h3>
                          </div>
                        </Row>
                        <Row>
                          <div className="col">
                            Descripcion: {data.description.substr(0, 30)}
                          </div>
                        </Row>
                      </CardHeader>
                      <CardBody className="mt--4">
                        <Row className="col">
                          <Button
                            className="mr-4 shadow btn-circle"
                            color="info"
                            size="sm"
                            onClick={(e) =>
                              OpenModalEdit(e, data.name, data.id)
                            }
                          >
                            <i className="fas fa-eye"></i>
                          </Button>

                          <Button
                            className="mr-4 shadow btn-circle"
                            color="danger"
                            size="sm"
                            onClick={(e) =>
                              OpenModalDelete(e, data.name, data.id)
                            }
                          >
                            <i className="far fa-trash-alt white"></i>
                          </Button>
                        </Row>
                      </CardBody>
                    </Card>
                  ))}
            </div>
          </div>
        </main>
      </div>
    </>
  );
};

export default AddFields;
