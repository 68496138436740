import React, { useEffect, useRef, useState } from "react";
import moment from "moment";
import { Button } from "reactstrap";

import "../assets/styles/components/Profile.scss";

import UserService from "../service/user.service";
import PreviewDocument from "./modals/PreviewDocument";
import FirmDocumentModal from "./modals/FirmDocumentModal";

const ExternalDocumentsProfile = ({
  selectedDocument,
  state = [],
  modalShow,
}) => {
  const firmModal = useState(false);
  const [showDelMod, setshowDelMod] = useState(false);
  const [firmDoc, setFirmDoc] = useState({
    id: "",
  });
  useEffect(() => {
    if(selectedDocument) {
      const document = state.find(doc=>(doc.id===selectedDocument))
      OpenModalFirm(document)
    }
  
    return () => {
      
    }
  }, [state,selectedDocument])
  
  const refPage = useRef(null);
  const [previewDocumentModal, setPreviewDocumentModal] = useState(false);

  const ModDelCateSate = () => setshowDelMod(!showDelMod);

  const OpenModalDelete = (doc) => {
    ModDelCateSate();
  };
  const handlePreview = async (doc) => {
    const documentFile = await UserService.getDocumentFirebase(
      doc.firebase_document_id
    );
    setPreviewDocumentModal(documentFile.document);
  };
  const OpenModalFirm = (doc) => {
    setFirmDoc({ ...doc });
    firmModal[1](true);
  };

  const downloadDocument = async (doc) => {
    try {
      const link = await UserService.getDocumentStorageBlob(
        doc.document_number
      );
      var downloadLink = document.createElement("a");
      downloadLink.href = link;
      downloadLink.download = doc.name + ".pdf";
      downloadLink.target = "_blank";

      refPage.current.appendChild(downloadLink);
      downloadLink.click();
      refPage.current.removeChild(downloadLink);
      downloadLink.remove();
    } catch (error) {
      console.error(error);
    }
  };
  return (
    <>
      <div ref={refPage} className="show_detail">
        <div id="divToPrint" className="table-responsive">
          <h2 className="mb-3 text-center">MIS FIRMAS EXTERNAS</h2>
          <hr />
          {!modalShow ? (
            state.length > 0 ? (
              <table className="table table-light table-striped">
                <thead id="dataTableHead">
                  <tr className="head-table">
                    <th scope="col">id</th>
                    <th scope="col">Nombre del documento</th>
                    <th scope="col">Estado</th>
                    <th scope="col">Fecha de Creación</th>
                    <th scope="col">Acción</th>
                  </tr>
                </thead>
                <tbody className="">
                  {state.map((doc, i) => {
                    return (
                      <tr key={i}>
                        <td>{doc.id}</td>
                        <td>{doc.name ? doc.name : "Sin Nombre"}</td>
                        <td>
                          {doc.state === "pagado"
                            ? "Pagado"
                            : doc.state === "necesita_firma"
                            ? "Falta firma"
                            : doc.state === "por_firmar"
                            ? "Firma"
                            : "Borrador"}
                        </td>
                        <td>
                          {doc.created_at
                            ? moment(doc.created_at).format("MMMM Do YYYY")
                            : "No tiene fecha de creación"}
                        </td>
                        {doc.state !== "pagado" &&
                        doc.state !== "necesita_firma" &&
                        doc.state !== "por_firmar" ? (
                          <td>

                            <i
                              className="far fa-trash-alt pl-2 icon_option"
                              onClick={(e) => OpenModalDelete(doc)}
                            ></i>
                          </td>
                        ) : (
                          <td>

                            {doc.state === "pagado" ? (
                              <i
                                className="fas fa-file-pdf pl-2 icon_option"
                                onClick={() => downloadDocument(doc)}
                              ></i>
                            ) : (
                              <i
                                className="fas fa-pen-nib pl-2 icon_option"
                                onClick={() => {
                                  OpenModalFirm(doc);
                                }}
                              ></i>
                            )}
                          </td>
                        )}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            ) : (
              <div className="container">
                <div className="body-style">
                    <div className="text__4">No tienes Documentos creados</div>
                </div>
              </div>
            )
          ) : (
            <div className="d-flex justify-content-center m-auto">
              {/* <Spinner animation="border" variant="primary" /> */}
            </div>
          )}
        </div>
      </div>
      <PreviewDocument
        documentHook={[previewDocumentModal, setPreviewDocumentModal]}
        title="Previsualización"
      />
      <FirmDocumentModal
        showHook={firmModal}
        dataHook={[firmDoc, setFirmDoc]}
      />
    </>
  );
};

export default ExternalDocumentsProfile;
