import React, { useState } from "react";
import AddFields from "../components/AddFields";

import { postList } from "../actions/auth";
import { useDispatch } from "react-redux";

import ModalViewList from "../components/modals/ViewList";
import Modaldelete from "../components/modals/Delete";
import ModalAcountAdd from "../components/modals/NewFields";
import AlertDialogSlide from "../components/modals/AlertDialogSlide";

const ListEdit = (props) => {
  const { state = [
    {
      name: "",
      description: "",
    }
  ], loader, refreshData, setrefreshData } = props;
  const defaultState = {
    name: "",
    description: "",
  };

  const dispatch = useDispatch();

  // const logOut = () => {
  //   dispatch(logout());
  //   setClose(true);
  // };

  const [select, setSelect] = useState(false);
  const [idList, setIdList] = useState("");
  const [rows, setRows] = useState([...state]);
  const [elementView, setView] = useState({});
  const [, setSateAlert] = useState({
    visible: false,
    code: 200,
    dataAlert: "",
  });

  const [modalData, setModalData] = useState({
    title: "",
    body: "",
    border: "",
  });

  const [showAlertMod, setshowAlertMod] = useState(false);
  const ModAlertMod = () => setshowAlertMod(!showAlertMod);
  
  const [stateformDelete, setformDelete] = useState({
    id: "",
    name: "",
    description: "",
    items: [],
  });
  const [stateform, setform] = useState({
    name: "",
    description: "",
    items: [],
  });

  const [showNewMod, setshowNewMod] = useState(false);
  const [showDelMod, setshowDelMod] = useState(false);
  const [showViewMod, setshowViewMod] = useState(false);

  // Funcion para cambiar de estado de los modals
  const ModNewCateSate = () => setshowNewMod(!showNewMod);
  const ModDelCateSate = () => setshowDelMod(!showDelMod);
  const ModViewCateSate = () => setshowViewMod(!showViewMod);

  // Accion al abrir los modals
  const OpenModalNew = (e) => {
    e.preventDefault();
    ModNewCateSate();
  };

  const OpenModalDelete = (e, name, id) => {
    e.preventDefault();
    setformDelete({ name: name, id: id });
    ModDelCateSate();
  };
  const OpenModalView = (e, name, id) => {
    e.preventDefault();
    if (id.length > 0) {
      setIdList(id);
    }
    
    const selected = state.find(element => element?.id === id)
    selected && setView(selected);
    ModViewCateSate();
  };

  const handleSubmit = (event, name, descripción, item) => {
    event.preventDefault();
    dispatch(postList(name, descripción, item.items))
      .then((response) => {
        setModalData({
          title: "Felicidades",
          body: response.message,
          border: "bg-success",
        });
        ModNewCateSate();
        ModAlertMod();
        setrefreshData(!refreshData);
      })
      .catch((error) => {
        setModalData({
          title: "Lo sentimos",
          body:
            "No se realizo la creación solicitada.\n" + error.response.message,
          border: "bg-danger",
        });
        ModNewCateSate();
        ModAlertMod();
      });
  };
  const handleOnChange = (e) => {
    setform({ ...stateform, [e.target.name]: e.target.value });
  };

  const handleChange = (index, name, value) => {
    const copyRows = [...rows];
    copyRows[index] = {
      ...copyRows[index],
      [name]: value,
    };
    setRows(copyRows);
    setform({ ...stateform, items: rows });
  };

  return (
    <>
      <AddFields
        state={state}
        loader={loader}
        OpenModalNew={OpenModalNew}
        OpenModalEdit={OpenModalView}
        OpenModalDelete={OpenModalDelete}
        setSateAlert={setSateAlert}
      />

      <ModalAcountAdd
        defaultState={defaultState}
        select={select}
        setSelect={setSelect}
        rows={rows}
        setRows={setRows}
        setform={setform}
        showNewMod={showNewMod}
        ModNewCateSate={ModNewCateSate}
        handleSubmit={handleSubmit}
        handleChange={handleChange}
        handleOnChange={handleOnChange}
        stateform={stateform}
      />

      <Modaldelete
        action="account"
        title="Eliminar lista dinamica"
        message={
          "¿Estas seguro de eliminar la lista dinamica: " +
          stateformDelete.name +
          "?"
        }
        refreshData={refreshData}
        setrefreshData={setrefreshData}
        state={stateformDelete}
        showDelMod={showDelMod}
        setshowDelMod={setshowDelMod}
        ModAlertMod={ModAlertMod}
        setModalData={setModalData}
      />

      <ModalViewList
        ModViewCateSate={ModViewCateSate}
        showViewMod={showViewMod}
        elementView={elementView}
        idList={idList}
      />

      <AlertDialogSlide
        modaldata={modalData}
        show={showAlertMod}
        onHide={() => ModAlertMod()}
      />
    </>
  );
};

export default ListEdit;
