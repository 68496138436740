/* eslint-disable no-useless-escape */
/**
 *
 * @param {String} stringLet string To Capitalize
 * @returns String
 */
export const toCapitalizeFirstLetter = (stringLet) => {
  const first = stringLet[0];
  const Rest = stringLet.substring(1).toLocaleLowerCase();
  return first.toUpperCase() + Rest;
};

/**
 *
 * @param {String} stringLet Sentence to Capitalize
 * @returns String
 */
export const toCapitalize = (stringLet) => {
  const palabras = stringLet.split(" ");
  const finalString = [];
  palabras.forEach((palabra) => {
    finalString.push(toCapitalizeFirstLetter(palabra));
  });
  return finalString.join(" ");
};

/**
 * 
 * @param {String|Number} value the number that its gonna be formatead
 * @returns String
 */
export function formatNumbersDot(value,decimals) {
  var [num,dec] = `${value.toFixed(decimals)}`.split(".");
  num = `${num}`.replace(/\,/g, "");
  if (!isNaN(num)) {
    num = num
      .toString()
      .split("")
      .reverse()
      .join("")
      .replace(/(?=\d*\,?)(\d{3})/g, "$1,");
    num = num.split("").reverse().join("").replace(/^[\,]/, "");
    return `${num}.${dec}`;
  } else {
    //alert("Solo se permiten numeros");
    return `${value}`
  }
}

/**
 * 
 * @param {Int} length 
 * @returns 
 */
export const randomString= (length=4) => {
  let result = ''
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
  for (let i = 0; i < length; i++ ) {
    result += characters.charAt(Math.floor(Math.random() * characters.length));
 }
 return result;
}

export const removeAccents = (str) => {
  return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
} 