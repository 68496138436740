import React, { useState } from "react";
import { Redirect } from "react-router-dom";

import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";

import { useDispatch } from "react-redux";
import { logout } from "../actions/auth";

import "../assets/styles/components/Header.scss";
import "../assets/styles/components/Navigation.scss";
import ImageIzq from "../assets/static/vector-fondo1.png";
import Logo from "../assets/static/logo-yo-litigo-01.png";
import ImageDer from "../assets/static/DerPanel.png";

const Header = (props) => {
  const {
    isHome,
    isLogged,
    isLoginAdmin,
    isHomeAdmin,
    setSideNav,
    handleClose,
    sideNav,
    name,
  } = props;

  const dispatch = useDispatch();
  const [close, setClose] = useState(false);
  const logOut = () => {
    dispatch(logout());
    setClose(true);
  };

  return (
    <>
      {close && <Redirect to="/login" />}
      {isHome && (
        <header className="img__home">
          <img src={ImageIzq} alt="" />
        </header>
      )}

      {isLoginAdmin && (
        <header className="img__homeAdmin">
          <img src={ImageIzq} alt="" />
          <a href="/login">
            <img className="logo__admin" src={Logo} alt="" />
          </a>
        </header>
      )}

      {isHomeAdmin && (
        <nav className="sb-topnav navbar navbar-expand navbar-dark bg-dark">
          <a href="" className="navbar-brand">
            {" "}
            <img className="admin__board" src={Logo} alt="" />
          </a>
          <div onClick={() => handleClose(sideNav)}>
            <button
              id="sidebarToggle"
              className="btn btn-link btn-sm order-1 order-lg-0 sidebargle"
              onClick={() => setSideNav(true)}
            >
              <i className="fas fa-bars"></i>
            </button>
          </div>
          {/* Navbar Search */}
          <form
            action=""
            className="d-none d-md-inline-block form-inline ml-auto mr-0 mr-md-3 my-2 my-md-0"
          >
            <div className="input-groug d-flex search__data">
              <input
                type="text"
                placeholder="Buscar por"
                aria-label="Search"
                aria-describedby="basic-addon2"
                className="form-control search__input"
              />
              <div className="input-group-append">
                <button className="btn btn-primary btn__search" type="button">
                  <i className="fas fa-search"></i>
                </button>
              </div>
            </div>
          </form>

          {/*Navbar */}
          <UncontrolledDropdown className="button__info" >
            <DropdownToggle caret className="dropwon__list">
              <i className="fas fa-user fa-fw"></i>
            </DropdownToggle>
            <DropdownMenu className="menu__info" >
              <DropdownItem>Hola, {name}!</DropdownItem>
              <DropdownItem>Actualizar Datos</DropdownItem>
              <DropdownItem divider />
              <DropdownItem onClick={() => logOut()}>
                Cerrar Sesión
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        </nav>
      )}

      {isLogged && (
        <header className="d-flex header__panel">
          <div className="img__logo">
            <img src={Logo} alt="" />
          </div>

          <div className="create__panel">
            <div className="text_create"></div>
          </div>

          <div className="img__izq">{<img src={ImageDer} alt="" />}</div>
        </header>
      )}
    </>
  );
};

export default Header;
