import React, { useState, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, Redirect } from "react-router-dom";

import "../assets/styles/components/Login.scss";

import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";

import ImageLogin from '../assets/static/login-1.png';
import Logo from '../assets/static/logo-yo-litigo-01.png'

import {
  SET_MESSAGE,
} from "../actions/types";

import { login } from "../actions/auth";
import Header from "./Header";
import Footer from "./Footer";
import useToast from "../utils/CustomHooks/useToast";

const Login = (props) => {
  const form = useRef();
  const checkBtn = useRef();

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const addToast= useToast();

  const [loading, setLoading] = useState(false);
  const { isLoggedIn } = useSelector((state) => state.auth);
  const { message } = useSelector((state) => state.message);
  const  {typeUser}  = useSelector((state)=> state.auth);

  const dispatch = useDispatch();
  const onChangeUsername = (e) => {
    const username = e.target.value;
    setUsername(username);
  };

  const onChangePassword = (e) => {
    const password = e.target.value;
    setPassword(password);
  };
  const dispatchData  = ()=>{
    dispatch({
      type: SET_MESSAGE,
      payload: "",
    });
  }

  const handleSubmit = (e) => {
    
    e.preventDefault();

    setLoading(true);
    form.current.validateAll();

    if (checkBtn.current.context._errors.length === 0) {
      dispatch(login(username, password, false))
        .then((response) => {
          if(response.token){
            addToast({
              title:'Bienvenido!',
              mode:'primary'
            })
            props.props.history.push("/panel");
          }
          setLoading(false);
        })
        .catch(() => {
          addToast({
            title:'Error al ingresar',
            mode:'danger'
          })
          setLoading(false);
        });
    } else {
      addToast({
        title:'Error al ingresar',
        mode:'danger'
      })
      setLoading(false);
    }
  };

  useEffect(() => {

    if (isLoggedIn && typeUser === "user") {
      return <Redirect to="/panel" />;
    }
  }, [])


  return (
    <>
      <div className="login__page">

        <Header isHome/>
        <section className="form__login d-flex justify-content-center">
          <div className="login__body">
            <div className="login__info">
              <img src={ImageLogin} alt="" />
              <div className="formulario">
                <Form onSubmit={handleSubmit} ref={form}>
                  <div className="input-group form-group">
                    <Input
                      type="email"
                      className="form-control"
                      placeholder="Usuario"
                      value={username}
                      onChange={onChangeUsername}
                      required="required"
                    />
                  </div>
                  <div className="input-group form-group">
                    <Input
                      type="password"
                      className="form-control"
                      placeholder="Contraseña"
                      value={password}
                      onChange={onChangePassword}
                      required="required"
                    />
                  </div>

                  <div className="form-group">
                    <button
                      type="submit"
                      className="login_btn"
                      disabled={loading}
                    >
                      {loading ? (
                        <span className="spinner-border spinner-border-sm"></span>
                      ) : <span>Ingresar</span>}
                    </button>
                  </div>

                  <CheckButton style={{ display: "none" }} ref={checkBtn} />
                </Form>
                <div className="d-flex justify-content-center" style={{color:'white'}}>
                  <Link className="contra__login" to="/register" onClick={dispatchData}>Regístrate  </Link>
                  <span className="mr-2">o</span>
                  <Link className="contra__login" to={{pathname: "/reset-password",state: { isAdmin: false }}}> Recupera tu contraseña</Link>
                </div>
                {message && (
                  <div className="form-group">
                    <div className="alert alert-danger error__login" role="alert">
                      {message}
                    </div>
                  </div>
                )}

              </div>

            </div>

            <div className="logo__img">
              <img src={Logo} alt="" />
            </div>
          </div>
        </section>
        <Footer isHome/>
      </div>
    </>
  );
};

export default Login;
