import {
  CCol,
  CForm,
  CFormCheck,
  CFormInput,
  CFormSelect,
  CFormSwitch,
  CModal,
  CModalBody,
  CModalTitle,
  CRow,
} from "@coreui/react";
import { ReactComponent as CloseSVG } from "../../../assets/svg/cross.svg";
import React, { useEffect, useState } from "react";
import SignerInput from "./SignerInput";
import useArray from "../../../utils/CustomHooks/useArray";
import authService from "../../../service/auth.service";
import mercadoPagoOrdersApi from "../../../utils/mercadoPagoOrdersApi";
import { isUndefined } from "lodash";
import ZapSignInfo from "./ZapSignInfo";
import moment from "moment";
import { Spinner } from "reactstrap";
import useToast from "../../../utils/CustomHooks/useToast";

const steps = [1, 2, 3];
const emptySigner = {
  name: "",
  email: "",
  auth_mode: "assinaturaTela",
  send_automatic_email: true,
  send_automatic_whatsapp: false,
  phone_country: "+57",
  phone_number: "",
  lock_email: false,
  blank_email: false,
  hide_email: false,
  lock_phone: false,
  blank_phone: false,
  hide_phone: false,
  lock_name: false,
  require_selfie_photo: false,
  require_document_photo: false,
  selfie_validation_type: "liveness-document-match",
};

export function FormSigners({
  signers = [],
  advanceInputsHook = [],
  onRemoveSigner = () => {},
  onChangeSigner = () => {},
  onAddSigner = () => {},
}) {
  const [advancedInputs, setAdvancedInputs] = advanceInputsHook;
  return (
    <>
      <CRow>
        <CCol className="form_sign_header">
          <h5>Quien Firmara?</h5>
        </CCol>
        <CCol xs={12}>
          <CFormSwitch
            onClick={() => {
              setAdvancedInputs((prev) => !prev);
            }}
            label="Opciones avanzadas"
            id="formSwitchCheckChecked"
            checked={advancedInputs}
          />
        </CCol>
      </CRow>
      <CRow className="">
        {signers.map((signer, ind) => {
          return (
            <CCol xs="auto" key={`signer?key_${ind}`}>
              <SignerInput
                advanced={advancedInputs}
                id={`signer_${ind}`}
                values={signer}
                onRemove={
                  signers.length > 1
                    ? () => {
                        onRemoveSigner(ind);
                      }
                    : undefined
                }
                onChange={(values) => {
                  onChangeSigner({
                    index: ind,
                    ...values,
                  });
                }}
              />
            </CCol>
          );
        })}
      </CRow>
      <button
        className="btn_firm"
        type="button"
        onClick={() => {
          onAddSigner({ ...emptySigner });
        }}
      >
        <i className="fas fa-plus-circle"></i> Añadir firmante
      </button>
    </>
  );
}
export function FormConfigFirm({ values = {}, onChange = () => {} }) {
  const handleChangeCheck = (e) => {
    
    const { checked, name } = e.target;
    const dataToSave = {};
    dataToSave[name] = checked;
    onChange(dataToSave);
  };
  const handleChange = (e) => {
    const dataToSave = {};
    dataToSave[e.target.name] = e.target.value;
    onChange(dataToSave);
  };
  return (
    <>
      <CRow>
        <CCol className="form_sign_header">
          <h5>Configuración extra</h5>
        </CCol>
      </CRow>
      <CRow className="">
        <CFormCheck
          onChange={handleChangeCheck}
          name="reminder_active"
          checked={Boolean(values.reminder_active)}
          label=<div>
            Activar recordatorios cada
            <CFormSelect
              onChange={handleChange}
              className="clear-select ms-1"
              name="reminder_every_n_days"
              value={values.reminder_every_n_days}
              options={[
                { label: "1", value: 1 },
                { label: "5", value: 5 },
                { label: "10", value: 10 },
              ]}
            />{" "}
            días
          </div>
        />
        <CFormCheck
          onChange={handleChangeCheck}
          name="allow_refuse_signature"
          checked={Boolean(values.allow_refuse_signature)}
          label="Permitir Rechazar firma"
        />
        <CFormCheck
          onChange={handleChangeCheck}
          name="date_limit_active"
          checked={Boolean(values.date_limit_active)}
          label="Fecha Limite Para firmar"
        />
        {values.date_limit_active && (
          <div className="pe-5">
            <CFormInput
              type="date"
              min={moment().format("YYYY-MM-DD")}
              onChange={handleChange}
              name="date_limit_to_sign"
              value={values.date_limit_to_sign}
              id="exampleFormControlInput1"
              aria-describedby="exampleFormControlInputHelpInline"
            />
          </div>
        )}
      </CRow>
    </>
  );
}
//<CFormCheck onChange={handleChangeCheck} name='reminder_every_n_days' checked={Boolean(values.reminder_every_n_days)} label="Activar recordatorios" />

function FirmDocumentModal({
  showHook = [],
  dataHook = [],
  onFirmCreate = () => {},
}) {
  const [show, setShow] = showHook;
  const [data, setData] = dataHook;
  const [loading, setLoading] = useState(true);
  const [advancedInputs, setAdvancedInputs] = useState(false);
  const [configurationOptionValues, setConfigurationOptionValues] = useState({
    reminder_active: false,
    reminder_every_n_days: 1,
    allow_refuse_signature: false,
    date_limit_active: false,
    date_limit_to_sign: null,
  });
  const [zapSignData, setZapSignData] = useState(null);
  const [actualPage, setActualPage] = useState(0);
  const {
    array: signers_array,
    //set :  signers_set,
    push: signers_push,
    //filter :  signers_filter,
    update: signers_update,
    remove: signers_remove,
    //clear :  signers_clear
  } = useArray([{ ...emptySigner }]);
  const addToast = useToast();

  useEffect(() => {
    const requestDocumentStatus = async () => {
      
      if (data.state === "por_firmar") {
        const { data: response } = await mercadoPagoOrdersApi.get(
          `/zapsign/docs/${data.document_number}`
        );
        setZapSignData(response.data);
        setActualPage(steps.length - 1);
      }
      setLoading(false);
    };
    requestDocumentStatus();
    return () => {
      setZapSignData(null);
      setActualPage(0);
      setLoading(true);
    };
  }, [data]);

  const handleEditSigner = ({
    index,
    name,
    email,
    phone_country,
    phone_number,
    send_automatic_email,
    send_automatic_whatsapp,
    require_selfie_photo,
    require_document_photo,
  }) => {
    const copySigner = { ...signers_array[index] };
    if (!isUndefined(name)) copySigner.name = name;
    if (!isUndefined(email)) copySigner.email = email;
    if (!isUndefined(phone_country)) copySigner.phone_country = phone_country;
    if (!isUndefined(phone_number)) copySigner.phone_number = phone_number;
    if (!isUndefined(send_automatic_email))
      copySigner.send_automatic_email = send_automatic_email;
    if (!isUndefined(send_automatic_whatsapp))
      copySigner.send_automatic_whatsapp = send_automatic_whatsapp;
    if (!isUndefined(require_selfie_photo))
      copySigner.require_selfie_photo = require_selfie_photo;
    if (!isUndefined(require_document_photo))
      copySigner.require_document_photo = require_document_photo;
    signers_update(index, copySigner);
  };
  const handleEditConfig = ({
    reminder_active,
    date_limit_active,
    reminder_every_n_days,
    allow_refuse_signature,
    date_limit_to_sign,
  }) => {
    const toChangeValues = {};
    if (!isUndefined(reminder_active))
      toChangeValues.reminder_active = reminder_active;
    if (!isUndefined(date_limit_active))
      toChangeValues.date_limit_active = date_limit_active;
    if (!isUndefined(reminder_every_n_days))
      toChangeValues.reminder_every_n_days = reminder_every_n_days;
    if (!isUndefined(allow_refuse_signature))
      toChangeValues.allow_refuse_signature = allow_refuse_signature;
    if (!isUndefined(date_limit_to_sign))
      toChangeValues.date_limit_to_sign = date_limit_to_sign;
    setConfigurationOptionValues((prev) => ({ ...prev, ...toChangeValues }));
  };

  const handleRemove = (index) => {
    signers_remove(index);
  };

  const createFirm = async () => {
    //const link = await userService.getDocumentStorageBlob(data.document_number);
    let response;
    if (signers_array.filter((e) => Boolean(e.name)).length === 0) {
      return Promise.reject({
        msg: "falta agregar firmantes",
      });
    }
    if (data.state === "necesita_firma") {
      const configOptions = {
        reminder_every_n_days: parseInt(
          configurationOptionValues.reminder_every_n_days
        ),
        allow_refuse_signature:
          configurationOptionValues.allow_refuse_signature,
        date_limit_to_sign: configurationOptionValues.date_limit_to_sign,
      };
      if (!configurationOptionValues.reminder_active) {
        configOptions.reminder_every_n_days = 0;
      }
      if (!configurationOptionValues.date_limit_active) {
        configOptions.date_limit_to_sign = null;
      }
      const signersToSend = signers_array.map((signer) => {
        if (advancedInputs) {
          return { ...signer };
        }
        return {
          name: signer.name,
        };
      });
      
      try {
        response = await authService.updateFirmDocument({
          document_id: data.firebase_document_id,
          order_id: data.id,
          document_name: data.document_number,
          signers: signersToSend,
          options: configOptions,
        });
        setZapSignData(response.zapSignDta);
        onFirmCreate(response.orderDta);
      } catch (error) {
        return Promise.reject(error);
      }
    }
  };
  const handleNextPage = async () => {
    try {
      if (actualPage === steps.length - 2) {
        setLoading(true);
        await createFirm();
        setLoading(false);
      }
      if (actualPage >= steps.length - 1) {
        setShow(false);
        return;
      }
      setActualPage((actual) => {
        return actual + 1;
      });
    } catch (error) {
      setLoading(false);
      if (error.msg || error.message) {
        addToast({
          title: error.msg|| error.message,
          mode: "danger",
        });
      }
      console.error(error);
    }
  };
  const deleteSigner = async (token,index) => {
    try {
      setZapSignData((prev)=>{
          const signers = prev.signers
          signers.splice(index, 1)
          return {...prev, signers}
        }
      )
      const response = await authService.updateSignersDocument({
        signer_token_to_delete: token,
        sign_document_token: data.sign_token ?? data.document_number,
      });

      setZapSignData(response.zapSignDta);
    } catch (error) {}
  };
  const newSigner = async (new_signer) => {
    try {
      
      const response = await authService.updateSignersDocument({
        new_signer: new_signer,
        sign_document_token: data.sign_token ?? data.document_number,
      });
      
      setZapSignData(response.zapSignDta);
    } catch (error) {
      return false;
    }
    return true;
  };

  const handleRefreshData =async () =>{
    setLoading(true)
    try {
      const { data: response } = await mercadoPagoOrdersApi.get(
        `/zapsign/docs/${data.document_number}`
      );
      setZapSignData(response.data);
    } catch (error) {
      
    }
    setLoading(false)
  }
  return (
    <CModal
      size="lg"
      className={`modal_firm_document `}
      visible={show}
      onClose={() => {
        //setShow(false);
      }}
      backdrop="static"
    >
      <CModalBody>
        <CRow>
          <CCol className="modal_firm_document_preview" xs="12">
            <CRow>
              <CCol className="form_sign_header">
                <CModalTitle className="text-center">{data.name}</CModalTitle>
                <div className="stepper_indicator">
                  {steps.map((pge, ind) => {
                    return (
                      <div
                        style={{
                          width: `${100 / steps.length}%`,
                        }}
                        className={actualPage >= ind ? "active" : ""}
                        key={`pge_${ind}`}
                      ></div>
                    );
                  })}
                </div>
              </CCol>
              <CCol className="modal_create_document_preview" xs="auto">
                <button
                  onClick={() => {
                    setShow(false);
                  }}
                  type="button"
                  className="close_modal_stepper"
                >
                  <CloseSVG />
                </button>
              </CCol>
            </CRow>
          </CCol>
          <CCol className="modal_firm_document_form my-3" md="12">
            {loading ? (
              <div
                className="d-flex justify-content-center align-items-center"
                style={{ height: "250px" }}
              >
                <Spinner size={"xl"} animation="border" variant="primary" />
              </div>
            ) : (
              <CForm>
                {actualPage === 0 ? (
                  <FormSigners
                    signers={signers_array}
                    advanceInputsHook={[advancedInputs, setAdvancedInputs]}
                    onAddSigner={signers_push}
                    onRemoveSigner={handleRemove}
                    onChangeSigner={handleEditSigner}
                  />
                ) : actualPage === 1 ? (
                  <FormConfigFirm
                    values={configurationOptionValues}
                    onChange={handleEditConfig}
                  />
                ) : actualPage === 2 ? (
                  <ZapSignInfo
                    data={zapSignData}
                    onRemoveSign={deleteSigner}
                    onNewSign={newSigner}
                    onRefreshData={handleRefreshData}
                  />
                ) : (
                  ""
                )}
              </CForm>
            )}
          </CCol>

          <div className="d-flex justify-content-end">
            <>
              {actualPage > 0 && actualPage < steps.length - 1 && (
                <button
                  type="button"
                  className="prev_step_btn"
                  onClick={() => {
                    if (actualPage === 0) return;
                    setActualPage((actual) => {
                      return actual - 1;
                    });
                  }}
                >
                  Regresar
                </button>
              )}
              <button
                type="button"
                onClick={handleNextPage}
                className="next_step_btn"
              >
                {actualPage < steps.length - 1 ? "Siguiente" : "Finalizar"}
              </button>
            </>
          </div>
        </CRow>
      </CModalBody>
    </CModal>
  );
}

export default FirmDocumentModal;
