import React, { useEffect, useRef, useState } from "react";
import { CBadge, CCol, CModal, CModalBody, CRow } from "@coreui/react";
import {
  DndContext,
  useDraggable,
  useDroppable,
} from "@dnd-kit/core";
import { CSS } from "@dnd-kit/utilities";
import { Document, Page } from "react-pdf";
import axios from "axios";
import { restrictToParentElement } from "@dnd-kit/modifiers";
import { Spinner } from "reactstrap";

function SignPlacement({ urlPDF, setUrlPDF, onSaveSignatures = async () => {} }) {
  const [pdfData, setPdfData] = useState(null);
  const [loading, setLoading] = useState(false)
  const pagesHook = useState(1);
  const firmsHook = useState({
    1: [
    ],
  });
  const refPDF = useRef(null);

  const { setNodeRef } = useDroppable({
    id: "unique-id",
  });
  useEffect(() => {
    if (urlPDF) {
      axios
        .get(urlPDF, {
          responseType: "blob",
        })
        .then((data) => {
          setPdfData(data.data);
        });
    }

    return () => {};
  }, [urlPDF]);

  const handleAddNewSign = () => {
    const addFirm = firmsHook[1];
    addFirm((prev) => {
      const next = { ...prev };
      if (next[pagesHook[0]]) {
        next[pagesHook[0]].push({
          x: 0,
          y: 0,
        });
      } else {
        next[pagesHook[0]] = [
          {
            x: 0,
            y: 0,
          },
        ];
      }

      return next;
    });
  };

  const handlePlaceSignatures = async () => {
    const dimensions = {
      height: refPDF.current.clientHeight,
      width: refPDF.current.clientWidth,
    };
    let positions = Object.entries(firmsHook[0]).map(([key, value]) => {
      const page = parseInt(key) - 1;
      return value.map((position) => ({
        ...position,
        page: page,
        y: Number(
          (100 - ((position.y + 60) / dimensions.height) * 100).toFixed(2)
        ),
        x: Number((((position.x - 10) / dimensions.width) * 100).toFixed(2)),
      }));
    });
    positions = positions.flat();
    setLoading(true)
    try {
      
    await onSaveSignatures(positions);
    } catch (error) {
      
    }
    setLoading(false)
  };
  return (
    <CModal
      size="xl"
      className={`modal_firm_document `}
      visible={Boolean(urlPDF) && Boolean(pdfData)}
      onClose={() => {
        setUrlPDF(null);
      }}
    >
      <CModalBody className="position-relative">
        <CRow>
          <CCol className="d-flex flex-column  justify-content-center">
            <p>Añade las firmas y después arrástrala al lugar deseado</p>
            <button disabled={loading} onClick={handleAddNewSign} className="btn btn-primary mb-1">
              Añadir nueva firma a esta pagina
            </button>
            <button disabled={loading}
              onClick={handlePlaceSignatures}
              className="btn btn-info text-white  "
            >
              Guardar
            </button>
            
            {loading && <div
                className="d-flex justify-content-center align-items-center"
              >
                <Spinner size={"xl"} animation="border" variant="primary" />
              </div>}
          </CCol>
          <CCol xs={"auto"}>
            <div ref={setNodeRef}>
              <PDFContainer
                pdfFile={pdfData}
                firmsHook={firmsHook}
                pagesHook={pagesHook}
                refPDF={refPDF}
              ></PDFContainer>
            </div>
          </CCol>
        </CRow>
      </CModalBody>
    </CModal>
  );
}

export default SignPlacement;

function PDFContainer({ pdfFile, firmsHook = [], pagesHook = [], refPDF }) {
  const [numPages, setNumPages] = useState(0);
  const [pageNumber, setPageNumber] = pagesHook;
  const [firms, setFirms] = firmsHook;

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }
  const handleChangePage = (move) => {
    setPageNumber((prev) => {
      let newPage = prev + move;
      if (newPage <= 0) newPage = 1;
      if (newPage > numPages) newPage = numPages;
      return newPage;
    });
  };

  const handleDragEnd = (event) => {
    const [index, page] = event.active.id.split(".");
    setFirms((prev) => {
      const next = { ...prev };
      const oldPosition = next[page][index];
      next[page][index] = !oldPosition
        ? event.delta
        : {
            ...event.delta,
            x: parseInt(event.delta.x + oldPosition.x),
            y: parseInt(event.delta.y + oldPosition.y),
          };
      return next;
    });
  };

  const handleRemoveSign = (id) => {
    const [index, page] = id.split(".");
    setFirms((prev) => {
      const next = { ...prev };
      next[page].splice(index, 1);
      return next;
    });
  };
  return (
    <div className="pdf-div ">
      <div className="d-flex justify-content-center mb-2">
        <div className="btn-group ">
          <button
            disabled={pageNumber === 1}
            className="btn btn-primary "
            onClick={() => handleChangePage(-1)}
          >
            Anterior
          </button>
          <button className="btn" disabled>
            Pag {pageNumber} de {numPages}
          </button>
          <button
            disabled={pageNumber === numPages}
            className="btn btn-primary "
            onClick={() => handleChangePage(+1)}
          >
            Siguiente
          </button>
        </div>
      </div>

      <DndContext
        modifiers={[restrictToParentElement]}
        onDragEnd={handleDragEnd}
      >
        <div
          className="position-relative  border border-1 rounded-1 border-light  mb-1"
          ref={refPDF}
        >
          {firms[pageNumber]?.map((position, index) => {
            return (
              <Draggable
                position={position}
                id={`${index}.${pageNumber}`}
                onRemove={handleRemoveSign}
              ></Draggable>
            );
          })}

          <Document file={pdfFile} onLoadSuccess={onDocumentLoadSuccess}>
            <Page
              pageNumber={pageNumber}
              renderTextLayer={false}
              renderAnnotationLayer={false}
            />
          </Document>
        </div>
      </DndContext>
    </div>
  );
}

function Draggable({ position, id, onRemove = () => {} }) {
  const { attributes, listeners, setNodeRef, transform } = useDraggable({
    id: id ?? "unique-id",
  });
  const style = {
    height: "40px",
    width: "100px",
    zIndex: 5000,
    transform: CSS.Translate.toString(transform),
    top: position ? (parseInt(position?.y) ?? 50) + "px" : "0",
    left: position ? (parseInt(position?.x) ?? 50) + "px" : "0",
  };
  return (
    <div className="position-absolute p-0" ref={setNodeRef} style={style}>
      <div
        className="btn-group border-0 bg-transparent "
        {...listeners}
        {...attributes}
      >
        <div className="btn btn-secondary opacity-50 ">Firma</div>
        <div className="btn btn-secondary position-relative">
          <i className="fas fa-bars"></i>
        </div>
      </div>
      <CBadge
        color="danger"
        position="top-end"
        shape="rounded-pill"
        style={{ zIndex: 1, cursor: "pointer" }}
        onClick={() => {
          onRemove(id);
        }}
      >
        <i className="fas fa-times"></i>
      </CBadge>
    </div>
  );
}
