import React from "react";
import Login from "../components/Login";

const SingIn = (props) => {
  return (
    <>
      <Login props={props} />
    </>
  );
};

export default SingIn;
