import React, { useEffect, useState } from "react";

import "../assets/styles/components/Panel.scss";
import Icons from "../assets/static/iconos.png";
import Header from "./Header";
import Footer from "./Footer";
import SearchCatg from "./SearchCatg";
import userService from "../service/user.service";
import { searchElementByName } from "../utils/searchElementByName";
import { useHistory } from "react-router-dom";
import Routes from "../routes/Routes";
import ExternalDocumentSignModal from "./modals/FirmDocumentModal/ExternalDocumentSignModal";
import { getDataUser } from "../reducers/auth";
import { useSelector } from "react-redux";

const Panel = (props) => {
  const { documents, loader, setModalData, ModAlertMod,categoryId } = props;

  // const [query, setQuery] = useState(false);
  const [filteredCatg, setFilteredCatg] = useState([]);
  const userData = useSelector(getDataUser)
  const history = useHistory()
  const [title, setTitle] = useState(null);
  const firmModal = useState(false)
  const [firmDoc, setFirmDoc] = useState(false)
  const [ordenedData, setOrdenedData] = useState({});
  const [loading, setLoading] = useState(false);

  const [iconPath, setIconPath] = useState("");

  const useSearchDocuemnt = (event) => {
    const value = searchElementByName(documents,event.target.value)
    if (event.target.value === "") {
      setFilteredCatg([]);
    } else if (Object.keys(value).length !== 0) {
      setFilteredCatg(value);
    }
  };

    useEffect(() => {
      const requestDataCategories = async () => {
        if(!categoryId) {
          handleBackDatacategory()
          return
        }
        try {
          setLoading(true)
          const [id,name] = categoryId.split("-")
          const dataCategorie = await userService.getDataCategories(id)
          if(name && !title) {
            setTitle(name);
          }
          
          const user_tags = userData.tags?.map(tag => tag.id) ?? []
          const tags_name = userData.tags?.reduce((acc, curr) => {
            acc[curr.id] = curr.name
            return acc
          },{}) ?? []
          const public_data = dataCategorie.data.filter((doc) => doc.status === "PUBLICADO").reduce((acc, curr)=>{
            const template_tags = curr.tags ?? []
            if( template_tags.length === 0) {
              acc['public'].push(curr)
              return acc
            }
            const has_tag = template_tags.findIndex(element => user_tags.includes(element)) >= 0
            if (!has_tag) return acc
            template_tags.forEach(tag_id=>{
              if (acc[tags_name[tag_id]]) 
                acc[tags_name[tag_id]].push(curr)
              else acc[tags_name[tag_id]] = [curr]
            })
            return acc
          },{
            'public':[]
          })
          
          delete dataCategorie.data
          setOrdenedData(public_data)
          
        } catch (error) {
          
          setModalData({
            title: "Lo sentimos",
            body: error.response.data.message,
            border: "bg-danger",
          });
          ModAlertMod();
          console.error(error)
        }
        setLoading(false)
      }
      requestDataCategories()
      return () => {
        
      }
    }, [categoryId])
    
  const handleOnClick = (docs) => {
    setIconPath(docs.icon_path);
    setTitle(docs.name);
    
    history.push(Routes.panel(`${docs.id}-${docs.name}`))
  };

  const handleBackDatacategory = () => {
    setOrdenedData({'public':[]})
    setIconPath('');
    setTitle(null);
  };

  const handleSubmitDocument = (doc) => {
    
    history.push(Routes.methods(doc.name.replace(/ /g,'-')),{doc})
    localStorage.setItem(
      "doc",
      JSON.stringify({ ...doc })
    );
  };

  return (
    <>
      <div className="body__panel">
        <Header isLogged />

        <main className="main__panel">
          <div className="container__panel">
            {categoryId ? (
              <SearchCatg
              loading={loading}
                iconPath={iconPath}
                handleOnPage={()=>{
                  history.replace(Routes.panel())
                  handleBackDatacategory()
                }}
                data={ordenedData}
                title={title}
                handleSubmitDocument={handleSubmitDocument}
              />
            ) : (
              <>
                <section className="selection__panel">
                  <h6>Selecciona la categoria</h6>
                  <input
                    type="text"
                    placeholder="Busca el documento que quieres generar" 
                    onChange={useSearchDocuemnt}
                  />
                </section>
                {loader && <div className="loader-page"></div>}

                {filteredCatg.length === 0 ? (
                  <TemplatesSection documents={documents} handleOnClick={handleOnClick}/>
                ) 
                : (
                  <section className="list__search list__categories">
                    {filteredCatg.map((categorie, i) => (
                      <div
                        className="categories"
                        key={i}
                        onClick={(e) => handleOnClick(categorie)}
                      >
                        {categorie.icon_path === null ? (
                          <img src={Icons} alt="" />
                        ) : (
                          <i className={categorie.icon_path}></i>
                        )}
                        <div className="data__cat">
                          <h6>{categorie.name}</h6>{" "}
                          {categorie.description === null ? (
                            <span>Sin Descripción</span>
                          ) : (
                            <span>{categorie.description}</span>
                          )}
                        </div>
                      </div>
                    ))}
                  </section>
                )}
              </>
            )}
          </div>
        </main>
        <Footer isLogged showSignModal={firmModal[1]} />
        
      <ExternalDocumentSignModal
        showHook={firmModal}
        dataHook={[firmDoc, setFirmDoc]}
      />
      </div>
    </>
  );
};


const TemplatesSection = ({documents,handleOnClick=()=>{}}) => {

  return (
    <section className="list__categories">
      {documents
        ? documents.map((categorie, i) => (
            <div
              className="categories"
              key={i}
              onClick={(e) => handleOnClick(categorie)}
            >
              {categorie.icon_path === null ||
              categorie.icon_path === "Sin icono" ? (
                <img src={Icons} alt="" />
              ) : (
                // <i className={categorie.icon_path}></i>
                <img
                  src={categorie.icon_path}
                  className="categorie"
                  alt=""
                />
              )}
              <div className="data__cat">
                <h6>{categorie.name}</h6>{" "}
                {categorie.description === null ? (
                  <span>Sin Descripcion</span>
                ) : (
                  <span>{categorie.description}</span>
                )}
              </div>
            </div>
          ))
        : ""}
    </section>)
}
export default Panel;
